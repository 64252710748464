import {ItemPlaceholderEnum} from './MediaResource';

export const ItemMediaStatuses = {
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
} as const;

export type ItemMediaStatus = typeof ItemMediaStatuses[keyof typeof ItemMediaStatuses];

export interface ItemMedia {
    bucket: string;
    contentType: string;
    name: string;
    url: string;
    metadata: Map<string, string>;
    idx?: string | number;
    uploadedAt?: number;
    placeholderType?: ItemPlaceholderEnum;
    a?: string;
    status?: ItemMediaStatus;
}

export interface ItemVideo extends ItemMedia {}

export interface ItemVideoWithId extends ItemVideo {
    id: string;
}

export interface ItemImage extends ItemMedia {
    height: number;
    width: number;
}

export interface ItemImageWithId extends ItemImage {
    id: string;
}

export interface ItemImageMap {
    original?: Map<string, ItemImage>;
    thumb_200?: Map<string, ItemImage>;
    full_4x3?: Map<string, ItemImage>;
    thumbnail_4x3?: Map<string, ItemImage>;
}

export interface ItemVideoMap {
    video?: Map<string, ItemVideo>;
    video_4x3?: Map<string, ItemVideo>;
}

export interface ItemImageAndVideoMedia extends ItemImageMap, ItemVideoMap {}
