import {Machine} from 'xstate';
import {Sender} from 'xstate/lib/types';
import {LongTimeoutResult} from '@joyrideautos/auction-utils/src/setLongTimeout';
import {
    ListingAuctionStateMachineContext,
    ENDED_RECENTLY_TIME_IN_MS,
    ListingAuctionStateMachineEvent,
    ListingAuctionStateEnum,
    ListingAuctionStateMachineSchema,
} from '../statemachines/types';
import {when, reaction} from 'mobx';
import {
    executeAt,
    startCountDown,
    subscribeToClockOffsetChange,
    subscribeToAuctionPauseChange,
} from '../statemachines/utils';
import {logError} from '@joyrideautos/ui-logger/src/utils';

const pauseStateInvocation =
    (context: ListingAuctionStateMachineContext) => (callback: Sender<ListingAuctionStateMachineEvent>) => {
        const auctionPauseChangeDisposer = subscribeToAuctionPauseChange(context, (paused) => {
            if (!paused) {
                callback('RESUME');
            }
        });

        return () => {
            auctionPauseChangeDisposer && auctionPauseChangeDisposer();
        };
    };

export const listingAuctionStateMachine = Machine<
    ListingAuctionStateMachineContext,
    ListingAuctionStateMachineSchema,
    ListingAuctionStateMachineEvent
>(
    {
        id: 'listingAuction',
        initial: ListingAuctionStateEnum.BEFORE_START,
        on: {
            SUSPEND: ListingAuctionStateEnum.SUSPENDED,
        },
        states: {
            beforeStart: {
                invoke: {
                    src:
                        ({presenter}: ListingAuctionStateMachineContext) =>
                        (callback: Sender<ListingAuctionStateMachineEvent>) => {
                            if (presenter.auction.ended && presenter.areAllResultsEnded) {
                                callback('ENDED_RECENTLY');
                                return;
                            }

                            let timerDisposer: LongTimeoutResult | undefined = undefined;
                            let countDownDisposer: (() => void) | undefined;

                            const checkBeforeStartStateEnded = () => {
                                timerDisposer && timerDisposer.clearTimeout();

                                const disposer = executeAt(() => callback('START_BIDDING'), presenter.startAuctionTime);

                                if (!disposer) {
                                    callback('START_BIDDING');
                                } else {
                                    console.log(
                                        `${new Date()}: start auction at ${new Date(presenter.startAuctionTime)}`
                                    );
                                }

                                return disposer;
                            };

                            const startBeforeStartStateCountDown = () => {
                                countDownDisposer && countDownDisposer();
                                return startCountDown(presenter, presenter.startAuctionRemainderInMs);
                            };

                            timerDisposer = checkBeforeStartStateEnded();
                            countDownDisposer = startBeforeStartStateCountDown();

                            const clockOffsetChangeDisposer = subscribeToClockOffsetChange(presenter.clock)(() => {
                                timerDisposer = checkBeforeStartStateEnded();
                                countDownDisposer = startBeforeStartStateCountDown();
                            });

                            return () => {
                                timerDisposer && timerDisposer.clearTimeout();
                                countDownDisposer && countDownDisposer();
                                clockOffsetChangeDisposer();
                            };
                        },
                },
                on: {
                    START_BIDDING: [
                        {target: ListingAuctionStateEnum.EMPTY, cond: 'isEmpty'},
                        {target: ListingAuctionStateEnum.SUSPENDED, cond: 'isSuspended'},
                        {target: ListingAuctionStateEnum.BIDDING, cond: 'isAuctionStarted'},
                        {target: ListingAuctionStateEnum.ENDED_RECENTLY},
                    ],
                    ENDED_RECENTLY: ListingAuctionStateEnum.ENDED_RECENTLY,
                },
            },
            bidding: {
                invoke: {
                    src:
                        ({presenter}: ListingAuctionStateMachineContext) =>
                        (callback: Sender<ListingAuctionStateMachineEvent>) => {
                            if (
                                !presenter.earliestWinningBid &&
                                presenter.auction.settings.isContinuousAuctionEnabled &&
                                !presenter.auction.ended
                            ) {
                                const endAuctionTimeDisposer = executeAt(
                                    () => callback('ENDED_RECENTLY'),
                                    presenter.endAuctionTime
                                );
                                if (!endAuctionTimeDisposer) {
                                    callback('ENDED_RECENTLY');
                                    return;
                                }

                                const checkBiddingEndedDisposer = reaction(
                                    () => presenter.earliestWinningBid,
                                    (earliestWinningBid) => {
                                        if (earliestWinningBid) {
                                            endAuctionTimeDisposer.clearTimeout();
                                            callback('START_BIDDING');
                                        }
                                    }
                                );

                                return () => {
                                    endAuctionTimeDisposer.clearTimeout();
                                    checkBiddingEndedDisposer();
                                };
                            }

                            let checkBiddingEndedTimerDisposer: LongTimeoutResult | undefined;
                            let countDownDisposer: (() => void) | undefined;

                            const createAuctionCountDown = () => {
                                countDownDisposer && countDownDisposer();
                                return startCountDown(presenter, presenter.endAuctionTime);
                            };

                            const checkBiddingEnded = (at: number) => {
                                const disposer = executeAt(() => callback('CHECK_BIDDING_ENDED'), at);
                                if (!disposer) {
                                    callback('CHECK_BIDDING_ENDED');
                                }
                                return disposer;
                            };

                            const earliestItemChangedDisposer = reaction(
                                () => presenter.earliestItemEndTime,
                                () => {
                                    checkBiddingEndedTimerDisposer && checkBiddingEndedTimerDisposer.clearTimeout();
                                    checkBiddingEndedTimerDisposer = checkBiddingEnded(presenter.earliestItemEndTime);
                                }
                            );

                            const endAuctionTimeChangedDisposer = reaction(
                                () => presenter.endAuctionTime,
                                () => {
                                    countDownDisposer && countDownDisposer();
                                    countDownDisposer = createAuctionCountDown();
                                }
                            );

                            checkBiddingEndedTimerDisposer = checkBiddingEnded(presenter.earliestItemEndTime);
                            countDownDisposer = createAuctionCountDown();

                            return () => {
                                earliestItemChangedDisposer();
                                endAuctionTimeChangedDisposer();
                                checkBiddingEndedTimerDisposer && checkBiddingEndedTimerDisposer.clearTimeout();
                                countDownDisposer && countDownDisposer();
                            };
                        },
                },
                on: {
                    CHECK_BIDDING_ENDED: ListingAuctionStateEnum.AFTER_BIDDING,
                    START_BIDDING: ListingAuctionStateEnum.BIDDING,
                },
            },
            afterBidding: {
                entry: ['checkAuctionBiddingEnded'],
                invoke: {
                    src:
                        ({presenter, services}) =>
                        (callback) => {
                            if (!presenter.earliestWinningBid) {
                                const {auction} = presenter;
                                if (auction.settings.isContinuousAuctionEnabled && !auction.ended) {
                                    callback('START_BIDDING');
                                } else {
                                    callback('ENDED_RECENTLY');
                                }
                                return;
                            }
                            if (presenter.earliestWinningBid.ended) {
                                callback('START_BIDDING');
                                return;
                            }

                            const earliestTimeChangedDisposer = reaction(
                                () => presenter.earliestItemEndTime,
                                () => callback('START_BIDDING')
                            );
                            const winningBidEndedDisposer = when(
                                () => Boolean(presenter.earliestWinningBid && presenter.earliestWinningBid.ended),
                                () => callback('START_BIDDING')
                            );

                            return () => {
                                earliestTimeChangedDisposer();
                                winningBidEndedDisposer();
                            };
                        },
                },
                on: {
                    START_BIDDING: [
                        {target: ListingAuctionStateEnum.ENDED_RECENTLY, cond: 'isAuctionEnded'},
                        {target: ListingAuctionStateEnum.BIDDING},
                    ],
                    ENDED_RECENTLY: ListingAuctionStateEnum.ENDED_RECENTLY,
                },
            },
            suspended: {
                invoke: {
                    src: pauseStateInvocation,
                },
                on: {
                    RESUME: [{target: ListingAuctionStateEnum.BIDDING}],
                },
            },
            endedRecently: {
                invoke: {
                    src:
                        ({presenter: {endAuctionTime, clock}}: ListingAuctionStateMachineContext) =>
                        (callback: Sender<ListingAuctionStateMachineEvent>) => {
                            let timerDisposer: LongTimeoutResult | undefined = undefined;

                            const checkEndedRecentlyStateEnded = () => {
                                timerDisposer && timerDisposer.clearTimeout();

                                const now = Date.now();
                                const timeAfterEnd = now - endAuctionTime;
                                const time = now + (ENDED_RECENTLY_TIME_IN_MS - timeAfterEnd);

                                const disposer = executeAt(() => callback('END_AUCTION'), time);

                                if (!disposer) {
                                    callback('END_AUCTION');
                                } else {
                                    console.log('Ended recently status will be changed at', new Date(time));
                                }

                                return disposer;
                            };

                            timerDisposer = checkEndedRecentlyStateEnded();

                            const clockOffsetChangeDisposer = subscribeToClockOffsetChange(clock)(() => {
                                timerDisposer = checkEndedRecentlyStateEnded();
                            });

                            return () => {
                                timerDisposer && timerDisposer.clearTimeout();
                                clockOffsetChangeDisposer();
                            };
                        },
                    onError: {
                        target: ListingAuctionStateEnum.ERROR,
                    },
                },
                on: {
                    END_AUCTION: ListingAuctionStateEnum.ENDED,
                },
            },
            ended: {
                type: 'final',
            },
            error: {
                type: 'final',
                invoke: {
                    id: 'error',
                    src: (context, event) => () => {
                        console.log('ERROR: Auction is in invalid state.', event);
                    },
                },
            },
            empty: {
                type: 'final',
            },
        },
    },
    {
        guards: {
            isSuspended: ({presenter}) => {
                return presenter.auction.isSuspended;
            },
            isAuctionStarted: ({presenter}) => {
                return presenter.clock.parseDate(presenter.auction.settings.start) < Date.now();
            },
            isAuctionEnded: ({presenter: {endAuctionTime}}) => {
                return endAuctionTime < Date.now();
            },
            isEmpty: ({presenter: {isEmpty}}) => isEmpty,
        },
        actions: {
            checkAuctionBiddingEnded: ({services, presenter: {auction}}) => {
                services.bidService.checkAuctionBiddingEnded(auction.regionId, auction.auctionId).catch(logError());
            },
        },
    }
);
