import React, {FC} from 'react';
import {COMPANY_NAME} from '@joyrideautos/auction-core/src/constants/Constants';

export const Copyright: FC = () => {
    const version = process.env.REACT_APP_BITBUCKET_TAG || process.env.REACT_APP_BITBUCKET_BUILD_NUMBER;
    return (
        <span className="copyright">
            {version && <span>ver. {version} &nbsp;</span>} &copy; 2020 - {new Date().getFullYear()} {COMPANY_NAME}
        </span>
    );
};
