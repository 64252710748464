import {types, Instance} from 'mobx-state-tree';
import {ContentTypes} from '@joyrideautos/auction-core/src/types/Document';

export const Document = types.model('Document', {
    name: types.string,
    document: types.string,
});

export interface DocumentType extends Instance<typeof Document> {}

export const ContentTypeEnum = types.enumeration(Object.values(ContentTypes));

export const DocumentWithId = Document.named('Document').props({
    id: types.maybe(types.number),
    uploadedAt: types.maybe(types.string),
    uid: types.maybe(types.string),
    contentType: types.maybe(ContentTypeEnum),
});

export interface DocumentWithIdType extends Instance<typeof DocumentWithId> {}

const DocumentWithIdArray = types.array(DocumentWithId);

export interface DocumentWithIdArrayType extends Instance<typeof DocumentWithIdArray> {}
