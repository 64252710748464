import {IDisposer, Instance} from 'mobx-state-tree';
import {PaymentIntentHold} from '@joyrideautos/auction-core/src/types/PaymentIntentHold';
import {AuctionType} from '@joyrideautos/ui-models/src/types/auction/Auction';
import moment from 'moment';
import {when} from 'mobx';
import {paymentIntentHoldStatuses} from '@joyrideautos/auction-core/src/enums/PaymentIntentHoldStatus';
import {CcHoldType} from '@joyrideautos/ui-models/src/types/CcHold';
import BaseViewModel from '@joyrideautos/web-common-components/src/models/BaseViewModel';

export const CCHoldFailedAlertViewModel = BaseViewModel.named('CCHoldFailedAlertViewModel')
    .views((self) => {
        return {
            get isVisibleAlert(): boolean {
                return !!this.auctionTitle;
            },
            get userInfo() {
                return self.authUserStore.userInfo;
            },
            getAuction(regionId: string, auctionId: string) {
                return self.auctionsStore.findAuction(regionId, auctionId);
            },
            get ccHolds(): Map<string, CcHoldType> | undefined {
                if (!this.userInfo) {
                    return undefined;
                }
                const {uid} = this.userInfo;
                return self.ccHoldStore.findHoldsForUser(uid);
            },
            get failedCcHoldAuction(): {regionId: string; auctionId: string} | undefined {
                if (!this.ccHolds) {
                    return undefined;
                }

                const holdEntries: [string, PaymentIntentHold][] = Array.from(this.ccHolds.entries());
                const filtered = holdEntries.filter(
                    ([_, paymentIntentHold]: [string, PaymentIntentHold]) =>
                        !paymentIntentHold?.auctionEnded &&
                        paymentIntentHold.status === paymentIntentHoldStatuses.FAILED
                );

                const {length} = filtered;
                if (length) {
                    // find auction with soonest date
                    const auctions: AuctionType[] = [];
                    filtered.forEach(([documentKey, _]) => {
                        const [regionId, auctionId] = documentKey.split('-auctions-');
                        const auction = this.getAuction(regionId, auctionId);
                        if (auction) {
                            auctions.push(auction);
                        }
                    });
                    const momentDates = auctions.map(({settings}) => moment(settings.startEvent));
                    const minDate = moment.min(momentDates);
                    const found = auctions.find(({settings}) => minDate.isSame(moment(settings.startEvent)));
                    // If not found - then the auction was deleted (archived)
                    if (!found) {
                        return undefined;
                    }
                    const {regionId, auctionId} = found;
                    return {regionId, auctionId};
                } else {
                    return undefined;
                }
            },
            get auctionTitle() {
                if (!this.failedCcHoldAuction) {
                    return '';
                }
                const {regionId, auctionId} = this.failedCcHoldAuction;
                return this.getAuction(regionId, auctionId)?.title;
            },
        };
    })
    .actions((self) => {
        const disposers: IDisposer[] = [];

        return {
            afterCreate() {
                disposers.push(
                    when(
                        () => Boolean(!!self.userInfo?.uid && !self.userInfo.isAnonymous),
                        () => {
                            disposers.push(self.ccHoldStore.subscribeToHolds(self.userInfo!.uid));
                        }
                    )
                );
            },

            beforeDestroy() {
                for (const disposer of disposers) {
                    disposer();
                }
            },
        };
    });

export interface CCHoldFailedAlertViewModelType extends Instance<typeof CCHoldFailedAlertViewModel> {}
