import {GlobalConfig} from '@joyrideautos/auction-core/src/types/GlobalConfig';
import {BaseService} from './BaseService';

export class GlobalConfigService extends BaseService {
    subscribeToGlobalConfig(listener: (globalConfig: GlobalConfig) => void): () => void {
        return this.firebase.database.subscribeToSnapshot(`/globalConfig`, (configSnapshot) => {
            if (configSnapshot.exists()) {
                const config = configSnapshot.toJSON() as GlobalConfig | null;
                listener(config ?? {});
            }
        });
    }
}
