import {Machine} from 'xstate';
import {ONE_SEC} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {Sender} from 'xstate/lib/types';
import {LongTimeoutResult} from '@joyrideautos/auction-utils/src/setLongTimeout';
import {
    SequenceAuctionStateMachineEvent,
    SequenceAuctionStateMachineSchema,
    SequenceAuctionStateEnum,
    SequenceAuctionStateMachineContext,
    CHECK_ITEM_BIDDING_ENDED_INTERVAL,
    BIDDING_CHECKING_RESULT_TIME_IN_MS,
    BIDDING_SHOWING_RESULT_TIME_IN_MS,
    ENDED_RECENTLY_TIME_IN_MS,
} from './types';
import {
    startCountDown,
    executeAt,
    subscribeToClockOffsetChange,
    subscribeToAuctionPauseChange,
    isNotEndedWinningBid,
    subscribeToWinningBidList,
    checkItemBiddingEnded,
} from './utils';
import {logError} from '@joyrideautos/ui-logger/src/utils';

// Invocations

type Disposer = () => void;
type InvocationCallback = (callback: Sender<SequenceAuctionStateMachineEvent>) => Disposer;
type Invocation = (
    context: SequenceAuctionStateMachineContext,
    event: SequenceAuctionStateMachineEvent
) => InvocationCallback;

export const pauseStateInvocation: Invocation = (context) => (callback) =>
    subscribeToAuctionPauseChange(context, (paused) => {
        if (!paused) {
            callback('RESUME');
        }
    });

export const beforePreBidInvocation: Invocation = (context, event) => (callback) => {
    const auctionStartTime = context.presenter.startPreBidTime;
    const auctionStartEventTime = context.presenter.startAuctionTime;
    const reminderTime = context.presenter.startAuctionRemainder;

    context.logger?.log('beforePreBid', {event});
    context.logger?.log(JSON.stringify({auctionStartTime, auctionStartEventTime, reminderTime}));

    let timerDisposer: LongTimeoutResult | undefined = undefined;

    const checkBeforePreBidStateEnded = () => {
        timerDisposer?.clearTimeout();

        let disposer: LongTimeoutResult | undefined;

        if (auctionStartTime === auctionStartEventTime) {
            const timeUntilAuctionStart = auctionStartEventTime - reminderTime;
            disposer = executeAt(() => callback('BEFORE_PREBID_NEXT'), timeUntilAuctionStart);
            if (!disposer) {
                callback('BEFORE_PREBID_NEXT');
            } else {
                context.logger?.log(
                    `${new Date()}: show start auction intermission banner at ${new Date(timeUntilAuctionStart)}`
                );
            }
        } else if (auctionStartEventTime > auctionStartTime) {
            disposer = executeAt(() => callback('BEFORE_PREBID_NEXT'), auctionStartTime);

            if (!disposer) {
                callback('BEFORE_PREBID_NEXT');
            } else {
                context.logger?.log(`${new Date()}: prebid starts at ${auctionStartTime}`);
                callback('BEFORE_PREBID_CHECK_EMPTY');
            }
        } else {
            context.logger?.log('start event date is less than start date');
            callback('BEFORE_PREBID_ERROR');
        }

        return disposer;
    };

    timerDisposer = checkBeforePreBidStateEnded();

    const disposer = subscribeToWinningBidList(
        context,
        () => callback('BEFORE_PREBID_NEXT'),
        () => {
            timerDisposer = checkBeforePreBidStateEnded();
        }
    );

    const clockOffsetChangeDisposer = subscribeToClockOffsetChange(context.presenter.clock)(() => {
        timerDisposer = checkBeforePreBidStateEnded();
    });

    return () => {
        timerDisposer?.clearTimeout();
        disposer.then((value) => value()).catch(logError());
        clockOffsetChangeDisposer();
    };
};

export const preBidInvocation: Invocation = (context, event) => (callback) => {
    let timerDisposer: LongTimeoutResult | undefined = undefined;
    let countDownDisposer: (() => void) | undefined;

    context.logger?.log('preBid', {event});

    const checkPreBidStateEnded = () => {
        timerDisposer?.clearTimeout();

        const timeUntilAuctionStart = context.presenter.startAuctionTime - context.presenter.startAuctionRemainder;
        const disposer = executeAt(() => callback('PREBID_NEXT'), timeUntilAuctionStart);
        context.logger?.log('checkPreBidStateEnded', {disposer});

        if (!disposer) {
            callback('PREBID_NEXT');
        } else {
            context.logger?.log(
                `${new Date()}: show start auction intermission banner at ${new Date(timeUntilAuctionStart)}`
            );
        }

        return disposer;
    };

    const startPreBidStateCountDown = () => {
        countDownDisposer && countDownDisposer();
        context.logger?.log('startPreBidStateCountDown', {
            timeUntilAuctionStartInMs: context.presenter.timeUntilAuctionStartInMs,
            time: new Date(context.presenter.timeUntilAuctionStartInMs),
        });
        return startCountDown(context.presenter, context.presenter.timeUntilAuctionStartInMs);
    };

    timerDisposer = checkPreBidStateEnded();
    countDownDisposer = startPreBidStateCountDown();

    const disposer = subscribeToWinningBidList(
        context,
        () => callback('PREBID_NEXT'),
        () => {
            timerDisposer = checkPreBidStateEnded();
            countDownDisposer = startPreBidStateCountDown();
        }
    );

    const clockOffsetChangeDisposer = subscribeToClockOffsetChange(context.presenter.clock)(() => {
        timerDisposer = checkPreBidStateEnded();
        countDownDisposer = startPreBidStateCountDown();
    });

    return () => {
        timerDisposer?.clearTimeout();
        countDownDisposer && countDownDisposer();
        disposer.then((value) => value()).catch(logError('preBid state disposer'));
        clockOffsetChangeDisposer();
    };
};

export const beforeStartInvocation: Invocation = (context, event) => (callback) => {
    context.logger?.log('beforeStart', {event});
    const presenter = context.presenter;

    let timerDisposer: LongTimeoutResult | undefined = undefined;
    let countDownDisposer: any;

    const checkBeforeStartStateEnded = () => {
        timerDisposer && timerDisposer.clearTimeout();

        const disposer = executeAt(() => callback('BEFORE_START_NEXT'), presenter.startAuctionTime);
        context.logger?.log('checkBeforeStartStateEnded', disposer);

        if (!disposer) {
            callback('BEFORE_START_NEXT');
        } else {
            context.logger?.log(`${new Date()}: start auction at ${new Date(presenter.startAuctionTime)}`);
        }

        return disposer;
    };

    const startBeforeStartStateCountDown = () => {
        clearInterval(countDownDisposer);
        return startCountDown(presenter, context.presenter.startAuctionRemainderInMs);
    };

    timerDisposer = checkBeforeStartStateEnded();
    countDownDisposer = startBeforeStartStateCountDown();

    const disposer = subscribeToWinningBidList(
        context,
        () => callback('BEFORE_START_NEXT'),
        () => {
            timerDisposer = checkBeforeStartStateEnded();
            countDownDisposer = startBeforeStartStateCountDown();
        }
    );

    const clockOffsetChangeDisposer = subscribeToClockOffsetChange(context.presenter.clock)(() => {
        timerDisposer = checkBeforeStartStateEnded();
        countDownDisposer = startBeforeStartStateCountDown();
    });

    return () => {
        disposer.then((value) => value()).catch(logError());
        timerDisposer?.clearTimeout();
        clearInterval(countDownDisposer);
        clockOffsetChangeDisposer();
    };
};

export const biddingInvocation: Invocation = (context, event) => (callback) => {
    let checkBiddingEndedTimerDisposer: LongTimeoutResult | undefined;
    let countDownDisposer: (() => void) | undefined;

    context.logger?.log('bidding', {event});

    const createBiddingCountDown = () => {
        countDownDisposer && countDownDisposer();

        return startCountDown(context.presenter, context.presenter.timeUntilItemBidEndsInMs);
    };

    const checkBiddingEnded = (): LongTimeoutResult | undefined => {
        const winningBid = context.presenter.currentWinningBid;
        const expiration = winningBid?.expiration;

        context.logger?.log(
            `${new Date().toISOString()}: checkBiddingEnded`,
            expiration && new Date(expiration).toISOString()
        );

        checkBiddingEndedTimerDisposer?.clearTimeout();
        const disposer =
            expiration &&
            expiration - BIDDING_CHECKING_RESULT_TIME_IN_MS >= Date.now() &&
            executeAt(() => callback('BIDDING_CHECK_IF_ENDED'), expiration - BIDDING_CHECKING_RESULT_TIME_IN_MS);

        if (!disposer) {
            callback('BIDDING_CHECK_IF_ENDED');
            return;
        }

        return disposer;
    };

    const isValidWinningBid = (context: SequenceAuctionStateMachineContext): boolean => {
        const winningBid = context.presenter.currentWinningBid;
        const auction = context.presenter.auction;
        const expirationTS = winningBid ? winningBid.expiration : 0;
        const isValid = !winningBid?.ended && (expirationTS > Date.now() + 100 || !!auction.settings.hybrid);
        context.logger?.log(
            '[isValidWinningBid]',
            {
                now: new Date().toISOString(),
                currentWinningBidExpiration: new Date(expirationTS).toISOString(),
                [`expirationTS - (Date.now() + 100)`]: expirationTS - (Date.now() + 100),
                isValid,
            },
            winningBid
        );
        return isValid;
    };

    if (isValidWinningBid(context)) {
        checkBiddingEndedTimerDisposer = checkBiddingEnded();
        countDownDisposer = createBiddingCountDown();
    } else {
        callback('BIDDING_END');
    }

    // After running checkItemBiddingEnded on the server, client listens for winningBid item update
    // and keeps in sync local winningBidList with FB. Here we subscribe on local collection `ended`
    // status update to end current Item bidding.
    const disposer = subscribeToWinningBidList(
        context,
        () => {
            const winningBid = context.presenter.currentWinningBid;
            context.logger?.log('on patch, BIDDING_END', winningBid?.itemKey);
            callback('BIDDING_END');
        },
        () => {
            checkBiddingEndedTimerDisposer = checkBiddingEnded();
            countDownDisposer = createBiddingCountDown();
        }
    );

    const clockOffsetChangeDisposer = subscribeToClockOffsetChange(context.presenter.clock)(() => {
        checkBiddingEndedTimerDisposer = checkBiddingEnded();
        countDownDisposer = createBiddingCountDown();
    });

    return () => {
        checkBiddingEndedTimerDisposer?.clearTimeout();
        countDownDisposer && countDownDisposer();
        disposer.then((value) => value()).catch(logError());
        clockOffsetChangeDisposer();
    };
};

export const afterBiddingInvocation: Invocation = (context, event) => (callback) => {
    context.logger?.log('afterBidding', {event});
    const winningBid = context.presenter.currentWinningBid;
    if (winningBid?.ended) {
        callback('AFTER_BIDDING_END');
        return () => {};
    }

    context.logger?.log('afterBidding. check bidding ended');

    const disposer = subscribeToWinningBidList(
        context,
        () => {
            const winningBid = context.presenter.currentWinningBid;
            context.logger?.log('on patch, END_BIDDING', winningBid?.itemKey);
            callback('AFTER_BIDDING_END');
        },
        () => callback('AFTER_BIDDING_START')
    );

    // call every second to check if bidding ended
    checkItemBiddingEnded(context).catch(() => {
        context.logger?.log('checkItemBiddingEnded timeout (afterBidding)');
    });
    const interval = setInterval(() => {
        const winningBid = context.presenter.currentWinningBid;
        context.logger?.log('check item bidding ended interval', winningBid?.itemKey, winningBid?.ended);
        checkItemBiddingEnded(context).catch(() => {
            context.logger?.log('checkItemBiddingEnded timeout (afterBidding interval)');
        });
    }, CHECK_ITEM_BIDDING_ENDED_INTERVAL);

    return () => {
        disposer.then((value) => value()).catch(logError());
        clearInterval(interval);
    };
};

export const biddingResultInvocation: Invocation = (context, event) => (callback) => {
    context.logger?.log('biddingResult', event);

    const previousWinningBid = context.presenter.previousWinningBid;

    let timerDisposer: LongTimeoutResult | undefined = undefined;

    const currentDate = Date.now();

    const checkBiddingResultStateEnded = () => {
        timerDisposer?.clearTimeout();

        let showBiddingResultTime: number;

        if (event.type === 'BIDDING_END' || event.type === 'AFTER_BIDDING_END') {
            showBiddingResultTime = currentDate + BIDDING_SHOWING_RESULT_TIME_IN_MS;
        } else {
            showBiddingResultTime =
                previousWinningBid && !previousWinningBid.skipped
                    ? previousWinningBid.expiration + BIDDING_SHOWING_RESULT_TIME_IN_MS
                    : 0;
        }

        const disposer = executeAt(() => {
            callback('BIDDING_RESULT_NEXT');
        }, showBiddingResultTime);

        if (!disposer) {
            callback('BIDDING_RESULT_NEXT');
        }

        return disposer;
    };

    timerDisposer = checkBiddingResultStateEnded();

    const disposer = subscribeToWinningBidList(
        context,
        () => callback('BIDDING_RESULT_NEXT'),
        () => {
            timerDisposer?.clearTimeout();
            timerDisposer = checkBiddingResultStateEnded();
        }
    );

    const clockOffsetChangeDisposer = subscribeToClockOffsetChange(context.presenter.clock)(() => {
        timerDisposer?.clearTimeout();
        timerDisposer = checkBiddingResultStateEnded();
    });

    return () => {
        disposer.then((value) => value()).catch(logError());
        timerDisposer?.clearTimeout();
        clockOffsetChangeDisposer();
    };
};

export const beforeBiddingInvocation: Invocation = (context, event) => (callback) => {
    context.logger?.log('beforeBidding', {event});
    const previousWinningBid = context.presenter.previousWinningBid;
    context.logger?.log('beforeBidding', {previousWinningBid, presenter: context.presenter});

    if (!previousWinningBid) {
        callback('BEFORE_BIDDING_START');
        return () => {};
    }

    const getTimeToSwitchToNextState = () =>
        previousWinningBid.expiration + context.presenter.endBiddingIntermissionTimeInMs;

    let timerDisposer: LongTimeoutResult | undefined = undefined;
    let countDownDisposer: (() => void) | undefined;

    const checkBeforeBiddingStateEnded = () => {
        timerDisposer?.clearTimeout();
        const disposer = executeAt(() => {
            callback('BEFORE_BIDDING_START');
        }, getTimeToSwitchToNextState());

        if (!disposer) {
            callback('BEFORE_BIDDING_START');
        }

        return disposer;
    };

    const startBeforeBiddingCountdown = () => {
        countDownDisposer && countDownDisposer();
        const timeInMillis = getTimeToSwitchToNextState();
        const time = timeInMillis > Date.now() ? timeInMillis : 0;
        return startCountDown(context.presenter, time);
    };

    timerDisposer = checkBeforeBiddingStateEnded();
    countDownDisposer = startBeforeBiddingCountdown();

    const disposer = subscribeToWinningBidList(
        context,
        () => callback('BEFORE_BIDDING_START'),
        () => {
            timerDisposer = checkBeforeBiddingStateEnded();
            countDownDisposer = startBeforeBiddingCountdown();
        }
    );

    const clockOffsetChangeDisposer = subscribeToClockOffsetChange(context.presenter.clock)(() => {
        timerDisposer = checkBeforeBiddingStateEnded();
        countDownDisposer = startBeforeBiddingCountdown();
    });

    return () => {
        disposer.then((value) => value()).catch((e) => console.log(e));
        timerDisposer?.clearTimeout();
        countDownDisposer && countDownDisposer();
        clockOffsetChangeDisposer();
    };
};

export const endedRecentlyInvocation: Invocation = (context) => (callback) => {
    context.logger?.log('biddingResult', event);
    let timerDisposer: LongTimeoutResult | undefined = undefined;

    const checkEndedRecentlyStateEnded = () => {
        timerDisposer && timerDisposer.clearTimeout();

        const now = Date.now();
        const lastWinningBid = context.presenter.lastWinningBid!;

        if (!lastWinningBid) {
            return;
        }
        const timeAfterEnd = now - lastWinningBid.expiration;
        const time = now + (ENDED_RECENTLY_TIME_IN_MS - timeAfterEnd);

        const disposer = executeAt(() => callback('ENDED_RECENTLY_AUCTION_END'), time);

        if (!disposer) {
            callback('ENDED_RECENTLY_AUCTION_END');
        } else {
            context.logger?.log('Ended recently status will be changed at', new Date(time));
        }

        return disposer;
    };

    timerDisposer = checkEndedRecentlyStateEnded();

    const disposer = subscribeToWinningBidList(
        context,
        () => callback('ENDED_RECENTLY_NEXT'),
        () => {
            timerDisposer = checkEndedRecentlyStateEnded();
        }
    );

    const clockOffsetChangeDisposer = subscribeToClockOffsetChange(context.presenter.clock)(() => {
        timerDisposer = checkEndedRecentlyStateEnded();
    });

    return () => {
        disposer.then((value) => value()).catch((e) => console.log(e));
        timerDisposer && timerDisposer.clearTimeout();
        clockOffsetChangeDisposer();
    };
};

export const endedInvocation: Invocation = (context) => (callback) => {
    const disposer = subscribeToWinningBidList(
        context,
        () => callback('ENDED_NEXT'),
        () => callback('ENDED_NEXT')
    );

    return () => {
        disposer.then((value) => value()).catch(logError());
    };
};

export const sequenceAuctionStateMachine = Machine<
    SequenceAuctionStateMachineContext,
    SequenceAuctionStateMachineSchema,
    SequenceAuctionStateMachineEvent
>(
    {
        id: 'sequenceAuction',
        initial: SequenceAuctionStateEnum.BEFORE_PRE_BID,
        on: {
            PAUSE: SequenceAuctionStateEnum.PAUSED,
            SUSPEND: SequenceAuctionStateEnum.SUSPENDED,
        },
        states: {
            beforePreBid: {
                invoke: {
                    src: beforePreBidInvocation,
                },
                on: {
                    BEFORE_PREBID_NEXT: [
                        {target: SequenceAuctionStateEnum.EMPTY, cond: 'isEmpty'},
                        {target: SequenceAuctionStateEnum.PAUSED, cond: 'isPaused'},
                        {target: SequenceAuctionStateEnum.SUSPENDED, cond: 'isSuspended'},
                        {target: SequenceAuctionStateEnum.PRE_BID, cond: 'ifFirst'},
                        {target: SequenceAuctionStateEnum.BEFORE_START, cond: 'ifPositiveCountDown'}, // seems like this condition is not needed
                        {target: SequenceAuctionStateEnum.BIDDING_RESULT, cond: 'ifItemExists'},
                        {target: SequenceAuctionStateEnum.ENDED_RECENTLY}, // seems like this condition is not needed
                    ],
                    BEFORE_PREBID_CHECK_EMPTY: [{target: SequenceAuctionStateEnum.EMPTY, cond: 'isEmpty'}],
                    BEFORE_PREBID_ERROR: SequenceAuctionStateEnum.ERROR,
                },
            },
            preBid: {
                invoke: {
                    src: preBidInvocation,
                },
                on: {
                    PREBID_NEXT: [
                        {target: SequenceAuctionStateEnum.BEFORE_START, cond: 'ifPositiveCountDown'},
                        {target: SequenceAuctionStateEnum.BIDDING_RESULT, cond: 'ifItemExists'},
                        {target: SequenceAuctionStateEnum.ENDED_RECENTLY},
                    ],
                },
            },
            beforeStart: {
                invoke: {
                    src: beforeStartInvocation,
                },
                on: {
                    BEFORE_START_NEXT: [
                        {target: SequenceAuctionStateEnum.BIDDING, cond: 'ifItemExists'},
                        {target: SequenceAuctionStateEnum.ENDED_RECENTLY},
                    ],
                },
            },
            bidding: {
                entry: ['setNextOrCurrentNotEndedItem'],
                invoke: {
                    src: biddingInvocation,
                },
                on: {
                    BIDDING_END: [
                        {target: SequenceAuctionStateEnum.BIDDING_RESULT, cond: 'ifItemExists'},
                        {target: SequenceAuctionStateEnum.ENDED_RECENTLY},
                    ],
                    BIDDING_CHECK_IF_ENDED: SequenceAuctionStateEnum.AFTER_BIDDING,
                },
            },
            afterBidding: {
                invoke: {
                    src: afterBiddingInvocation,
                },
                on: {
                    AFTER_BIDDING_END: [{target: SequenceAuctionStateEnum.BIDDING_RESULT}],
                    AFTER_BIDDING_START: [
                        {target: SequenceAuctionStateEnum.BIDDING, cond: 'ifItemExists'},
                        {target: SequenceAuctionStateEnum.ENDED_RECENTLY},
                    ],
                },
            },
            biddingResult: {
                entry: ['setNextOrCurrentNotEndedItem'],
                invoke: {
                    src: biddingResultInvocation,
                },
                on: {
                    BIDDING_RESULT_NEXT: [
                        {target: SequenceAuctionStateEnum.BEFORE_BIDDING, cond: 'ifItemExists'},
                        {target: SequenceAuctionStateEnum.ENDED_RECENTLY},
                    ],
                },
            },
            beforeBidding: {
                entry: ['setNextOrCurrentNotEndedItem'],
                invoke: {
                    src: beforeBiddingInvocation,
                },
                on: {
                    BEFORE_BIDDING_START: [
                        {target: SequenceAuctionStateEnum.BIDDING, cond: 'ifItemExists'},
                        {target: SequenceAuctionStateEnum.ENDED_RECENTLY},
                    ],
                },
            },
            paused: {
                entry: ['setNextOrCurrentNotEndedItem'],
                invoke: {
                    src: pauseStateInvocation,
                },
                on: {
                    RESUME: [{target: SequenceAuctionStateEnum.BEFORE_PRE_BID}],
                },
            },
            suspended: {
                entry: ['setNextOrCurrentNotEndedItem'],
                invoke: {
                    src: pauseStateInvocation,
                },
                on: {
                    RESUME: [{target: SequenceAuctionStateEnum.BEFORE_PRE_BID}],
                },
            },
            endedRecently: {
                entry: ['checkAuctionBiddingEnded'],
                invoke: {
                    src: endedRecentlyInvocation,
                    onError: {
                        target: SequenceAuctionStateEnum.ERROR,
                    },
                },
                on: {
                    ENDED_RECENTLY_AUCTION_END: SequenceAuctionStateEnum.ENDED,
                    ENDED_RECENTLY_NEXT: SequenceAuctionStateEnum.BEFORE_PRE_BID,
                },
            },
            ended: {
                invoke: {
                    src: endedInvocation,
                },
                on: {
                    ENDED_NEXT: SequenceAuctionStateEnum.BEFORE_PRE_BID,
                },
            },
            error: {
                type: 'final',
                invoke: {
                    id: 'error',
                    src: (context, event) => () => {
                        context.logger?.log('ERROR: Auction is in invalid state.', event);
                    },
                },
            },
            empty: {
                type: 'final',
                invoke: {
                    id: 'empty',
                    src: (context, event) => () => {
                        context.logger?.log('EMPTY: Auction has no items.', event);
                    },
                },
            },
        },
    },
    {
        guards: {
            isPaused: (context, event) => {
                context.logger?.log('isPaused', {event});
                const isPaused = context.presenter.auction.isPaused;
                context.logger?.log(JSON.stringify({isPaused}));
                return isPaused;
            },
            isSuspended: (context, event) => {
                context.logger?.log('isSuspended', {event});
                const isSuspended = context.presenter.auction.isSuspended;
                context.logger?.log(JSON.stringify({isSuspended}));
                return isSuspended;
            },
            ifFirst: (context, event) => {
                context.logger?.log('ifFirst', {event});
                const item = context.presenter.currentItem;
                const startItem = context.presenter.startItemKey;
                const ifFirst = !!item && item.key === startItem;
                context.logger?.log('', {item: item?.key, startItem, ifFirst});
                return ifFirst;
            },
            ifItemExists: (context, event) => {
                context.logger?.log('ifItemExists', {event});
                const item = setNextOrCurrentNotEndedItem(context);
                context.logger?.log('set next item returned', {item});
                return !!item;
            },
            ifPositiveCountDown: (context, event) => {
                context.logger?.log('ifPositiveCountDown', {event});
                const countDown = Math.ceil(context.presenter.startAuctionRemainder / ONE_SEC);
                context.logger?.log(JSON.stringify({countDown, ifPositiveCountDown: countDown > 0}));
                return countDown > 0;
            },
            isEmpty: (context, event) => {
                context.logger?.log('isEmpty', {event});
                const isEmpty = context.presenter.isEmpty;
                context.logger?.log(JSON.stringify({isEmpty}));
                return context.presenter.isEmpty;
            },
        },
        actions: {
            checkItemBiddingEnded,
            checkAuctionBiddingEnded: (context) => {
                const auction = context.presenter.auction;
                context.logger?.log('checkAuctionBiddingEnded', {
                    regionId: auction.regionId,
                    auctionId: auction.auctionId,
                    bidService: context.services.bidService,
                });
                context.services.bidService
                    .checkAuctionBiddingEnded(auction.regionId, auction.auctionId)
                    .catch(logError('SequenceAuctionStateMachine.checkAuctionBiddingEnded'));
            },
            setNextOrCurrentNotEndedItem,
        },
    }
);

function setNextOrCurrentNotEndedItem(context: SequenceAuctionStateMachineContext) {
    let item = context.presenter.currentItem;
    context.logger?.log('setNextOrCurrentNotEndedItem', {
        item,
        currentWinningBid: context.presenter.currentWinningBid,
        isNotEndedWinningBid: isNotEndedWinningBid(context),
    });
    while (item && context.presenter.currentWinningBid && !isNotEndedWinningBid(context)) {
        context.logger?.log('makeNextItem');
        item = context.presenter.makeNextItem();
    }
    context.logger?.log('setNextOrCurrentNotEndedItem return item', item?.key);
    return item;
}
