import {
    ValidatePhoneNumberReqData,
    ValidatePhoneNumberResData,
    PhoneValidationResponseCodeEnum,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/phones/ValidatePhoneNumberReqTypes';
import {BaseService} from './BaseService';
import {feReqRoutes} from '@joyrideautos/auction-core/src/services/FERoutingService';

export const VALID_TEST_PHONE_NUMBER = '+14444444444';
export const INVALID_TEST_PHONE_NUMBER = '+12222222222';
export const NOT_MOBILE_TEST_PHONE_NUMBER = '+13333333333';

const results: {[phoneNumber: string]: () => ValidatePhoneNumberResData} = {
    [VALID_TEST_PHONE_NUMBER]: () => ({
        code: PhoneValidationResponseCodeEnum.SUCCESS,
    }),
    [INVALID_TEST_PHONE_NUMBER]: () => ({
        code: PhoneValidationResponseCodeEnum.INVALID,
        message: 'An Error',
    }),
    [NOT_MOBILE_TEST_PHONE_NUMBER]: () => ({
        code: PhoneValidationResponseCodeEnum.NOT_MOBILE,
        message: 'An Error',
    }),
};

export class PhoneValidationService extends BaseService {
    async validatePhoneNumber(data: Partial<ValidatePhoneNumberReqData>): Promise<ValidatePhoneNumberResData> {
        const {phoneNumber} = data;
        if (!phoneNumber) {
            return <ValidatePhoneNumberResData>{
                code: 'UNKNOWN',
                message: 'Phone number is required',
            };
        }
        if (this.config.isEmulatedEnv) {
            this.logger.log('validating a phone', phoneNumber);
            if (results[phoneNumber]) {
                return (
                    results[phoneNumber]() ?? {
                        code: 'UNKNOWN',
                        message: 'Wrong test phone',
                    }
                );
            }
        }
        try {
            const response = await this.firebase.rpcService.call(feReqRoutes.API_PHONE_VALIDATE_PHONE_NUMBER)<
                ValidatePhoneNumberReqData,
                ValidatePhoneNumberResData
            >({
                phoneNumber,
            });
            return response.code === 'SUCCESS' ? response : Promise.reject(response);
        } catch (e) {
            return <ValidatePhoneNumberResData>{
                code: 'UNKNOWN',
                message: String(e),
            };
        }
    }
}
