import {CheckVerificationCodeResponse, OrderBy, Unsubscribe} from '../types';
import {User, UserWithId} from '@joyrideautos/auction-core/src/types/User';
import {GetZipCodeByIPRPCResData} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/getZipCodeByIPReqTypes';
import {
    CreateUserRPCResData,
    CreateUserRPCReqData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/createUserReqTypes';
import {UpdateUserRPCReqData} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/updateUserReqTypes';
import {ContactDetails, ContactDetailsCollection} from '@joyrideautos/auction-core/src/types/ContactDetails';
import {BuyerInfoWithUid} from '@joyrideautos/auction-core/src/types/Item';
import {SellerApplicationStatusEnum} from '@joyrideautos/auction-core/src/types/SellerApplication';
import {
    GetCoordinatesByZipCodeRPCReqData,
    GetCoordinatesByZipCodeRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/getCoordinatesByZipCodeReqTypes';
import {BaseService} from './BaseService';
import {feReqRoutes} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {
    GetZipCodeByCoordinatesRPCReqData,
    GetZipCodeByCoordinatesRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/getZipCodeByCoordinatesReqTypes';
import {User as FirebaseUser} from '../firebase/Auth';
import {AuctionPath} from '@joyrideautos/auction-core/src/types/AuctionOccurrence';
import {UserBidData} from '@joyrideautos/auction-core/src/types/Bid';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {
    BanUserGloballyReqTypes,
    BanUserGloballyResTypes,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/banUserGloballyReqTypes';
import {
    CheckVerificationCodeDataReqType,
    CheckVerificationCodeDataResType,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/phones/checkVerificationCodeDataReqTypes';
import {
    SendVerificationCodeDataReqType,
    SendVerificationCodeDataResType,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/phones/sendVerificationCodeDataReqTypes';

enum PermissionTypes {
    AUCTION = 'AUCTION',
}

export class UsersService extends BaseService {
    async sendEmailVerificationForUser(uid: string): Promise<void> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        await this.firebase.rpcService.call(feReqRoutes.API_USER_SEND_EMAIL_VERIFICATION_FOR_USER)({uid});
    }

    async updateEmailForUser(uid: string, email: string): Promise<void> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        await this.firebase.rpcService.call(feReqRoutes.API_USER_UPDATE_EMAIL_FOR_USER)({uid, email});
    }

    async saveUserNote(uid: string, noteText: string): Promise<void> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        await this.firebase.rpcService.call(feReqRoutes.API_USER_SAVE_USER_NOTE)({uid, noteText});
    }

    async getUserNote(uid: string): Promise<{note: string} | undefined> {
        const user = await this.firebase.firestore.fetchOnce<{note: string}>(
            this.firebase.firestore.documentRef(`/users/${uid}/notes/note`)
        );
        return user ?? undefined;
    }

    async saveContactDetails(
        keyForEdit: string | undefined,
        uid: string,
        contactDetails: Omit<ContactDetails, 'uid'>
    ): Promise<void> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        await this.firebase.rpcService.call(feReqRoutes.API_USER_SAVE_CONTACT_DETAILS)({
            keyForEdit,
            uid,
            ...contactDetails,
        });
    }

    async getContactDetails(uid: string): Promise<{[key: string]: Omit<WithKey<ContactDetails>, 'uid'>} | undefined> {
        const docs = await this.firebase.firestore.fetchOnceDocuments(
            this.firebase.firestore
                .collectionRef<ContactDetails>(`/users/${uid}/contactDetails`)
                .orderBy('timestamp', 'desc')
        );
        if (!docs) {
            return undefined;
        }
        const result: ContactDetailsCollection = {};
        docs.forEach((doc) => {
            result[doc.id!] = {...doc.data()!, uid, key: doc.id};
        });
        return result;
    }

    async updateVerificationStatusByAdminForUser(uid: string): Promise<Partial<User>> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_UPDATE_VERIFICATION_STATUS_BY_ADMIN_FOR_USER)({
            uid,
        });
    }

    async updateUserPassword(newPassword: string, user?: FirebaseUser): Promise<boolean> {
        const currentUser = this.firebase.auth.currentUser ?? undefined;
        return this.firebase.auth.updatePassword(newPassword, user ?? currentUser).then(() => true);
    }

    async confirmPasswordReset(oobCode: string, newPassword: string) {
        return this.firebase.auth
            .confirmPasswordReset(oobCode, newPassword)
            .then(() => {
                return true;
            })
            .catch<any>((error) => {
                console.error('Error occurred during password reset confirmation', error);
                return Promise.reject(error);
            });
    }

    async sendVerificationCode(phoneNumber?: string, uid?: string) {
        return await this.firebase.rpcService.call(feReqRoutes.API_PHONE_SEND_VERIFICATION_CODE)<
            SendVerificationCodeDataReqType,
            SendVerificationCodeDataResType
        >({
            phoneNumber,
            uid,
        });
    }

    async checkVerificationCode(code: string, updateUserProfile: boolean) {
        return await this.firebase.rpcService.call(feReqRoutes.API_PHONE_CHECK_VERIFICATION_CODE)<
            CheckVerificationCodeDataReqType,
            CheckVerificationCodeDataResType
        >({
            code,
            updateUserProfile,
        });
    }

    subscribeToAuctionsWithBids(
        {uid, regionId, auctionId}: AuctionPath & {uid: string},
        subscriber: (data: {[itemId: string]: UserBidData}) => Promise<void>
    ): Unsubscribe {
        return this.database.subscribe<{[itemId: string]: UserBidData}>(
            `/users/${uid}/auctionsWithBids/${regionId}/${auctionId}`,
            (data) => {
                if (data) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    subscriber(data).catch(this.logger.error.bind(this.logger));
                }
            }
        );
    }

    async cancelPhoneVerification(uid?: string): Promise<CheckVerificationCodeResponse> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_PHONE_CANCEL_PHONE_VERIFICATION)({uid});
    }

    async createUserInfo(data: CreateUserRPCReqData): Promise<CreateUserRPCResData> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_CREATE_USER)(data);
    }

    async updateUserInfo(data: UpdateUserRPCReqData): Promise<User> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_UPDATE_USER)(data);
    }

    async fetchUserInfo(uid: string): Promise<User> {
        const snapshot = await this.firebase.database.fetchOnceSnapshot(`/users/${uid}`);
        return snapshot.val();
    }

    subscribeToUserInfo(uid: string, subscriber: (userInfo: User) => void) {
        return this.firebase.database.subscribeToSnapshot(`/users/${uid}`, (snapshot) => {
            subscriber(snapshot.val());
        });
    }

    async findUserByEmail(email: string): Promise<UserWithId | undefined> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        try {
            return await this.firebase.rpcService.call(feReqRoutes.API_USER_FIND_USER_BY_EMAIL)({email});
        } catch (e: any) {
            return undefined;
        }
    }

    async findUserById(searchUid: string, uid: string): Promise<UserWithId> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_FIND_USER_BY_ID)({uid, searchUid});
    }

    async getWinningBuyerInfo(persistenceKey: string): Promise<BuyerInfoWithUid | null> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_GET_WINNING_BUYER_INFO)({persistenceKey});
    }

    async userIsAdmin(uid: string) {
        return (await this.firebase.database.fetchOnceSnapshot(`/userRoles/admins/${uid}`)).val();
    }

    async getUserActivity(uid: string): Promise<any> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_GET_USER_ACTIVITY)({uid});
    }

    async userHaveAccess(uid: string, module: PermissionTypes): Promise<User> {
        const permissions = (
            await this.firebase.database.fetchOnceSnapshot(`/userRoles/admins/${uid}/permissions/`)
        ).val();
        if (permissions && permissions[module.toLowerCase()]) {
            return permissions;
        }
        return Promise.reject(permissions);
    }

    async searchUsers(searchText: string, orderBy: OrderBy, ascending?: boolean): Promise<UserWithId[]> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_SEARCH_USERS)({searchText, orderBy, ascending});
    }

    async toggleAuctionAnnouncement(uid: string, regionId: string, auctionId: string, opened: boolean): Promise<any> {
        const data = !opened ? {dateClosed: new Date().toISOString()} : {dateClosed: null};
        return await this.firebase.database.setValues(`/userAnnouncements/${uid}/${regionId}/${auctionId}`, data);
    }

    async didUserCloseAnnouncement(uid: string, regionId: string, auctionId: string): Promise<number | undefined> {
        const dateClosed = await this.firebase.database.fetchOnceSnapshot(
            `/userAnnouncements/${uid}/${regionId}/${auctionId}/dateClosed`
        );
        return dateClosed.exists() ? Date.parse(dateClosed.val()) : undefined;
    }

    async setUserProfileCompleted(uid: string): Promise<void> {
        return this.firebase.database.setValues(`/users/${uid}/profileCompletedAt`, new Date().toISOString());
    }

    async getUserIdentityVerificationUrl(): Promise<any> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_PAYMENT_GET_USER_IDENTITY_VERIFICATION_URL)({});
    }

    async getStripeIdentityVerificationSession(): Promise<any> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_PAYMENT_GET_STRIPE_IDENTITY_VERIFICATION_SESSION)(
            {}
        );
    }

    async isUserBlacklisted(uid: string) {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_CHECK_IS_USER_BLACKLISTED)({uid});
    }

    async banUserGlobally(data: BanUserGloballyReqTypes): Promise<BanUserGloballyResTypes> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_BAN_USER_GLOBALLY)(data);
    }

    async getGloballyBannedUser(uid: string): Promise<any> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_GET_GLOBALLY_BANNED_USER)({uid});
    }

    async bypassEmailVerificationForUser(uid: string): Promise<any> {
        return this.firebase.rpcService.call(feReqRoutes.API_USER_BYPASS_EMAIL_VERIFICATION_FOR_USER)({uid});
    }

    async bypassPhoneVerificationForUser(uid: string): Promise<void> {
        return this.firebase.rpcService.call(feReqRoutes.API_USER_BYPASS_PHONE_VERIFICATION_FOR_USER)({uid});
    }

    async getZipCodeByCoordinates(data: GetZipCodeByCoordinatesRPCReqData): Promise<GetZipCodeByCoordinatesRPCResData> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_GET_ZIP_CODE_BY_COORDINATES)(data);
    }

    async getCoordinatesByZipCode(data: GetCoordinatesByZipCodeRPCReqData): Promise<GetCoordinatesByZipCodeRPCResData> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_GET_COORDINATES_BY_ZIP_CODE)(data);
    }

    async saveSellerApplication(payload: any): Promise<boolean> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_APPLICATION_UPDATE)(payload);
    }

    async cancelSellerApplicationProcess(): Promise<boolean> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_APPLICATION_PROCESS_CANCEL)();
    }

    async getSellerApplicationCurrentStatus(): Promise<SellerApplicationStatusEnum | null> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_APPLICATION_STATUS_GET)();
    }

    async confirmSellerApplication(): Promise<boolean> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_APPLICATION_CONFIRM)();
    }

    async goBackToSellerApplicationForm(): Promise<boolean> {
        // TODO (Future): move out the returned type to core/src/types/requests/RPCReqTypes.ts
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_APPLICATION_GO_BACK)();
    }

    /**
     * Returns ZIP code by the current IP of the user
     */
    async getZipCodeByIP(): Promise<GetZipCodeByIPRPCResData> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_GET_ZIP_CODE_BY_IP)();
    }
}
