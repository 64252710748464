import {types, isAlive, Instance} from 'mobx-state-tree';
import {
    ItemInvoice,
    ItemInvoiceType,
    ItemPayment,
    ItemPaymentType,
    PaymentStatistics,
    PaymentStatisticsType,
    fromItemPaymentDto,
    fromItemInvoiceDto,
    fromPaymentStatisticsDto,
} from '../types/ItemPayment';
import {values} from 'mobx';
import {PaymentTypeEnum} from '@joyrideautos/auction-core/src/types/Payments';
import BaseStore from './BaseStore';

function makeKey(p: {regionId: string; auctionId: string; itemId: string; type?: string}) {
    return `${p.regionId}-${p.auctionId}-${p.itemId}-${p.type}`;
}
function makeInvoiceKey(p: {regionId: string; auctionId: string; invoiceId: string}) {
    return `${p.regionId}-${p.auctionId}-${p.invoiceId}`;
}

export const ItemPaymentsStore = BaseStore.named('ItemPaymentsStore')
    .props({
        payments: types.map(ItemPayment),
        invoices: types.map(ItemInvoice),
        paymentStatistics: types.map(types.maybeNull(PaymentStatistics)),
        payInFullDiscount: types.maybe(types.number),
    })
    .actions((self) => ({
        setPayments(payments: ItemPaymentType[]) {
            payments.forEach((p) => {
                const {itemId, regionId, auctionId, type} = p;
                self.payments.set(makeKey({itemId: String(itemId), regionId, auctionId, type}), p);
            });
        },
        setInvoices(invoices: ItemInvoiceType[]) {
            invoices.forEach((p) => {
                const {invoiceId, regionId, auctionId} = p;
                self.invoices.set(makeInvoiceKey({invoiceId, regionId, auctionId}), p);
            });
        },
        setStatistics(uid: string, statistics: PaymentStatisticsType | null) {
            self.paymentStatistics.set(uid, statistics);
        },
        setPayInFullDiscount(discount: number) {
            self.payInFullDiscount = discount;
        },
    }))
    .views((self) => ({
        getPayment(regionId: string, auctionId: string, itemId: string, type: PaymentTypeEnum) {
            return self.payments.get(makeKey({regionId, auctionId, itemId, type}));
        },
        get allPayments(): ItemPaymentType[] {
            return values(self.payments).flat();
        },
        get allInvoices(): ItemInvoiceType[] {
            return values(self.invoices).flat();
        },
        getStatistics(uid: string) {
            return self.paymentStatistics.get(uid);
        },
    }))
    .actions((self) => {
        let paymentResultsDisposer = () => {};
        let invoiceResultsDisposer = () => {};
        let paymentStatisticsDisposer = () => {};
        let paymentStatisticsDisposers: {[uid: string]: () => void} = {};

        return {
            subscribe(uid: string) {
                paymentResultsDisposer();
                invoiceResultsDisposer();
                self.setPayments([]);
                self.setInvoices([]);
                paymentResultsDisposer = self.itemPaymentService.subscribeToPaymentResults(uid, (payments) => {
                    self.setPayments(payments.map(fromItemPaymentDto));
                });
                invoiceResultsDisposer = self.itemPaymentService.subscribeToInvoiceResults(uid, (invoices) => {
                    self.setInvoices(invoices.map(fromItemInvoiceDto));
                });
            },

            unsubscribe() {
                paymentResultsDisposer();
                invoiceResultsDisposer();
                this.unsubscribeFromPaymentStatistics();
            },

            subscribeToStatistics() {
                paymentStatisticsDisposer();
                paymentStatisticsDisposer = self.itemPaymentService.subscribeToPaymentStatistics((statistics) => {
                    Object.keys(statistics).forEach((uid) => {
                        self.setStatistics(uid, fromPaymentStatisticsDto(statistics[uid]));
                    });
                });
            },

            subscribeToStatisticsForUser(uid: string) {
                const disposer = paymentStatisticsDisposers[uid];
                if (!disposer) {
                    paymentStatisticsDisposers[uid] = self.itemPaymentService.subscribeToPaymentStatisticsForUser(
                        uid,
                        (statistics) => {
                            self.setStatistics(uid, statistics ? fromPaymentStatisticsDto(statistics) : null);
                        }
                    );
                }
            },

            unsubscribeFromPaymentStatistics() {
                paymentStatisticsDisposer();
                paymentStatisticsDisposers = {};

                Object.values(paymentStatisticsDisposers).forEach((disposer) => disposer());
                paymentStatisticsDisposer = () => {};

                self.paymentStatistics.clear();
            },

            afterCreate() {
                self.paymentService
                    .getPayInFullDiscount()
                    .then((discount) => {
                        if (isAlive(self)) {
                            self.setPayInFullDiscount(discount);
                        }
                    })
                    .catch((e) => console.log(e));
            },

            beforeDestroy() {
                this.unsubscribe();
            },
        };
    });
export interface ItemPaymentStoreType extends Instance<typeof ItemPaymentsStore> {}

export interface HasItemPaymentsStore {
    paymentsStore: ItemPaymentStoreType;
}
