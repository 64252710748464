import merge from 'lodash/merge';
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import omitBy from 'lodash/omitBy';
import differenceWith from 'lodash/differenceWith';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import lodashOmit from 'lodash/omit';
import lodashPick from 'lodash/pick';
import lodashGet from 'lodash/get';
import LRUCache from './LRUCache';
import {diff as deepDiff, PreFilterFunction, Diff} from 'deep-diff';
/**
 * remove all undefined properties from the object, since Firebase doesn't accept them
 */
export function sanitizeObject<T>(obj: T) {
    return typeof obj === 'object' && obj ? (doSanitizeObject(obj) as typeof obj) : obj;
}

export function get<U, T extends string | number | symbol>(record: U, key: T) {
    return lodashGet(record, key);
}

export function omitProperty<T, K extends Array<keyof T>>(obj: T, ...props: K): Omit<T, K[number]> {
    const newObj: Omit<T, K[number]> = Object.assign({}, obj);
    for (const prop of props) {
        delete (newObj as T)[prop];
    }
    return newObj;
}

export function isClassObj(obj: any) {
    return Object.getPrototypeOf(obj) !== Object.prototype;
}

function doSanitizeObject(obj: any) {
    const copy = Array.isArray(obj) ? [] : ({} as any);
    for (const key of Object.keys(obj)) {
        const objValue = obj[key];
        if (objValue === Object(objValue)) {
            // We also need to sanitize Error objects because custom Errors may have undefined props.
            // We shouldn't modify other class instances because they may be required, e.g. Date, Firestore.Timstamp.
            // We can't remove all undefined props of all custom class instances (so we do it only for Errors for now)
            // because it's hard to handle some objects that hide props/methods in Symbol, private props, or native code (like Date.getTime)
            if (!Array.isArray(objValue) && !(objValue instanceof Error) && isClassObj(objValue)) {
                copy[key] = objValue;
            } else {
                copy[key] = doSanitizeObject(objValue);
            }
        } else if (typeof objValue !== 'undefined') {
            copy[key] = objValue;
        }
    }
    return copy;
}

// TODO: move to array utils
export function filterUndef<T>(ts: (T | undefined | null)[]): T[] {
    return ts.filter((t: T | undefined | null): t is T => typeof t !== 'undefined' && t !== null);
}

export {merge, omitBy, isEmpty, isEqual, cloneDeep};

/**
 * https://lodash.com/docs/4.17.15#defaultsDeep
 */
export {defaultsDeep};

export function convertToObj<T = any>(obj: ArrayLike<any> | any): T {
    return Object.keys(obj || {}).reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
    }, {} as any);
}

export function arrayToObj<Element = any>(
    arr: Element[],
    toKey: (el: Element) => {key: string; propertyName: string},
    options?: {removeKey?: boolean}
): Record<string, Element> {
    return arr.reduce<Record<string, Element>>((acc, el) => {
        const {key, propertyName} = toKey(el);
        const removeKey = options?.removeKey ?? true;
        if (removeKey) {
            // @ts-ignore
            delete el[propertyName];
        }
        acc[key] = el;
        return acc;
    }, {});
}

export const toArrayBuffer = (blob: Blob): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onloadend = () => {
            resolve(fr.result as ArrayBuffer);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(blob);
    });
};

// Be careful, this method returns only one-way difference
// {aProp: 'aValue'} diff to {bProp: 'bValue'} returns {aProp: 'aValue'}
// {bProp: 'bValue'} diff {aProp: 'aValue'} to  returns {bProp: 'bValue'}
// Use deep-diff lib based method instead (see method below)
export function objDiff<T, U extends Record<keyof T, unknown>>(a: U, b: U): Record<string, unknown> {
    return fromPairs(differenceWith(toPairs(a), toPairs(b), isEqual));
}

export function diff<T extends Record<string, any>>(
    a: T | null | undefined,
    b: T | null | undefined,
    prefilter?: PreFilterFunction
): Array<Diff<T | null | undefined, T | null | undefined>> | undefined {
    return deepDiff(a, b, prefilter);
}

export const omit = (obj: Record<string, any>, keys: string[]) => {
    return lodashOmit(obj, keys);
};

export const pick = (obj: Record<string, any>, keys: string[]) => {
    return lodashPick(obj, keys);
};

export function mergeMaps<Key, Value>(a: Map<Key, Value>, b: Map<Key, Value>) {
    const merged = new Map<Key, Value>(a);
    for (const [key, value] of b.entries()) {
        merged.set(key, value);
    }
    return merged;
}

export function createMemoize(cacheSize = 1) {
    const cache = new LRUCache<any>(cacheSize);
    return function <T>(obj: T | null | undefined): T | null | undefined {
        if (obj == null) {
            return obj;
        }
        const cached = cache.get(JSON.stringify(obj));
        if (cached) {
            return cached;
        }
        cache.set(JSON.stringify(obj), obj);
        return obj;
    };
}

export function mapObjectValues<T, P>(inputObject: T, mapper: (value: T[keyof T]) => P): {[key in keyof T]: P} {
    const outputObject = {} as {[key in keyof T]: P};

    for (const [key, value] of Object.entries<T[keyof T]>(inputObject as {[key in keyof T]: T[key]})) {
        outputObject[key as keyof T] = mapper(value);
    }

    return outputObject;
}

export function getObjectKeys<T extends object>(obj: T): (keyof T)[] {
    return Object.keys(obj) as (keyof T)[];
}
