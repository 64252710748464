import {logError} from '@joyrideautos/ui-logger/src/utils';
import {NotificationItem} from '@joyrideautos/auction-core/src/types/events/transient';
import {DataSnapshot} from '../firebase/Database';
import {BaseService} from './BaseService';
import {WithKey} from '../firebase/types';
import {Notification, NotificationState} from '@joyrideautos/auction-core/src/types/events/notifications';
import {feReqRoutes} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {EventSubscriptionDto} from '../types';
import {
    MarkForRemoveTransientEventStateReqType,
    MarkForRemoveTransientEventStateResType,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/markForRemoveTransientEventStateReqTypes';
import {
    SaveUserNotificationsReqData,
    SaveUserNotificationsResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/saveUserNotificationReqTypes';
import {
    UpdateStateForAllUserPersistedNotificationsReqType,
    UpdateStateForAllUserPersistedNotificationsResType,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/updateStateForAllUserPersistedNotificationsReqTypes';
import {SortingEnum} from '@joyrideautos/auction-core/src/types/Sorting';
import {mapValuesObjectToArrayWithKey} from '@joyrideautos/auction-core/src/types/common';

export type SubscriptionCallback = (a: DataSnapshot, b?: string | null) => any;

function collectionsSnapshotCallback<T extends Record<string, any>>(
    callback: (events: T[]) => void
): SubscriptionCallback {
    return (collectionSnap: DataSnapshot) => {
        const values: T[] = [];
        if (collectionSnap) {
            collectionSnap.forEach((evSnap) => {
                values.push({...evSnap.val(), key: evSnap.key});
            });
        }
        callback(values);
    };
}

const sortNotificationsFn = <T extends {timestamp?: number}>(sorting?: SortingEnum) =>
    ({
        [SortingEnum.ASC]: (a: T, b: T) => (a.timestamp ?? 0) - (b.timestamp ?? 0),
        [SortingEnum.DESC]: (a: T, b: T) => (b.timestamp ?? 0) - (a.timestamp ?? 0),
    }[sorting ?? SortingEnum.DESC]);

export class EventsService extends BaseService {
    subscribeToPersistedNotifications(
        uid: string,
        state: NotificationState,
        callback: (events: WithKey<Notification>[]) => void,
        limit?: number,
        sorting?: SortingEnum
    ) {
        let query = this.firebase.firestore
            .collectionRef<Notification>(`/users/${uid}/notifications`)
            .orderBy('timestamp')
            .where('state', '==', state);
        if (limit) {
            query = {[SortingEnum.ASC]: query.limit(limit), [SortingEnum.DESC]: query.limitToLast(limit)}[
                sorting ?? SortingEnum.DESC
            ];
        }
        return this.firebase.firestore.subscribeToCollection<Notification>(query, (notifications) => {
            if (!notifications) {
                return callback([]);
            }
            callback(
                mapValuesObjectToArrayWithKey(notifications)
                    .sort(sortNotificationsFn(sorting))
                    .map((e: any) => ({
                        ...e,
                        params: {...e.params, type: e.type},
                    }))
            );
        });
    }

    subscribeToPersistedNotificationsCount(
        uid: string,
        params: {
            filters: {
                name: keyof Notification;
                value: Notification[keyof Notification] | Notification[keyof Notification][];
            }[];
            limit?: number;
        },
        callback: (eventsCount: number) => void
    ) {
        let query = this.firebase.firestore.collectionRef<Notification>(`/users/${uid}/notifications`);
        for (const {name, value} of params.filters) {
            if (Array.isArray(value)) {
                query = query.where(name, 'in', value);
            } else {
                query = query.where(name, '==', value);
            }
        }
        if (params.limit) {
            query = query.limit(params.limit);
        }
        return this.firebase.firestore.subscribeToCollectionCount(query, (count) => callback(count));
    }

    fetchPersistedNotificationsCount(
        uid: string,
        filters: {
            name: keyof Notification;
            value: Notification[keyof Notification] | Notification[keyof Notification][];
        }[]
    ) {
        let query = this.firebase.firestore.collectionRef<Notification>(`/users/${uid}/notifications`);
        for (const {name, value} of filters) {
            if (Array.isArray(value)) {
                query = query.where(name, 'in', value);
            } else {
                query = query.where(name, '==', value);
            }
        }
        return this.firebase.firestore.fetchCollectionCount(query);
    }

    getPersistedNotificationsIterator(
        uid: string,
        options: {
            limit: number;
            filters?: {
                name: keyof Notification;
                value: Notification[keyof Notification] | Notification[keyof Notification][];
            }[];
            sorting: SortingEnum;
        }
    ): AsyncGenerator<WithKey<Notification>[]> {
        const fetchChunkAsc = async (lastSnapshotTs: number | null) => {
            let query = this.firebase.firestore
                .collectionRef<Notification>(`/users/${uid}/notifications`)
                .orderBy('timestamp');
            if (lastSnapshotTs) {
                for (const {name, value} of options.filters ?? []) {
                    if (Array.isArray(value)) {
                        query = query.where(name, 'in', value);
                    } else {
                        query = query.where(name, '==', value);
                    }
                }
                query = query.startAfter(lastSnapshotTs);
            } else {
                for (const {name, value} of options.filters ?? []) {
                    if (Array.isArray(value)) {
                        query = query.where(name, 'in', value);
                    } else {
                        query = query.where(name, '==', value);
                    }
                }
            }
            query = query.limit(options.limit);
            const snapshots = await this.firebase.firestore.fetchOnceArray<Notification, WithKey<Notification>>(query);
            lastSnapshotTs = snapshots[options.limit - 1]?.timestamp ?? null;
            snapshots.sort(sortNotificationsFn(SortingEnum.ASC));
            return {snapshots, snapshotTs: lastSnapshotTs};
        };

        const fetchChunkDesc = async (firstSnapshotTs: number | null) => {
            let query = this.firebase.firestore
                .collectionRef<Notification>(`/users/${uid}/notifications`)
                .orderBy('timestamp');
            if (firstSnapshotTs) {
                for (const {name, value} of options.filters ?? []) {
                    if (Array.isArray(value)) {
                        query = query.where(name, 'in', value);
                    } else {
                        query = query.where(name, '==', value);
                    }
                }
                query = query.endBefore(firstSnapshotTs);
            } else {
                for (const {name, value} of options.filters ?? []) {
                    if (Array.isArray(value)) {
                        query = query.where(name, 'in', value);
                    } else {
                        query = query.where(name, '==', value);
                    }
                }
            }
            query = query.limitToLast(options.limit);
            const snapshots = await this.firebase.firestore.fetchOnceArray<Notification, WithKey<Notification>>(query);
            firstSnapshotTs = snapshots.length === options.limit ? snapshots[0]?.timestamp ?? null : null;
            snapshots.sort(sortNotificationsFn(SortingEnum.DESC));
            return {snapshots, snapshotTs: firstSnapshotTs};
        };

        const fetchChunk: (snapshotTs: number | null) => Promise<{
            snapshots: WithKey<Notification>[];
            snapshotTs: number | null;
        }> = {[SortingEnum.ASC]: fetchChunkAsc, [SortingEnum.DESC]: fetchChunkDesc}[options.sorting];

        return (async function* () {
            let _snapshotTs: number | null = null;
            while (true) {
                const {snapshots, snapshotTs} = await fetchChunk(_snapshotTs);
                _snapshotTs = snapshotTs;
                yield snapshots.map((e: any) => ({
                    ...e,
                    params: {...e.params, type: e.type},
                }));
                if (_snapshotTs == null) {
                    return;
                }
            }
        })();
    }

    subscribeToNewTransientEvents(uid: string, callback: (events: WithKey<NotificationItem>) => void) {
        let unsubscribe: (() => void) | undefined;
        return ((self) => {
            let fetchEventsAndSubscribe: (() => Promise<void>) | undefined = async function () {
                await self.markForRemoveTransientEventState({removeAllExceptLastN: true});
                const eventsQuery = self.firebase.database.ref(`events/transient/${uid}`).orderByKey();
                unsubscribe = self.firebase.database.subscribeToSnapshot(
                    eventsQuery,
                    (snap) => callback({...snap.val(), key: snap.key}),
                    'child_added'
                );
            };
            fetchEventsAndSubscribe().catch(logError());
            return () => {
                unsubscribe && unsubscribe();
                fetchEventsAndSubscribe = undefined;
            };
        })(this);
    }

    async markForRemoveTransientEventState(data: MarkForRemoveTransientEventStateReqType) {
        await this.firebase.rpcService.call(feReqRoutes.API_USER_MARK_FOR_REMOVE_TRANSIENT_EVENT_STATE)<
            MarkForRemoveTransientEventStateReqType,
            MarkForRemoveTransientEventStateResType
        >(data);
    }

    async updateStateForAllUserPersistedNotifications(data: UpdateStateForAllUserPersistedNotificationsReqType) {
        await this.firebase.rpcService.call(feReqRoutes.API_USER_UPDATE_STATE_FOR_ALL_USER_PERSISTED_NOTIFICATIONS)<
            UpdateStateForAllUserPersistedNotificationsReqType,
            UpdateStateForAllUserPersistedNotificationsResType
        >(data);
    }

    async savePersistedNotifications(data: SaveUserNotificationsReqData) {
        await this.firebase.rpcService.call(feReqRoutes.API_USER_SAVE_PERSISTED_NOTIFICATIONS)<
            SaveUserNotificationsReqData,
            SaveUserNotificationsResData
        >(data);
    }

    async createEventSubscription(subscription: EventSubscriptionDto): Promise<void> {
        await this.firebase.database.pushValues(`/eventSubscriptions`, subscription);
    }

    listenEventSubscriptions(uid: string, callback: (s: (EventSubscriptionDto & {key: string})[]) => void) {
        const userSubscriptions = this.firebase.database.ref(`/eventSubscriptions`).orderByChild('uid').equalTo(uid);
        return this.firebase.database.subscribeToSnapshot(userSubscriptions, collectionsSnapshotCallback(callback));
    }
}
