import {ONE_DAY} from '@joyrideautos/auction-utils/src/dateTimeUtils';

export const COMPANY_NAME = 'Autura Marketplace';
export const TIME_FORMAT = 'LT';
export const NOTIFICATION_TYPE = {
    INFO: 'info',
    WARNING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
} as const;
export const CANT_UNSOLD_PERIOD_HOURS = 72;

// authorization amount to charge when new card is added to user's profile
export const AUTH_AMOUNT_CENTS = 5000;
export const DEFAULT_ITEMS_PER_PAGE_GRID = 4;
export const DEFAULT_ITEMS_PER_PAGE_LIST = 10;

// TODO (Future): move to auction module since it's UI related only
export const TAB_NAME = {
    ALL: 'All',
    DEPOSITS_OUTSTANDING: 'Deposits Outstanding',
    FAVORITES: 'Favorites',
    STORED: 'Stored',
    CANDIDATE: 'Candidate',
    PUBLISHED: 'Published',
    UNSOLD: 'Unsold',
    WON_BIDDER: 'Won',
    SOLD_SELLER: 'Sold',
    PAID: 'Paid',
    PAYMENT_RECEIVED: 'Payment Received',
    PICKED_UP: 'Picked Up',
    ACTIVE_BIDS: 'Active Bids',
    AWAITING_PAYMENT: 'Awaiting Payment',
    OFFERS: 'Offers',
};

export const UNSCHEDULED_VEHICLE_PERIOD_HOURS = 12; // Do not allow unscheduling within 12 hours of a live auction

export type TabNames = typeof TAB_NAME[keyof typeof TAB_NAME];

export const CC_HOLD_AMOUNT_IN_USD = 100; // 100 USD. May need conversion to cents
export const CC_HOLD_START_CHARGE_IN_HOURS = 48;
export const CC_HOLD_START_CHARGE_IN_MS = CC_HOLD_START_CHARGE_IN_HOURS * 60 * 60 * 1000;

export const DEFAULT_ZIP_CODE_SEARCH_DISTANCE_IN_METERS = 5_000; // 5 km
export const ZIP_CODE_SIZE = 5; // 5 characters
export const DEFAULT_ZIPCODE = '94102'; // It's just a random zip code in San Francisco

export const SEARCH_TERM_MAX_LENGTH = 64;

// TODO (Future): move to auction module since it's UI related only
export const SEARCH_FILTERS = {
    LOCATION: 'Location',
    MAKE_MODEL: 'Make & Model',
    YEARS: 'Years',
    CURRENT_PRICE: 'Current Price',
    KEY_STATUS: 'Key Status',
    START_CODE: 'Start Code',
    ODOMETER: 'Odometer',
    SELLER: 'Seller',
    AUCTION_DATE: 'Auction Date',
    AUCTION_TYPE: 'Auction Type',
    BODY: 'Body',
    ENGINE: 'Engine',
    TRANSMISSION: 'Transmission',
    DRIVE_TRAIN: 'Drive Train',
    COLOR: 'Color',
    CATALYTIC_CONVERTER: 'Catalytic Converter',
    BUY_NOW: 'Buy Now',
} as const;

export const PLACE_BID_TIMEOUT_IN_MS = 3_000;
export const PUBLIC_SEARCH_BY_FILTERS_DEFAULT_LIMIT = 12;
export const INVENTORY_SEARCH_DEFAULT_LIMIT = 15;
export const MAX_SEARCH_LIMIT = 10000; // By default, you cannot use from and size to page through more than 10,000 hits. (https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html)
export const FILTER_BY_AUCTION_DROPDOWN_QUANTITY = 10;

export const DAYS_90 = 90;
export const AUCTIONS_HISTORY_DAYS = 90;
export const AUCTIONS_HISTORY_DAYS_IN_MS = AUCTIONS_HISTORY_DAYS * ONE_DAY;

export const PERMISSIBLE_DURATION_OF_VIEWING_FINANCIAL_INFO_IN_MS = 7 * ONE_DAY;

// TODO (Future): move to auction module since it's UI related only
export const SELLER_TOOLS_PAGE = {
    MY_INVENTORY: 'My Inventory',
    MY_AUCTIONS: 'My Auctions',
};

export const MY_AUCTIONS_PAGE_SIZE = 10;
export const MY_AUCTIONS_PAGE_SIZE_UNDER_ALL_TAB = 6;

export const ACTIVE_OFFER_DURATION_HOURS = 24;

export const CATALYTIC_CONVERTER_OTHER = {code: 'OTHER', name: 'Other'};

export const NO_VALIDATION_ERRORS = 'No validation errors';
