import {PlatformFeeCalculator} from './types';
import {StepFunctionPlatformFeeCalculator} from './StepFunctionPlatformFeeCalculator';
import {PercentPlatformFeeCalculator} from './PercentPlatformFeeCalculator';
import {AuctionOccurrenceSettings} from '../../types/AuctionOccurrence';
import {PlatformFeeSchedule, PlatformFeeTypes} from '../../types/PlatformFeeSchedule';

const createPlatformFeeCalculator = ({
    auctionSettings,
    platformFeeSchedule,
    isManagerForSeller,
}: {
    auctionSettings: AuctionOccurrenceSettings;
    platformFeeSchedule?: PlatformFeeSchedule | null;
    isManagerForSeller?: boolean;
}) => {
    return {
        [PlatformFeeTypes.PERCENTAGE]: (): PlatformFeeCalculator =>
            PercentPlatformFeeCalculator.getInstanceWithAuctionSettings(auctionSettings, {
                isManagerForSeller: isManagerForSeller ?? false,
            }),
        [PlatformFeeTypes.SCHEDULE]: (): PlatformFeeCalculator => {
            if (!platformFeeSchedule) {
                throw new Error('platformFeeSchedule is required');
            }
            return StepFunctionPlatformFeeCalculator.getInstance(platformFeeSchedule);
        },
    }[auctionSettings.platformFeeType ?? PlatformFeeTypes.PERCENTAGE]();
};

export default createPlatformFeeCalculator;
