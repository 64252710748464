import {types, getEnv, getRoot, Instance} from 'mobx-state-tree';
import type {AuctionSeriesStoreType} from '../AuctionSeriesStore';
import type {AuctionsStoreType} from '../AuctionsStore';
import type {AuthUserStoreType} from '../AuthUserStore';
import type {BidderStatusStoreType} from '../BidderStatusStore';
import type {BidsStoreType} from '../BidsStore';
import type {CcHoldStoreType} from '../CcHoldStore';
import type {FavoriteItemsStoreType} from '../FavoritesStore';
import type {FeatureFlagStoreType} from '../FeatureFlagStore';
import type {ItemPaymentStoreType} from '../ItemPaymentsStore';
import type {ItemsStoreType} from '../ItemsStore';
import type {ItemsStoreFactory} from '../ItemsStoreFactory';
import type {ManagerRoleStoreType} from '../ManagerRoleStore';
import type {RefsStoreType} from '../refsStore/RefsStore';
import type {RegionsStoreType} from '../RegionsStore';
import type {SellerRoleStoreType} from '../SellerRoleStore';
import type {SellerContainerType} from '../SellersStore';
import type {UserStoreType} from '../UserStore';
import type {WinningBidStoreType} from '../WinningBidStore';
import type {WonItemsStoreType} from '../WonItemsStore';
import type {SellerApplicationStoreType} from '../SellerApplicationStore';
import type {WhitelistStoreType} from '../WhitelistStore';
import type {MediaStoreType} from '../MediaStore';
import type {GlobalConfigStoreType} from '../GlobalConfigStore';
import type {LiveAuctionStateStoreType} from '../LiveAuctionStateStore';
import type {ClockModelType} from '../../utils/Clock';
import type {SellerSessionStorage} from '../../sessionStores/SellerSessionStorage';
import {UIServicesAwareViewModelType} from '../../common/UIServicesAwareViewModel';

const RootStoreAwareViewModel = types.model('RootStoreAwareViewModel').views((self) => {
    const {rootStore} = getEnv(self);

    function checkStoreExists(storeName: string, rootStore: any) {
        if (typeof rootStore[storeName] === 'undefined') {
            throw new Error(`Missing store: ${storeName}`);
        }
    }

    return {
        get rootStore(): Record<string, any> & UIServicesAwareViewModelType & {appConfig: any} {
            return rootStore || getRoot(self);
        },
        get sellerSessionStorage(): SellerSessionStorage {
            return this.rootStore.sellerSessionStorage;
        },
        get sessionStore() {
            return this.rootStore.sessionStore;
        },
        get localStorageStore() {
            return this.rootStore.localStorageStore;
        },
        get clock(): ClockModelType {
            return this.rootStore.clock;
        },
        get firebaseTraceInstance() {
            return this.rootStore.firebaseTraceService;
        },
        get regionsStore() {
            return this.getStoreByName<RegionsStoreType>('regionsStore');
        },
        get auctionsStore() {
            return this.getStoreByName<AuctionsStoreType>('auctionsStore');
        },
        get auctionSeriesStore() {
            return this.getStoreByName<AuctionSeriesStoreType>('auctionSeriesStore');
        },
        get liveAuctionStateStore() {
            return this.getStoreByName<LiveAuctionStateStoreType>('liveAuctionStateStore');
        },
        get sellersStore() {
            return this.getStoreByName<SellerContainerType>('sellersStore');
        },
        get winningBidStore() {
            return this.getStoreByName<WinningBidStoreType>('winningBidStore');
        },
        get bidsStore() {
            return this.getStoreByName<BidsStoreType>('bidsStore');
        },
        get biddersStatuses() {
            return this.getStoreByName<BidderStatusStoreType>('biddersStatuses');
        },
        get authUserStore() {
            return this.getStoreByName<AuthUserStoreType>('authUserStore');
        },
        get globalConfigStore() {
            return this.getStoreByName<GlobalConfigStoreType>('globalConfigStore');
        },
        get wonItemsStore() {
            return this.getStoreByName<WonItemsStoreType>('wonItemsStore');
        },
        get itemsStoreFactory() {
            return this.getStoreByName<ItemsStoreFactory>('itemsStoreFactory');
        },
        get sellerRoleStore() {
            return this.getStoreByName<SellerRoleStoreType>('sellerRoleStore');
        },
        get managerRoleStore() {
            return this.getStoreByName<ManagerRoleStoreType>('managerRoleStore');
        },
        get eventsStore() {
            // TODO: add a type
            return this.getStoreByName('eventsStore');
        },
        get favoritesStore() {
            return this.getStoreByName<FavoriteItemsStoreType>('favoritesStore');
        },
        get isSWNewVersionAvailable() {
            return this.getPropertyByName<boolean>('isSWNewVersionAvailable');
        },
        get ccHoldStore() {
            return this.getStoreByName<CcHoldStoreType>('ccHoldStore');
        },
        get soundStore() {
            // TODO: add a type
            return this.getStoreByName('soundStore');
        },
        get paymentsStore() {
            return this.getStoreByName<ItemPaymentStoreType>('paymentsStore');
        },
        get userStore() {
            return this.getStoreByName<UserStoreType>('userStore');
        },
        get itemsStore() {
            return this.getStoreByName<ItemsStoreType>('itemsStore');
        },
        get refsStore() {
            return this.getStoreByName<RefsStoreType>('refsStore');
        },
        get featureFlagStore() {
            return this.getStoreByName<FeatureFlagStoreType>('featureFlagStore');
        },
        get sellerApplicationStore() {
            return this.getStoreByName<SellerApplicationStoreType>('sellerApplicationStore');
        },
        get whitelistStore() {
            return this.getStoreByName<WhitelistStoreType>('whitelistStore');
        },
        get mediaStore() {
            return this.getStoreByName<MediaStoreType>('mediaStore');
        },
        getStoreByName<T = any>(name: string): T {
            return this.getPropertyByName<T>(name);
        },
        getPropertyByName<T>(name: string): T {
            checkStoreExists(name, this.rootStore);
            return this.rootStore[name];
        },
    };
});

export default RootStoreAwareViewModel;
export interface RootStoreAwareViewModelType extends Instance<typeof RootStoreAwareViewModel> {}
