import React, {FC, ReactNode} from 'react';
import {Col, Row, Layout, Typography, FloatButton} from 'antd';
import logo from '@joyrideautos/ui-common/src/images/Logo_white.svg';
import {Link} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../../Routes';
import {PDFModal} from '@pageComponents/pdfModal/PDFModalWithViewModel';
import {Copyright} from '@components/Copyright';
import {PDFModalViewModel} from '@joyrideautos/ui-models/src/presentation/pdfModal/PDFModalViewModel';
import {useViewModel} from '@joyrideautos/ui-models/src/hooks/useViewModel';
import {observer} from 'mobx-react-lite';
import {COMPANY_NAME} from '@joyrideautos/auction-core/src/constants/Constants';
import './Footer.less';

const {Footer: AntdFooter} = Layout;
const {Text} = Typography;

const Footer: FC<{anchor?: ReactNode}> = observer(({anchor}) => {
    const {viewModel} = useViewModel(PDFModalViewModel);
    if (!viewModel) {
        return <>{anchor || null}</>;
    }
    return (
        <>
            <PDFModal viewModel={viewModel} />
            <AntdFooter className="footer">
                {anchor || null}
                <FloatButton.BackTop className="back-top" />
                <div className="container">
                    <div className="footer-holder">
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                                <div className="footer-item footer-info">
                                    <Link to={Routes.HOME()} className="footer-logo">
                                        <img src={logo} width="103" height="34" alt={COMPANY_NAME} />
                                    </Link>
                                    <Text className="txt">
                                        Our unclaimed vehicle sales platform makes it easy and safe for anyone to buy
                                        and sell from anywhere.
                                    </Text>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-bottom">
                        <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div className="list">
                                    <Link
                                        to="#"
                                        className="item"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            viewModel.handleLocalDocument(
                                                'Privacy policy',
                                                '/docs/Autura_PrivacyPolicy.pdf'
                                            );
                                        }}>
                                        Privacy policy
                                    </Link>
                                    <Link
                                        to="#"
                                        className="item"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            viewModel.handleLocalDocument(
                                                'Terms of Service',
                                                '/docs/Autura_TermsOfService.pdf'
                                            );
                                        }}>
                                        Terms of Service
                                    </Link>
                                    <a
                                        href={Routes.SUPPORT_LINK()}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="item">
                                        Contact us
                                    </a>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Copyright></Copyright>
                            </Col>
                        </Row>
                    </div>
                </div>
            </AntdFooter>
        </>
    );
});

export default Footer;
