import React from 'react';
import {
    BidLossEventPayloadType,
    ItemAwareEventPayloadType,
    UploadEventPayloadType,
    UserEventPayloadType,
    AuctionEventPayloadType,
    BidHoldFailedEventPayloadType,
    BidHoldSuccessfulEventPayloadType,
    GDriveEventPayloadType,
    ReserveNotMetEventPayloadType,
} from '@joyrideautos/ui-models/src/types/events/EventDto';
import {EventType} from '@joyrideautos/ui-models/src/types/events/Event';
import {AlertFactoryOptions, AlertTemplateType, SettingsType} from './NotificationSettings';
import {Routes} from '../../Routes';
import {getBanReasonDescription} from '@joyrideautos/auction-core/src/rules/UserModel';
import {BanReasonEnum} from '@joyrideautos/auction-core/src/types/User';
import {COMPANY_NAME, NOTIFICATION_TYPE} from '@joyrideautos/auction-core/src/constants/Constants';
import {Observer} from 'mobx-react-lite';
import {Only} from '@joyrideautos/ui-common/src/components/Only';
import {NotificationEnum} from '@joyrideautos/auction-core/src/types/events/notifications';
import {
    BidRejectEventPayload,
    PaymentMethodBannedEventPayload,
    UserBanSuccessPayload,
} from '@joyrideautos/auction-core/src/types/events/persisted';
import {BID_REJECTION_REASON_MESSAGES} from '@joyrideautos/ui-models/src/types/Bid';

export class PersistedNotificationFactory {
    private settingsTemplate: AlertTemplateType<SettingsType> = {
        [NotificationEnum.RESERVE_NOT_MET]: (event: EventType, {router}) => {
            const {
                make,
                model,
                year,
                bidPath: {bidId, ...itemPath},
            } = event.params as ReserveNotMetEventPayloadType;
            return {
                message: 'Reserve not met',
                description: (
                    <Observer>
                        {() => (
                            <span>
                                <div>{`${year} ${make} ${model}`}</div>
                                <div>unsold due to the reserve</div>
                            </span>
                        )}
                    </Observer>
                ),
                type: NOTIFICATION_TYPE.WARNING,
                onClick: () => router.push(Routes.VEHICLE_PROFILE({pathParams: itemPath})),
            };
        },
        [NotificationEnum.BID_LOSS]: (event: EventType, {router}) => {
            const params = event.params as BidLossEventPayloadType;
            return {
                message: 'You were outbid',
                description: (
                    <Observer>
                        {() => (
                            <span>
                                <div>
                                    {params.itemDataAsync?.itemTitle ||
                                        (params.itemPath.itemId && `Vehicle ID: ${params.itemPath.itemId}`)}
                                </div>
                                {params.newBid && params.prevBid && (
                                    <span className="your-bid">
                                        ${params.newBid.amount} ← <em>${params.prevBid.amount} (your bid)</em>
                                    </span>
                                )}
                            </span>
                        )}
                    </Observer>
                ),
                type: NOTIFICATION_TYPE.WARNING,
                onClick: () => router.push(Routes.VEHICLE_PROFILE({pathParams: params.itemPath})),
            };
        },
        [NotificationEnum.ITEM_WON]: (event: EventType, {router}) => {
            const params = event.params as ItemAwareEventPayloadType;
            return {
                message: `Item Won`,
                description: (
                    <span>
                        <div>{params.auctionId && params.item && params.item.itemTitle}</div>
                    </span>
                ),
                type: NOTIFICATION_TYPE.INFO,
                onClick: () => router.push(Routes.VEHICLE_PROFILE({pathParams: params.itemPath})),
            };
        },
        [NotificationEnum.AUCTION_START]: (event: EventType, {router}) => {
            const params = event.params as AuctionEventPayloadType;
            return {
                message: `Auction Started`,
                type: NOTIFICATION_TYPE.INFO,
                onClick: () =>
                    router.push(
                        Routes.AUCTION_OCCURRENCE({
                            pathParams: {regionId: params.regionId!, auctionId: params.auctionId!.auctionId},
                        })
                    ),
            };
        },
        [NotificationEnum.AUCTION_ENDED]: (event: EventType, {router}) => {
            const params = event.params as AuctionEventPayloadType;
            return {
                message: `Auction completed`,
                type: NOTIFICATION_TYPE.INFO,
                onClick: () =>
                    router.push(
                        Routes.AUCTION_OCCURRENCE({
                            pathParams: {regionId: params.regionId!, auctionId: params.auctionId!.auctionId},
                        })
                    ),
            };
        },
        [NotificationEnum.BID_HOLD_FAILED_FOR_BUYER]: (event: EventType, {router}) => {
            const {auctionSeriesName, regionId, auctionId} = event.params as BidHoldFailedEventPayloadType;
            return {
                message: `Hold authorization failed`,
                description: (
                    <span>
                        <div>
                            The hold authorization attempt failed. To continue bidding in the {auctionSeriesName} please
                            complete the hold authorization.
                        </div>
                    </span>
                ),
                type: NOTIFICATION_TYPE.WARNING,
                onClick: () =>
                    router.push(
                        Routes.AUCTION_OCCURRENCE({
                            pathParams: {regionId, auctionId},
                        })
                    ),
            };
        },
        [NotificationEnum.BID_HOLD_SUCCESSFUL_FOR_BUYER]: (event: EventType, {router}) => {
            const {auctionSeriesName, regionId, auctionId} = event.params as BidHoldSuccessfulEventPayloadType;
            return {
                message: `$100 hold authorized`,
                description: (
                    <span>
                        <div>
                            Your hold was successfully authorized and you can continue continue bidding in the{' '}
                            {auctionSeriesName}.
                        </div>
                    </span>
                ),
                type: NOTIFICATION_TYPE.INFO,
                onClick: () =>
                    router.push(
                        Routes.AUCTION_OCCURRENCE({
                            pathParams: {regionId, auctionId},
                        })
                    ),
            };
        },
        [NotificationEnum.ITEM_PAYMENT_SUCCESSFUL]: (event: EventType, {router}) => {
            const params = event.params as ItemAwareEventPayloadType;
            return {
                message: `Payment successfully processed`,
                description: (
                    <span>
                        <div>{params.auctionId && params.item && params.item.itemTitle}</div>
                    </span>
                ),
                type: NOTIFICATION_TYPE.INFO,
                onClick: () => router.push(Routes.VEHICLE_PROFILE({pathParams: params.itemPath})),
            };
        },
        [NotificationEnum.ITEM_PAYMENT_FAILED]: (event: EventType, {router}) => {
            const params = event.params as ItemAwareEventPayloadType;
            return {
                message: `Payment failed`,
                description: (
                    <span>
                        <div>{params.auctionId && params.item && params.item.itemTitle}</div>
                    </span>
                ),
                type: NOTIFICATION_TYPE.WARNING,
                onClick: () => router.push(Routes.VEHICLE_PROFILE({pathParams: params.itemPath})),
            };
        },
        [NotificationEnum.USER_BANNED]: (event: EventType, {router}) => {
            const params = event.params as UserEventPayloadType;
            const auctionSeriesName = params.bannedFor;

            const LearnMoreLink = (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        router.push(Routes.PROFILE_SETTINGS({hash: 'payment_methods'}));
                    }}>
                    <strong>Learn more</strong>
                </a>
            );
            return {
                message: `Suspended`,
                description: (
                    <span>
                        <div>
                            You have been suspended from participating in the {auctionSeriesName}. {LearnMoreLink}
                        </div>
                    </span>
                ),
                type: NOTIFICATION_TYPE.ERROR,
            };
        },
        [NotificationEnum.USER_BANNED_GLOBALLY]: (event: EventType) => {
            const params = event.params as UserEventPayloadType;
            const reason = params.reason && (
                <>
                    {' '}
                    for <b>{getBanReasonDescription(params.reason as BanReasonEnum)}</b>
                </>
            );
            return {
                message: `Suspended`,
                description: (
                    <span className="notification-item-description">Your account have been suspended{reason}.</span>
                ),
                type: NOTIFICATION_TYPE.ERROR,
            };
        },
        [NotificationEnum.UPLOAD_SUCCESS]: (event) => {
            const params = event.params as UploadEventPayloadType;

            const item = params.item?.info.yearMakeModel;
            const seller = params.seller?.name;
            return {
                message: `Uploads Complete`,
                description: (
                    <span>
                        <div>
                            {item} @ {seller} media ready to view
                        </div>
                    </span>
                ),
                type: NOTIFICATION_TYPE.SUCCESS,
            };
        },
        [NotificationEnum.UPLOAD_FAILED]: (event) => {
            const params = event.params as UploadEventPayloadType;

            const item = params.item?.info.yearMakeModel;
            const seller = params.seller?.name;
            return {
                message: `Failed to Upload Media`,
                description: (
                    <span>
                        <div>
                            {item} @ {seller} failed to upload
                        </div>
                    </span>
                ),
                type: NOTIFICATION_TYPE.WARNING,
            };
        },
        [NotificationEnum.G_DRIVE_START_SUCCESS]: (event) => {
            const params = event.params as GDriveEventPayloadType;
            const {regionId, auctionId, auctionTitle} = params;

            return {
                message: `Vehicle import started`,
                description: (
                    <span>
                        <Only when={!!regionId && !!auctionId && !!auctionTitle}>
                            <div>Publishing vehicles to {auctionTitle} auction</div>
                        </Only>
                        <Only when={!auctionTitle}>
                            <div>Saving vehicles to My Inventory</div>
                        </Only>
                    </span>
                ),
                type: NOTIFICATION_TYPE.SUCCESS,
            };
        },
        [NotificationEnum.G_DRIVE_START_ERROR]: (event) => {
            const params = event.params as GDriveEventPayloadType;
            const {regionId, auctionId, auctionTitle} = params;

            return {
                message: 'Cannot initiate vehicle import',
                description: (
                    <span>
                        <Only when={!!regionId && !!auctionId && !!auctionTitle}>
                            <div>Failed to publish vehicles to {auctionTitle} auction</div>
                        </Only>
                        <Only when={!auctionTitle}>
                            <div>Failed to save vehicles to My Inventory</div>
                        </Only>
                    </span>
                ),
                type: NOTIFICATION_TYPE.ERROR,
            };
        },
        [NotificationEnum.G_DRIVE_FINISH_SUCCESS]: (event) => {
            const params = event.params as GDriveEventPayloadType;
            const {regionId, auctionId, auctionTitle} = params;

            return {
                message: `Successfully imported vehicles`,
                description: (
                    <span>
                        <Only when={!!regionId && !!auctionId && !!auctionTitle}>
                            <div>Vehicles published to {auctionTitle} auction</div>
                        </Only>
                        <Only when={!auctionTitle}>
                            <div>Vehicles saved to My Inventory</div>
                        </Only>
                    </span>
                ),
                type: NOTIFICATION_TYPE.SUCCESS,
            };
        },
        [NotificationEnum.G_DRIVE_FINISH_ERROR]: (event) => {
            const params = event.params as GDriveEventPayloadType;
            const {regionId, auctionId, auctionTitle} = params;

            return {
                message: `Error importing vehicles`,
                description: (
                    <span>
                        <Only when={!!regionId && !!auctionId && !!auctionTitle}>
                            <div>Failed to publish vehicles to {auctionTitle} auction</div>
                        </Only>
                        <Only when={!auctionTitle}>
                            <div>Failed to save vehicles to My Inventory</div>
                        </Only>
                    </span>
                ),
                type: NOTIFICATION_TYPE.ERROR,
            };
        },
        [NotificationEnum.UNSCHEDULE_VEHICLE]: (event: EventType, {router}) => {
            return {
                message: 'Your vehicle was removed from auction',
                description: (
                    <Observer>
                        {() => {
                            const {item, auctionTitle} = event.params as ItemAwareEventPayloadType;
                            // TODO: (Future) update eventDto to use persistenceKey instead of 'regionId, auctionId, itemId' to get item
                            // because when the vehicle was removed from the auction 'regionId, auctionId' will be removed/replaced
                            return (
                                <span className="notification-item-description">
                                    <div>{`${item?.itemTitle ?? 'The vehicle'} was removed from ${
                                        auctionTitle ? auctionTitle : 'the auction'
                                    }`}</div>
                                </span>
                            );
                        }}
                    </Observer>
                ),
                type: NOTIFICATION_TYPE.WARNING,
            };
        },
        [NotificationEnum.USER_BAN_SUCCESS]: (event) => ({
            message: 'Ban success',
            description: `Buyer ${
                (event.params as UserBanSuccessPayload).userEmail ?? ''
            } has been banned successfully`,
            type: NOTIFICATION_TYPE.SUCCESS,
        }),
        [NotificationEnum.USER_BAN_FAIL]: () => ({
            message: 'Buyer ban error',
            type: NOTIFICATION_TYPE.ERROR,
        }),
        [NotificationEnum.PAYMENT_METHOD_BANNED]: (event) => {
            const {last4, paymentMethodName} = event.params as PaymentMethodBannedEventPayload;
            const endingStr = last4 ? `(ending ${last4})` : '';
            const nameStr = paymentMethodName ? `"${paymentMethodName}"` : '';
            return {
                message: `Warning`,
                description: `Your card ${nameStr} ${endingStr} has been banned from ${COMPANY_NAME}, please contact support`,
                type: NOTIFICATION_TYPE.WARNING,
            };
        },
        [NotificationEnum.BID_REJECT]: (event) => ({
            message: 'Bid Rejected',
            description: (
                <span>
                    Your bid was rejected for the following reason:{' '}
                    {BID_REJECTION_REASON_MESSAGES[(event.params as BidRejectEventPayload).reason]}
                </span>
            ),
            type: NOTIFICATION_TYPE.WARNING,
        }),
    };

    createSettings(event: EventType, options: AlertFactoryOptions): SettingsType | null {
        const factory = this.settingsTemplate[event.type];
        return factory ? (factory(event, options) as SettingsType) : null;
    }
}
