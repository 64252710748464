import {
    FavoriteAuctionSeries,
    FavoriteAuction,
    FavoriteItem,
    FavoriteRegion,
} from '@joyrideautos/auction-core/src/types/Favorite';
import {feReqRoutes} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {BaseService} from './BaseService';

type FavoriteAuctionsListener = (favorites: {[auctionId: string]: FavoriteAuction}) => void;
type FavoriteAuctionSeriesListener = (favorites: {[auctionSeriesId: string]: FavoriteAuctionSeries}) => void;
type FavoriteItemsListener = (favorites: {[itemKey: string]: FavoriteItem}) => void;
type FavoriteRegionsListener = (favorites: {[regionId: string]: FavoriteRegion}) => void;

export class FavoritesService extends BaseService {
    subscribeToFavoriteRegions(uid: string, listener: FavoriteRegionsListener) {
        return this.firebase.database.subscribeToSnapshot(`/users/${uid}/favorites/regions`, (snapshot) => {
            const favorites: {[regionId: string]: FavoriteRegion} = {};

            if (snapshot.exists()) {
                snapshot.forEach((regionSnapshot) => {
                    const regionId = regionSnapshot.key!;
                    favorites[regionId] = {
                        regionId,
                        sortableKey: regionSnapshot.val(),
                    } as FavoriteRegion;
                });
            }

            listener(favorites);
        });
    }

    subscribeToFavoriteAuctionSeries(uid: string, listener: FavoriteAuctionSeriesListener) {
        return this.firebase.database.subscribeToSnapshot(`/users/${uid}/favorites/auctionSeries`, (snapshot) => {
            const favorites: {[auctionSeriesId: string]: FavoriteAuctionSeries} = {};

            if (snapshot.exists()) {
                snapshot.forEach((auctionSeriesSnapshot) => {
                    const auctionSeriesId = auctionSeriesSnapshot.key!;
                    favorites[auctionSeriesId] = {
                        auctionSeriesId,
                        ...auctionSeriesSnapshot.val(),
                    } as FavoriteAuctionSeries;
                });
            }

            listener(favorites);
        });
    }

    subscribeToFavoriteAuctions(uid: string, listener: FavoriteAuctionsListener) {
        return this.firebase.database.subscribeToSnapshot(`/users/${uid}/favorites/auctions`, (snapshot) => {
            const favorites: {[auctionId: string]: FavoriteAuction} = {};

            if (snapshot.exists()) {
                snapshot.forEach((auctionSnapshot) => {
                    const auctionId = auctionSnapshot.key!;
                    favorites[auctionId] = {
                        auctionId,
                        ...auctionSnapshot.val(),
                    } as FavoriteAuction;
                });
            }

            listener(favorites);
        });
    }

    subscribeToFavoriteItems(uid: string, listener: FavoriteItemsListener) {
        return this.firebase.database.subscribeToSnapshot(`/users/${uid}/favorites/items`, (snapshot) => {
            const favorites: {[itemKey: string]: FavoriteItem} = {};

            if (snapshot.exists()) {
                snapshot.forEach((itemSnapshot) => {
                    const itemKey = itemSnapshot.key!;
                    favorites[itemKey] = {
                        itemKey,
                        ...itemSnapshot.val(),
                    } as FavoriteItem;
                });
            }

            listener(favorites);
        });
    }

    async toggleFavoriteRegion(uid: string, favorite: FavoriteRegion): Promise<boolean> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_TOGGLE_FAVORITE_REGION)({uid, ...favorite});
    }

    async toggleFavoriteAuctionSeries(uid: string, favorite: FavoriteAuctionSeries): Promise<boolean> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_TOGGLE_FAVORITE_AUCTION_SERIES)({
            uid,
            ...favorite,
        });
    }

    async toggleFavoriteAuction(uid: string, favorite: FavoriteAuction): Promise<boolean> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_TOGGLE_FAVORITE_AUCTION)({uid, ...favorite});
    }

    async toggleFavoriteItem(uid: string, favorite: FavoriteItem): Promise<boolean> {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_TOGGLE_FAVORITE_ITEM)({uid, ...favorite});
    }
}
