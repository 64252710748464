//////////////////////////////////////////////////  Common types  ////////////////////////////////////////////////////
import {AuctionPauseType} from '../AuctionTypes';
import {BidTypeEnum} from '../Bid';

export const wsResStatuses = {
    SUCCESS: 'success',
    ERROR: 'error',
    SYSTEM_ERROR: 'system_error',
} as const;
export type WSResStatus = typeof wsResStatuses[keyof typeof wsResStatuses];

export const wsResSuccessStatuses = [wsResStatuses.SUCCESS];
export type WSResSuccessStatus = typeof wsResSuccessStatuses[number];

export const wsResFailStatuses = [wsResStatuses.ERROR, wsResStatuses.SYSTEM_ERROR];
export type WSResFailStatus = typeof wsResFailStatuses[number];

export interface WSResFail {
    status: WSResFailStatus;
    reason?: string;
}

export interface WSResSuccess {
    status: WSResSuccessStatus;
}

export function isSuccessful(res: WSResFail | WSResSuccess): res is WSResSuccess {
    return wsResSuccessStatuses.includes((res as WSResSuccess).status);
}

export function isFailed(res: WSResFail | WSResSuccess): res is WSResFail {
    return wsResFailStatuses.includes((res as WSResFail).status);
}

//////////////////////////////////////////////  "bid:placeBid" route  /////////////////////////////////////////////////

export interface WSPlaceBidReq {
    regionId: string;
    auctionId: string;
    itemId: string;
    amount: number;
    paddleNumber: string;
    type: BidTypeEnum;
}

export interface WSPlaceBidResSuccess extends WSResSuccess {
    bidRef: string;
}

export type WSPlaceBidResFail = WSResFail;

export type WSPlaceBidRes = WSPlaceBidResSuccess | WSPlaceBidResFail;

export interface WSPlaceAutoBidReq {
    regionId: string;
    auctionId: string;
    itemId: string;
    amount: number;
    multibid?: boolean;
}

export interface WSPlaceAutoBidResSuccess extends WSResSuccess {
    key: string;
}

export type WSPlaceAutoBidRes = WSPlaceAutoBidResSuccess | WSPlaceBidResFail;

export interface WSExtendBiddingExpirationReq {
    regionId: string;
    auctionId: string;
}

export type WSExtendBiddingExpirationResSuccess = WSResSuccess;
export type WSExtendBiddingExpirationResFail = WSResFail;

export type WSExtendBiddingExpirationRes = WSExtendBiddingExpirationResSuccess | WSExtendBiddingExpirationResFail;

export interface WSUpdateAuctionPauseReq {
    regionId: string;
    auctionId: string;
    pause: AuctionPauseType;
}

export type WSUpdateAuctionPauseResSuccess = WSResSuccess;
export type WSUpdateAuctionPauseResFail = WSResFail;

export type WSUpdateAuctionPauseRes = WSUpdateAuctionPauseResSuccess | WSUpdateAuctionPauseResFail;
