import {io, Socket} from 'socket.io-client';
import {makeSubscriber} from '@joyrideautos/auction-utils/src/subscriptions';
import {FERequestHandler, feRequestHandlers} from '@joyrideautos/auction-core/src/services/FERoutingService';
import type {AuthUserService} from '@joyrideautos/ui-services/src/services/AuthUserService';
import type {SentryService} from '@joyrideautos/ui-services/src/types';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import RoutingService from '@joyrideautos/auction-core/src/services/RoutingService';

const reportConnectionError =
    (sentryService?: SentryService, logger?: Logger) =>
    (errorKind: string, shouldSendToSentry = true) => {
        return (error: any) => {
            logger?.log(`Socket connection error [${errorKind}]`, error);
            if (!shouldSendToSentry) {
                return;
            }
            sentryService?.captureMessage(`socket.io ${errorKind}`, (scope) => {
                scope.setLevel('error');
                scope.setExtra('error', error);
                return scope;
            });
        };
    };

const errorHandler = (sentryService?: SentryService, logger?: Logger) =>
    reportConnectionError(sentryService, logger)('error');
const connectErrorHandler = (sentryService?: SentryService, logger?: Logger) =>
    reportConnectionError(sentryService, logger)('connect_error', false);
const reconnectErrorHandler = (sentryService?: SentryService, logger?: Logger) =>
    reportConnectionError(sentryService, logger)('reconnect_error');
const reconnectFailedHandler = (sentryService?: SentryService, logger?: Logger) =>
    reportConnectionError(sentryService, logger)('reconnect_failed');

export class SocketWrapper {
    private _socket: Socket | null = null;

    constructor(
        private userService: AuthUserService,
        private feRoutingService: RoutingService<FERequestHandler>,
        private sentryService?: SentryService,
        private logger?: Logger
    ) {
        this.reconnect = this.reconnect.bind(this);
        this.connectListener = this.connectListener.bind(this);
    }

    async getIdToken(): Promise<string | undefined> {
        const idToken = this.userService.getIdToken();
        if (!idToken) {
            this.logger?.error('missing idToken');
            this.destroy();
            return;
        }
        return idToken;
    }

    open() {
        return makeSubscriber(`SocketWrapper`, () => {
            this.init();
            return () => {
                this.destroy();
            };
        })();
    }

    get socket(): Socket | null {
        return this._socket;
    }

    private init() {
        const biddingWsUrl = this.feRoutingService.getRequestHandlerUrl(feRequestHandlers.BIDDING_WS);
        if (!biddingWsUrl) {
            return;
        }
        // Note, these options are sent only on a (re-)connection attempt and not updated per each further request
        const options = {
            // The auth() function will fetch a fresh token on each (re-)connection attempt.
            // At the moment we check and decode the auth token only at the "connect" event and don't check when placing bids.
            // If we checked tokens on every event we would need to pass tokens with the data to keep them fresh because
            // headers are also sent only at the moment of (re-)connection attempt
            auth: (cb: any) => this.getIdToken().then((token) => cb({token})),
            transports: ['websocket', 'polling'],
            autoConnect: false,
            reconnectionDelay: 500,
            reconnectionDelayMax: 3000,
            timeout: 5000,
        };
        const socket = io(biddingWsUrl, options);
        this.setSocket(socket);
        socket.connect();
    }

    private destroy() {
        if (this.socket) {
            this.logger?.log('disconnect');
            this.socket.disconnect();
            this.setSocket(null);
        }
    }

    private setSocket(socket: Socket | null) {
        this.logger?.log(`setSocket(${socket ? 'socket' : 'null'})`);

        if (this.socket) {
            this.socket.off('connect', this.connectListener);
            this.socket.off('error', errorHandler(this.sentryService, this.logger));
            this.socket.off('connect_error', connectErrorHandler(this.sentryService, this.logger));
            this.socket.off('reconnect_error', reconnectErrorHandler(this.sentryService, this.logger));
            this.socket.off('reconnect_failed', reconnectFailedHandler(this.sentryService, this.logger));
            this.socket.off('reconnect_failed', this.reconnect);
        }
        this._socket = socket;
        if (this.socket) {
            this.socket.on('connect', this.connectListener);
            this.socket.on('error', errorHandler(this.sentryService));
            // SocketIO wil automatically try to reconnect in case of "connect_error"
            // When connect error happens we need to update the token, but since currently auth() is a function it will
            // grab a fresh token automatically: https://socket.io/docs/v4/client-socket-instance/#connect_error
            this.socket.on('connect_error', connectErrorHandler(this.sentryService));
            // reconnect_error - fired upon a reconnection attempt error.
            this.socket.on('reconnect_error', reconnectErrorHandler(this.sentryService));
            // reconnect_failed - fired when couldn't reconnect within reconnectionAttempts (we use reconnectionAttempts=Infinity (default))
            this.socket.on('reconnect_failed', reconnectFailedHandler(this.sentryService));
            this.socket.on('reconnect_failed', this.reconnect);
        }
    }

    private connectListener() {
        const transport = this.socket?.io?.engine?.transport?.name;
        this.logger?.log(`connected to socket ${this.socket?.id}. Used transport "${transport}"`);
    }

    private reconnect() {
        this.logger?.log('reconnect');
        setTimeout(() => {
            if (this.socket) {
                this.socket.disconnect().connect();
            }
        }, 10);
    }
}

let socketWrapper: SocketWrapper;

export function createSocketWrapper(
    userService: AuthUserService,
    feRoutingService: RoutingService<FERequestHandler>,
    sentryService?: SentryService,
    logger?: Logger
) {
    if (!socketWrapper) {
        socketWrapper = new SocketWrapper(userService, feRoutingService, sentryService, logger);
    }
    return socketWrapper;
}
