export interface PlatformFeeScheduleStep {
    from: number;
    to?: number;
    amountInCents: number;
}

export interface PlatformFeeSchedule {
    editedBy?: string;
    editedAt?: string;
    createdBy?: string;
    createdAt?: string;
    name: string;
    steps: PlatformFeeScheduleStep[];
}

export const PlatformFeeTypes = {
    PERCENTAGE: 'PERCENTAGE',
    SCHEDULE: 'SCHEDULE',
} as const;

export type PlatformFeeType = typeof PlatformFeeTypes[keyof typeof PlatformFeeTypes];

export const platformFeeTypeDescriptions = {
    [PlatformFeeTypes.PERCENTAGE]: 'Percentage',
    [PlatformFeeTypes.SCHEDULE]: 'Platform Fee Schedule',
};

export const DEFAULT_PLATFORM_FEE_TYPE = PlatformFeeTypes.PERCENTAGE;
