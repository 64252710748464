export interface Document {
    name: string;
    document: string;
}

export const ContentTypes = {
    CONTENT_TYPE_MS_WORD: 'CONTENT_TYPE_MS_WORD',
    CONTENT_TYPE_MS_EXCELX: 'CONTENT_TYPE_MS_EXCELX',
    CONTENT_TYPE_MS_EXL: 'CONTENT_TYPE_MS_EXL',
    CONTENT_TYPE_MS_DOCX: 'CONTENT_TYPE_MS_DOCX',
    CONTENT_TYPE_IMAGE_GIF: 'CONTENT_TYPE_IMAGE_GIF',
    CONTENT_TYPE_IMAGE_JPEG: 'CONTENT_TYPE_IMAGE_JPEG',
    CONTENT_TYPE_IMAGE_PNG: 'CONTENT_TYPE_IMAGE_PNG',
    CONTENT_TYPE_APPLICATION_PDF: 'CONTENT_TYPE_APPLICATION_PDF',
    CONTENT_TYPE_CSV: 'CONTENT_TYPE_CSV',
} as const;

export type ContentType = typeof ContentTypes[keyof typeof ContentTypes];

export interface DocumentWithId extends Document {
    id?: number;
    uploadedAt?: string;
    uid?: string;
    contentType?: ContentType;
}

export const documentTypes = {
    INTERNAL_DOCS: 'internal_docs',
    POLICY_DOCS: 'policy_docs',
    PAYMENT_INFO_DOCS: 'payment_info_docs',
} as const;

export type DocumentTypes = typeof documentTypes[keyof typeof documentTypes];
