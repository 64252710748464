import {BaseService} from './BaseService';
import {
    ItemInvoice,
    ItemPayment,
    PaymentStatistics,
    PaymentTypeEnum,
} from '@joyrideautos/auction-core/src/types/Payments';
import {DocumentSnapshot, QuerySnapshot} from '../firebase/Firestore';
import {WithKey} from '../firebase/types';

function toArrayWithKey<T>(snapshots: DocumentSnapshot<T>[] | null): WithKey<T>[] {
    return snapshots ? snapshots.map((snapshot) => ({...(snapshot.data() as any), key: snapshot.id})) : [];
}

export class ItemPaymentService extends BaseService {
    /*
{
      "collectionGroup": "paymentResults",
      "queryScope": "COLLECTION_GROUP",
      "fields": [
        { "fieldPath": "uid", "order": "ASCENDING" },
        { "fieldPath": "timestamp", "order": "DESCENDING" }
      ]
    }
 */
    subscribeToPaymentResults(uid: string, listener: (payments: ItemPayment[]) => void): () => void {
        const paymentResultsQuery = this.firebase.firestore
            .collectionGroupRef<ItemPayment>('paymentResults')
            .where('uid', '==', uid)
            .orderBy('timestamp', 'desc');
        return this.firebase.firestore.subscribeToCollection<ItemPayment>(paymentResultsQuery, (result) =>
            listener(Object.values(result || {}))
        );
    }

    subscribeToInvoiceResults(uid: string, listener: (invoices: ItemInvoice[]) => void): () => void {
        const invoiceResultsQuery = this.firebase.firestore
            .collectionRef<ItemInvoice>('/invoiceResults')
            .where('uid', '==', uid);

        return this.firebase.firestore.subscribeToCollection<ItemInvoice>(invoiceResultsQuery, (result) =>
            listener(Object.values(result || {}))
        );
    }

    /*
    {
          "collectionGroup": "paymentResults",
          "queryScope": "COLLECTION_GROUP",
          "fields": [
            { "fieldPath": "auctionId", "order": "ASCENDING" },
            { "fieldPath": "itemId", "order": "ASCENDING" },
            { "fieldPath": "regionId", "order": "ASCENDING" },
            { "fieldPath": "uid", "order": "ASCENDING" }
          ]
        }
     */
    subscribeToPaymentResult(
        uid: string,
        regionId: string,
        auctionId: string,
        itemId: string,
        listener: (payment: ItemPayment) => void
    ): () => void {
        const onSnapshot = (paymentSnapshot: QuerySnapshot<ItemPayment>) => {
            if (paymentSnapshot.empty) {
                return;
            }
            console.warn('payment results found', paymentSnapshot.size);
            listener({...paymentSnapshot.docs[0].data()});
        };

        const paymentResultsQuery = this.firebase.firestore
            .collectionGroupRef<ItemPayment>('paymentResults')
            .orderBy('timestamp', 'desc')
            .where('uid', '==', uid)
            .where('regionId', '==', regionId)
            .where('auctionId', '==', auctionId)
            .where('itemId', '==', itemId);
        return this.firebase.firestore.subscribeToCollectionSnap<ItemPayment>(paymentResultsQuery, onSnapshot);
    }

    async fetchPaymentResult(uid: string, regionId: string, auctionId: string, itemId: string, type: PaymentTypeEnum) {
        const paymentResultsQuery = this.firebase.firestore
            .collectionGroupRef<ItemPayment>('paymentResults')
            .orderBy('timestamp', 'desc')
            .where('uid', '==', uid)
            .where('regionId', '==', regionId)
            .where('auctionId', '==', auctionId)
            .where('itemId', '==', itemId)
            .where('type', '==', type);

        const snapshots = await this.firebase.firestore.fetchOnceDocuments(paymentResultsQuery);
        return snapshots && snapshots[0] ? snapshots[0].data() : null;
    }

    async fetchPaymentResults(
        uid: string,
        regionId: string,
        auctionId: string,
        itemId: string,
        type?: PaymentTypeEnum | null
    ): Promise<ItemPayment[]> {
        let paymentResultsQuery = this.firebase.firestore
            .collectionGroupRef<ItemPayment>('paymentResults')
            .orderBy('timestamp', 'desc')
            .where('uid', '==', uid)
            .where('regionId', '==', regionId)
            .where('auctionId', '==', auctionId)
            .where('itemId', '==', itemId);
        if (type) {
            paymentResultsQuery = paymentResultsQuery.where('type', '==', type);
        }
        return await this.firebase.firestore.fetchOnceArray(paymentResultsQuery);
    }

    subscribeToPaymentStatistics(subscriber: (statistics: {[uid: string]: PaymentStatistics}) => void) {
        return this.firebase.firestore.subscribeToCollection<PaymentStatistics>(
            this.firebase.firestore.collectionRef<PaymentStatistics>('/paymentStatistics'),
            (statistics) => subscriber(statistics || {})
        );
    }

    subscribeToPaymentStatisticsForUser = (
        uid: string,
        subscriber: (statistics: PaymentStatistics | undefined) => void
    ) => {
        return this.firebase.firestore.subscribeToDocument<PaymentStatistics>(
            this.firebase.firestore.documentRef(`/paymentStatistics/${uid}`),
            subscriber
        );
    };

    async fetchItemsPaymentsByIdsLocal(itemsIds: Array<string | number>): Promise<ItemPayment[]> {
        const findByKey = async (key: string | number): Promise<WithKey<ItemPayment>[]> => {
            const snapshots = await this.firebase.firestore.fetchOnceDocuments<ItemPayment>(
                this.firebase.firestore.collectionRef(`/items/${key}/paymentResults`)
            );
            return toArrayWithKey<ItemPayment>(snapshots);
        };
        const results = await Promise.all(itemsIds.map((itemId) => findByKey(itemId)));
        return results.reduce((a, i) => [...a, ...i], []);
    }
}
