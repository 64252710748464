import * as s from 'superstruct';
import {WinningBid} from './WinningBid';
import {ItemStatusEnum, PersistedItemStatusEnum} from './ItemTypes';
import {FeePrice} from './FeePrice';
import {Timestamp, WithKey} from './common';
import {lodashGroupBy} from '@joyrideautos/auction-utils/src/arrayUtils';
import {toUpper} from '@joyrideautos/auction-utils/src/stringUtils';
import {toNumber} from '@joyrideautos/auction-utils/src/numberUtil';
import {ItemMediaStatus} from './ItemMedia';
import {ItemInfoValidation} from './validations/itemValidation';
import {AuctionOccurrence} from './AuctionOccurrence';

export const DEFAULT_REQUIRED_NUMBER_OF_PHOTOS_FOR_CANDIDATE = 4;
export const imageSizes = {
    ORIGINAL: 'original',
    THUMB_200: 'thumb_200',
    FULL_4X3: 'full_4x3',
    THUMBNAIL_4X3: 'thumbnail_4x3',
} as const;

export const oldImageSizes = {
    ORIGINAL: 'original',
    THUMB_200: 'thumb_200',
} as const;

export type ImageSize = typeof imageSizes[keyof typeof imageSizes];
export const videoSizes = {VIDEO: 'video', VIDEO_4X3: 'video_4x3'} as const;
export type VideoSize = typeof videoSizes[keyof typeof videoSizes];

export function isVideo(imageOrVideoSize: string) {
    return Object.values(videoSizes).includes(imageOrVideoSize as VideoSize);
}

export function isImage(imageOrVideoSize: string) {
    return Object.values(imageSizes).includes(imageOrVideoSize as ImageSize);
}

export const UNKNOWN_ITEM_VALUE = 'unknown';
export const UNKNOWN_YEAR_VALUE = 0;

export function toStoredYearValue(year: string | number | null | undefined) {
    return year ?? UNKNOWN_YEAR_VALUE;
}

export function isUnknownYearValue(year: number | string | null | undefined) {
    return (toNumber(year) ?? UNKNOWN_YEAR_VALUE) === UNKNOWN_YEAR_VALUE;
}

export function toFormattedYearValue(year: number | null | undefined) {
    return toUpper(year === UNKNOWN_YEAR_VALUE ? UNKNOWN_ITEM_VALUE : year, UNKNOWN_ITEM_VALUE);
}

export type ItemPath = {
    regionId: string;
    auctionId: string;
    itemId: string;
};

export function itemPathComparator(a: ItemPath, b: ItemPath) {
    return a.regionId === b.regionId && a.auctionId === b.auctionId && a.itemId === b.itemId;
}

export function isItemPath(input: any): input is ItemPath {
    return input.regionId !== undefined && input.auctionId !== undefined && input.itemId !== undefined;
}

export type ItemInfo = s.Infer<typeof ItemInfoValidation>;

export type ItemImages = {
    [imageSize in ImageSize]: {[key: string]: ItemImage};
};

export type ItemImageIndexType = string | number;

export type ItemImage = {
    bucket: string;
    contentType: string;
    height: number;
    name: string;
    url: string;
    width: number;
    idx: ItemImageIndexType | null;
    metadata: {
        sourceIdx: string;
        sourceURL?: string;
        sourceGdriveFileId?: string;
    };
    status?: ItemMediaStatus;
    uploadedAt?: number;
    placeholderType: string;
};

export interface ItemImageInfo {
    image?: ItemImages;
    imagesCount?: number;
}

export enum ArchiveReasonEnum {
    RELEASED = 'released',
    RELISTED = 'relisted',
    SOLD_OFFLINE = 'sold offline',
    ABSENT_IN_DATA_FEED = 'absent_in_data_feed', // = absent in the data feed from original provider (already sold in Copart)
}

export const AuctionItemImportSources = {
    AUTO_74: 'auto74',
    COPART: 'copart',
    G_DRIVE: 'G_DRIVE',
    ARIES: 'ARIES',
    REST_API: 'REST_API',
    WEB_UI_VIN_LIST: 'WEB_UI_VIN_LIST',
    WEB_UI_FILE_UPLOAD: 'WEB_UI_FILE_UPLOAD',
    WEB_UI_FILE_IMPORT: 'WEB_UI_FILE_IMPORT_TO_AUCTION',
} as const;

export type AuctionItemImportSource = typeof AuctionItemImportSources[keyof typeof AuctionItemImportSources];

export const ImpoundFeeCategories = {
    STORAGE: 'STORAGE',
    TOW: 'TOW',
    LIEN: 'LIEN',
} as const;

export type ImpoundFeeCategory = typeof ImpoundFeeCategories[keyof typeof ImpoundFeeCategories];

export type ImpoundFeeItem = {
    feeDate: string;
    feeAmountInCents: number;
    taxAmountInCents?: number;
};

export type ImpoundFees = Partial<Record<ImpoundFeeCategory, ImpoundFeeItem[]>>;

export interface ItemCustomField {
    key: string;
    value: string;
}

export interface BaseItem extends ItemImageInfo {
    itemId: string;
    sellerId: string;
    info: ItemInfo;
    feePrice?: FeePrice | null;
    reservePrice?: number | null;
    startingBid?: number | null;
    buyNowAmount?: number | null;
    locationId: string;
    transId?: string;
    archiveReason?: ArchiveReasonEnum;
    relisted?: boolean;
    isAcceptingOffers?: boolean;
    importedFrom?: AuctionItemImportSource;
    importGroupId?: string;
    impoundFees?: ImpoundFees;
    customFields?: Record<string, ItemCustomField>;
}

export interface ItemStatusTimestamp<T = Timestamp> {
    publishedAt?: T;
    storedAt?: T;
    candidateAt?: T;
    soldAt?: T;
    unsoldAt?: T;
    paidAt?: T;
    claimedAt?: T;
    archivedAt?: T;
    deletedAt?: T;
}

export interface ItemMarkedAsStatusBy<T = string> {
    markedAsUnsoldBy?: T;
    markedAsArchivedBy?: T;
    markedAsClaimedBy?: T;
    markedAsPublishedBy?: T;
}

export type ItemStatusTimestampFieldsType = keyof Required<ItemStatusTimestamp>;

export const SoldStatusMetas = {
    AWATING_DEPOSIT: 'awating deposit',
    DEPOSIT_FAILED: 'failed deposit',
} as const;

export type SoldStatusMeta = typeof SoldStatusMetas[keyof typeof SoldStatusMetas];

export interface PersistedStatusMetadata {
    reason: ArchiveReasonEnum | SoldStatusMeta | string;
}

export interface BuyerInfo {
    firstName: string;
    lastName: string;
    companyName?: string;
    email: string;
    streetAddress: string;
    apartmentOrSuite?: string;
    city?: string;
    state?: string;
    zip?: string;
    phone: string;
}

export interface BuyerInfoWithUid extends BuyerInfo {
    uid?: string;
}

export function isBuyerInfo(data: any): data is BuyerInfo {
    return data.firstName && data.lastName && data.email && data.streetAddress && data.phone;
}

export interface PersistedItem extends BaseItem, ItemStatusTimestamp, ItemMarkedAsStatusBy {
    idx: number;
    regionId?: string;
    auctionId?: string;
    // result is synced to PersistedItem only after auction is ended
    result?: WinningBid;
    // WARNING! currentResult is updated in triggers and may be outdated; don't use when consistency is required and
    // fetch the data from RTDB instead
    currentResult?: WinningBid;
    status: PersistedItemStatusEnum;
    statusMeta?: PersistedStatusMetadata;
    sellerVehicleId?: string;
    strictAuctionDate?: boolean;
    auctionSeries?: string;
    requestedAuctionDate?: Timestamp;
    publishAtDate?: Timestamp;
    sellerNotes?: string;
    totalBids?: number;
    buyerInfo?: BuyerInfo;
    reserveUpdatedBy?: string;
    reserveUpdatedAt?: Timestamp;
    startingBidUpdatedBy?: string;
    startingBidUpdatedAt?: Timestamp;
    forbiddenAuctionsIds?: string[];
    claimSessionKey?: string;
}

export interface Item extends BaseItem {
    idx: number;
    prev?: string;
    next?: string;
    persistenceKey?: string;
    publishedAt?: string;
    status: ItemStatusEnum;
    result?: WinningBid;
}

export const groupItemsBy = (fieldName: keyof WithKey<PersistedItem>) => (items: WithKey<PersistedItem>[]) => {
    return lodashGroupBy(items, (item: {[x: string]: any}) => item[fieldName]);
};

export function getItemBuyerId(item: PersistedItem): string | undefined {
    return item?.result?.billingUid || item?.result?.uid || undefined;
}

export function getMinimumBidAmount(auction?: AuctionOccurrence, item?: BaseItem): number {
    return item?.startingBid || auction?.settings.minimumBid || 0;
}
