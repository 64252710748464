import {flow, getParent, getRoot, Instance, isAlive, types, getSnapshot, IMSTMap} from 'mobx-state-tree';
import {Bid, BidType, createPendingBid, SnapshotInBidType, mapFromBidDto} from '../types/Bid';
import {LoadingStatus} from '../utils/LoadingStatus';
import {PersistedItemType, toPersistedItemDto} from '../types/item/PersistedItem';
import {compareDatesAsString} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {AuthUserType} from '../types/UserInfo';
import {
    HANDLE_AUTOBID_SUBMIT,
    HANDLE_BID_SUBMIT,
    tracingAttributeNames,
} from '@joyrideautos/ui-services/src/services/FirebaseTraceService';
import {makeSubscriber, makeSubscribers} from '@joyrideautos/auction-utils/src/subscriptions';
import AutoBid, {AutoBidType, mapFromAutobidDto} from '../types/AutoBid';
import type {SnapshotInAutoBidType} from '../types/AutoBid';
import {
    acceptedOffersBySellerUserFilter,
    activeOffersFilter,
    activeOffersForUserFilter,
    bidsFilter,
    BidTypeEnum,
    offersFilter,
} from '@joyrideautos/auction-core/src/types/Bid';
import {ItemPath} from '@joyrideautos/auction-core/src/types/Item';
import {UserInfo} from '@joyrideautos/auction-core/src/types/User';
import BaseStore from './BaseStore';
import {mergeMaps} from '@joyrideautos/auction-utils/src/objectUtils';
import primitives from '../Primitives';
import {logError} from '@joyrideautos/ui-logger/src/utils';

const LIVE_BIDS_COUNT_LIMIT = 10;

function filterBidsOrOffers<T>(bidsOrOffers: IMSTMap<typeof Bid>, filter: (bidOrOffer: BidType) => boolean) {
    const filtered = new Map<string, SnapshotInBidType>();
    for (const [key, bidOrOffer] of bidsOrOffers) {
        if (filter(bidOrOffer)) {
            filtered.set(key, getSnapshot(bidOrOffer));
        }
    }
    return filtered;
}

export const BidsContainer = BaseStore.named('BidsContainer')
    .props({
        regionId: types.maybe(types.string),
        auctionId: types.maybe(types.string),
        bids: types.map(Bid),
        allBidsCount: primitives.number(),
        autobids: types.map(AutoBid),
    })
    .volatile(() => ({
        item: null as ItemPath | null,
        bidsLoadingStatus: new LoadingStatus(),
        offersLoadingStatus: new LoadingStatus(),
        autoBidStatus: new LoadingStatus(),
        makingNewBidStatus: new LoadingStatus(),
        makingNewAutoBidStatus: new LoadingStatus(),
    }))
    .actions((self) => ({
        addBid(bid: BidType) {
            self.bids.put(bid);
        },
    }))
    .actions((self) => ({
        loadBid: flow(function* (bidId: string): Generator<Promise<any>, any, any> {
            const item = self.item;
            if (item) {
                const bid = yield self.bidService.fetchBid({...item, bidId});
                if (!isAlive(self) || !bid) {
                    return;
                }
                self.addBid({
                    ...bid,
                    item: item.itemId,
                    regionId: item.regionId,
                    auctionId: item.auctionId,
                });
            }
        }),
    }))
    .views((self) => ({
        get userInfo(): AuthUserType | undefined {
            return getRoot<any>(self).authUserStore.userInfo;
        },
        get sortedBidsByTimestamp() {
            /*
            scenario1:
                user_a has the autobid $100
                user_b puts the bid $100
                autobid wins

                1. the bid of the user_b is created (timestamp1)
                2. the system creates the bid of the user_a (from the autobid) (timestamp2)
                3. the system rejects the user_b bid.

                bids history:
                - bid_user_b ($100)
                - bid_user_a ($100 from autobid)

            scenario2:
                user_a puts the bid $100
                manager rejects the bid $100
                user_a creates the autobid $100 -> the system creates a new bid

                bids history:
                - autobid_bid ($100)
                - rejected_bid ($100)
             */
            return [...self.bids.values()].filter(bidsFilter).sort((a, b) => {
                const bidsFromSameUser = a.bidInfo?.userId === b.bidInfo?.userId;
                if (!bidsFromSameUser && a.amount === b.amount) {
                    return a.autobidId || b.autobidId ? 1 : -1;
                }
                return compareDatesAsString(a.bidInfo?.timestamp, b.bidInfo?.timestamp, true);
            });
        },

        get lastUserBid(): BidType | undefined {
            return this.sortedBidsByTimestamp.find((bid: BidType) => {
                return bid.bidInfo && bid.bidInfo.userId === (this.userInfo && this.userInfo.uid);
            });
        },

        get lastBid(): BidType | undefined {
            return this.sortedBidsByTimestamp[0];
        },

        get isAuthUserHasBids() {
            return [...self.bids.values()]
                .filter(bidsFilter)
                .some(({bidInfo}) => this.userInfo?.uid === bidInfo?.userId);
        },

        getBid(bidKey: string) {
            const bid = self.bids.get(bidKey);
            if (!bid) {
                setTimeout(() => self.loadBid(bidKey), 0);
            }
            return bid;
        },
        get isLoading() {
            return (
                self.bidsLoadingStatus.isInProgress ||
                self.offersLoadingStatus.isInProgress ||
                self.autoBidStatus.isInProgress
            );
        },
        findAutoBidsForItem(itemId: string | number, uid?: string) {
            return Array.from(self.autobids.values())
                .filter((bid) => !bid.cancelledAt)
                .filter((bid) => {
                    let userMatch = true;
                    if (uid) {
                        userMatch = bid.bidInfo?.userId === uid;
                    }
                    return userMatch && bid.item?.itemId === itemId;
                });
        },
        getAutobid(bidId?: string): AutoBidType | undefined {
            if (!bidId) {
                return undefined;
            }
            const autobid = self.autobids.get(bidId);
            return !autobid?.cancelledAt ? autobid : undefined;
        },
        get allOffers(): BidType[] {
            return [...self.bids.values()].filter(offersFilter);
        },
        get activeOffers(): BidType[] {
            return this.allOffers.filter(activeOffersFilter);
        },
        getAcceptedOfferBySeller(uid: string) {
            return this.allOffers.find(acceptedOffersBySellerUserFilter(uid));
        },
        findActiveOffersForBuyer(uid: string): BidType[] {
            return this.allOffers.filter(activeOffersForUserFilter(uid));
        },
        findActiveOfferForUser(): BidType | undefined {
            if (!this.userInfo) {
                return undefined;
            }
            const activeOffers = this.findActiveOffersForBuyer(this.userInfo.uid);
            return activeOffers[activeOffers.length - 1];
        },
    }))
    .actions((self) => {
        return {
            setItem(item: ItemPath) {
                self.item = item;
            },
            setBids(bids: Map<string, SnapshotInBidType>) {
                const userInfo: UserInfo = self.userInfo!;
                if (bids.size) {
                    self.bids.replace(mergeMaps(filterBidsOrOffers(self.bids, offersFilter), bids));
                } else {
                    self.bids.replace(filterBidsOrOffers(self.bids, offersFilter));
                }
                if (self.makingNewBidStatus.isInProgress) {
                    for (const [, bid] of self.bids) {
                        if (bid.isPending && bid.uid === userInfo.uid) {
                            self.logger.log('bid', bid.key, bid.amount, bid.uid, bid.isPending);
                            return;
                        }
                    }
                    // It's needed for measuring time of bids placed using DB trigger since we just push the bid to DB
                    // and wait here until the bid is processed by DB trigger
                    self.firebaseTraceService.stopTrace(HANDLE_BID_SUBMIT);
                    self.makingNewBidStatus.setReady();
                }
            },
            setOffers(offers: Map<string, SnapshotInBidType>) {
                const userInfo: UserInfo = self.userInfo!;
                if (offers.size) {
                    self.bids.replace(mergeMaps(filterBidsOrOffers(self.bids, bidsFilter), offers));
                    if (self.makingNewBidStatus.isInProgress) {
                        for (const [, bid] of self.bids) {
                            if (bid.isPending && bid.uid === userInfo.uid) {
                                self.logger.log('bid', bid.key, bid.amount, bid.uid, bid.isPending);
                                return;
                            }
                        }
                        self.makingNewBidStatus.setReady();
                    }
                } else {
                    self.bids.replace(filterBidsOrOffers(self.bids, bidsFilter));
                }
            },
            setAutoBids(autobids: Map<string, SnapshotInAutoBidType>) {
                if (autobids.size) {
                    self.autobids.replace(autobids);
                } else {
                    self.autobids.clear();
                }
            },
        };
    })
    .actions((self) => {
        return {
            addPendingBid(amount: number) {
                const {uid}: UserInfo = self.userInfo!;
                const pendingBid = createPendingBid(amount, uid, self.item!);
                self.bids.put(pendingBid);
                return pendingBid;
            },
            clearPendingBid(pendingBid: {key: string}) {
                self.bids.delete(pendingBid.key);
            },
        };
    })
    .actions((self) => {
        const bidsSubscribe = (itemPath: ItemPath, persistenceKey: string, isManager: boolean) => () => {
            if (self.bidsLoadingStatus.isNew) {
                self.bidsLoadingStatus.setInProgress();
            }
            // this subscription is used for live bids shown in the bids history
            // the number of live bids limited by LIVE_BIDS_COUNT_LIMIT
            const unsubscribeRTDB = self.bidService.subscribeToBids(
                itemPath,
                (bids) => {
                    if (!isAlive(self)) {
                        return;
                    }
                    self.setBids(mapFromBidDto(bids, isManager));
                    self.bidsLoadingStatus.setReady();
                },
                {isManager, limit: LIVE_BIDS_COUNT_LIMIT}
            );

            // this subscription is used to fetch all bids counts
            // notified every time when the new bid was added.
            // we need this extra subscription because sync bids to Firestore take some time
            // and we can't rely on predefined delay
            const unsubscribeFirestore = self.bidService.subscribeToInventoryBids(
                persistenceKey,
                () => {
                    if (!isAlive(self)) {
                        return;
                    }
                    self.bidService
                        .getAllBidsCount(itemPath)
                        .then(self.allBidsCount.setValue)
                        .catch(logError('get all bids count'));
                },
                {
                    limit: 1,
                }
            );

            return () => {
                unsubscribeRTDB();
                unsubscribeFirestore();
            };
        };

        const offersSubscribe = (itemPath: ItemPath, isManager: boolean, uid?: string) => () => {
            if (self.offersLoadingStatus.isNew) {
                self.offersLoadingStatus.setInProgress();
            }
            return self.bidService.subscribeToOffers(itemPath, isManager, uid, (bids) => {
                if (!isAlive(self)) {
                    return;
                }
                self.setOffers(mapFromBidDto(bids, isManager));
                self.offersLoadingStatus.setReady();
            });
        };

        const autobidsSubscribe = (itemPath: ItemPath) => () => {
            self.autoBidStatus.setInProgress();
            return self.bidService.subscribeToAutoBid(itemPath, (autobids) => {
                if (!isAlive(self)) {
                    return;
                }
                self.setAutoBids(mapFromAutobidDto(autobids));
                self.autoBidStatus.setReady();
            });
        };
        return {
            loadPersistedBids: flow(function* (item: PersistedItemType) {
                self.bidsLoadingStatus.setInProgress();
                try {
                    const bids = yield self.bidService.fetchPersistedBidsMap(toPersistedItemDto(item));
                    if (!isAlive(self)) {
                        return;
                    }
                    self.setBids(bids);
                    self.bidsLoadingStatus.setReady();
                } catch (e: any) {
                    self.logger.log(e.message);
                    self.bidsLoadingStatus.setError(e.message);
                }
            }),
            loadBids: flow(function* (itemPath: ItemPath, isManager: boolean) {
                if (self.bidsLoadingStatus.isReady) {
                    return;
                }
                self.bidsLoadingStatus.setInProgress();
                self.setItem(itemPath);
                try {
                    const bids = yield self.bidService.fetchBidsMap(itemPath, isManager);
                    if (!isAlive(self)) {
                        return;
                    }
                    self.setBids(bids);
                    self.bidsLoadingStatus.setReady();
                } catch (e: any) {
                    self.logger.log(e.message);
                    self.bidsLoadingStatus.setError(e.message);
                }
            }),
            subscribe(
                itemPath: ItemPath,
                persistenceKey: string,
                options?: {isManager: boolean; bidType: BidTypeEnum; uid?: string}
            ) {
                const isManager = options?.isManager ?? false;
                const bidType = options?.bidType;
                const uid = options?.uid;
                self.setItem(itemPath);
                if (bidType === BidTypeEnum.OFFER) {
                    return makeSubscriber(
                        `offers-BidsContainer-${itemPath.regionId}-${itemPath.auctionId}-${itemPath.itemId}-${
                            uid ?? 'uid'
                        }-${isManager}`,
                        offersSubscribe(itemPath, isManager, uid)
                    )();
                } else if (bidType === BidTypeEnum.AUCTION) {
                    return makeSubscribers(
                        `BidsContainer-${itemPath.regionId}-${itemPath.auctionId}-${itemPath.itemId}-${
                            uid ?? 'uid'
                        }-${isManager}`,
                        {
                            bids: bidsSubscribe(itemPath, persistenceKey, isManager),
                            autobid: autobidsSubscribe(itemPath),
                        }
                    )();
                }
                return () => {};
            },
            makeNewBid: flow(function* (amount: number, paddleNumber?: string, isOffer?: boolean) {
                if (!self.regionId || !self.auctionId) {
                    return;
                }

                self.makingNewBidStatus.setInProgress();
                // this pending bid will be removed after receiving new bids; at the same time the status updates
                const pendingBid = self.addPendingBid(amount);
                const userInfo: UserInfo = self.userInfo!;

                if (isOffer) {
                    try {
                        yield self.bidService.addOffer(
                            userInfo.uid,
                            self.regionId,
                            self.auctionId,
                            self.item!.itemId,
                            amount,
                            paddleNumber
                        );
                    } catch (e) {
                        const sentryEventId = self.sentryService.captureMessage('Error submitting offer', (scope) => {
                            scope.setExtra('response', e);
                            scope.setExtra('pendingBid', pendingBid);
                            scope.setLevel('error');
                            return scope;
                        });
                        self.makingNewBidStatus.setReady();
                        self.clearPendingBid(pendingBid);
                        // For successful case we will stop in the bid subscription since here we just push a pending
                        // bid info to DB and wait for it to be processed by DB trigger
                        self.firebaseTraceService.stopTrace(HANDLE_BID_SUBMIT);
                        self.logger.log(`Error submitting offer. Sentry event id = '${sentryEventId}'`, e);
                    }
                } else {
                    const socket = self.socketWrapper.socket;
                    self.firebaseTraceService.startTrace(HANDLE_BID_SUBMIT, {
                        [tracingAttributeNames.BID_SUBMIT_BE_HANDLER]: socket?.connected
                            ? 'WEB_SOCKETS'
                            : 'CLOUD_FUNCTIONS',
                    });
                    self.logger.log(`socket.id = ${socket?.id}, socket.connected = ${socket?.connected}`);

                    try {
                        if (socket?.connected) {
                            self.logger.log('add bid using socket');
                            yield self.bidService.addBidSocketIO(
                                socket,
                                userInfo.uid,
                                self.regionId,
                                self.auctionId,
                                self.item!.itemId,
                                amount,
                                paddleNumber
                            );
                        } else {
                            self.logger.log('add bid writing in db');
                            // Here we just push the pending bid to DB, it's not processed by BE trigger yet
                            yield self.bidService.addBid(
                                userInfo.uid,
                                self.regionId,
                                self.auctionId,
                                self.item!.itemId,
                                amount,
                                paddleNumber
                            );
                        }
                    } catch (e) {
                        const sentryEventId = self.sentryService.captureMessage('Error submitting bid', (scope) => {
                            scope.setExtra('response', e);
                            scope.setExtra('pendingBid', pendingBid);
                            scope.setLevel('error');
                            return scope;
                        });
                        self.makingNewBidStatus.setReady();
                        self.clearPendingBid(pendingBid);
                        // For successful case we will stop in the bid subscription
                        // (for consistency between socket and trigger approaches)
                        self.firebaseTraceService.stopTrace(HANDLE_BID_SUBMIT);
                        self.logger.log(`Error submitting bid. Sentry event id = '${sentryEventId}'`, e);
                    }
                }
            }),
            submitNewAutobid: flow(function* (amount: number, multibid = false): Generator<Promise<any>, any, any> {
                if (!self.regionId || !self.auctionId) {
                    return;
                }
                try {
                    const socket = self.socketWrapper.socket;
                    self.firebaseTraceService.startTrace(HANDLE_AUTOBID_SUBMIT, {
                        [tracingAttributeNames.AUTOBID_SUBMIT_BE_HANDLER]: socket?.connected
                            ? 'WEB_SOCKETS'
                            : 'CLOUD_FUNCTIONS',
                    });
                    self.logger.log(`socket.id = ${socket?.id}, socket.connected = ${socket?.connected}`);
                    if (socket?.connected) {
                        self.logger.log('create autobid using socket');
                        self.makingNewAutoBidStatus.setInProgress();
                        yield self.bidService.createNewAutoBidSocketIO(socket, self.item!, amount, multibid);
                        self.makingNewAutoBidStatus.setReady();
                    } else {
                        self.logger.log('create autobid using db');
                        self.makingNewAutoBidStatus.setInProgress();
                        yield self.bidService.createNewAutoBid(self.userInfo!.uid, self.item!, amount, multibid);
                        self.makingNewAutoBidStatus.setReady();
                    }
                } catch (e) {
                    const sentryEventId = self.sentryService.captureMessage('Error submitting autobid', (scope) => {
                        scope.setExtra('response', e);
                        scope.setExtra('item', self.item);
                        scope.setExtra('amount', amount);
                        scope.setExtra('multibid', multibid);
                        scope.setLevel('error');
                        return scope;
                    });
                    self.logger.log(`Error submitting autobid. Sentry event id = '${sentryEventId}'`, e);
                    self.makingNewAutoBidStatus.setReady();
                    // It will be further processed to show a proper UI notification depending on single/bulk autobid
                    throw e;
                } finally {
                    self.firebaseTraceService.stopTrace(HANDLE_AUTOBID_SUBMIT);
                }
            }),
            cancelAutobid: flow(function* (): Generator<Promise<any>, any, any> {
                if (!self.regionId || !self.auctionId) {
                    return;
                }
                yield self.bidService.cancelAutoBid(self.regionId, self.auctionId, self.item!.itemId);
            }),
            revertBid: flow(function* (bidKey: string): Generator<Promise<any>, any, any> {
                const {regionId, auctionId, itemId} = self.item!;
                const bidsStore = getParent(self, 2) as any;
                bidsStore.addRevertInProgress(bidKey);
                yield self.bidService.revertBid(regionId, auctionId, itemId, bidKey);
                bidsStore.removeRevertInProgress(bidKey);
            }),
        };
    });

export interface BidsContainerType extends Instance<typeof BidsContainer> {}
