import {isLocalHost} from '@joyrideautos/auction-utils/src/common';
import RoutingService from './RoutingService';

export const feReqRoutes = {
    AWS_WEBHOOK_UPLOAD_MEDIA: 'aws-upload-media-callback',
    AWS_GET_OPEN_ID_TOKEN_FOR_DEVELOPER_IDENTITY: 'aws-getOpenIdTokenForDeveloperIdentity',

    API_SELLERS_CREATE: 'seller-createSeller',
    API_SELLERS_REGIONS_UPDATE: 'seller-updateSellerRegions',
    API_SELLERS_GOOGLE_DRIVE_FOLDER_ID_UPDATE: 'seller-updateSellerGoogleFolderId',
    API_SELLERS_ADDRESS_UPDATE: 'seller-updateSellerAddress',
    API_SELLERS_ACCEPTS_PAY_IN_FULL_UPDATE: 'seller-updateSellerAcceptsFullVehiclePayment',
    API_SELLERS_PICKUP_MESSAGE_UPDATE: 'seller-updateSellerPickupMessage',
    API_SELLERS_LOCATION_UPDATE: 'seller-updateSellerLocation',
    API_SELLERS_DOCUMENTS_CREATE: 'seller-addDocumentToSeller',
    API_SELLERS_DOCUMENTS_DELETE: 'seller-deleteDocumentToSeller',
    API_SELLERS_FEE_UPDATE: 'seller-saveSellerFee',
    API_SELLERS_APPLICATION_PDF_GET: 'seller-getSellerApplicationPDF',
    API_SELLERS_APPLICATION_PDF_GENERATE: 'seller-generateSellerApplicationPDF',
    API_SELLERS_APPLICATION_APPROVE: 'seller-approveSellerApplication',
    API_SELLERS_APPLICATION_DECLINE: 'seller-declineSellerApplication',
    API_SELLERS_APPLICATION_UPDATE: 'seller-updateSellerApplication',
    API_SELLERS_APPLICATION_CONFIRM: 'seller-confirmSellerApplication',
    API_SELLERS_APPLICATION_PROCESS_CANCEL: 'seller-cancelSellerApplicationProcess',
    API_SELLERS_APPLICATION_STATUS_GET: 'seller-getSellerApplicationCurrentStatus',
    API_SELLERS_APPLICATION_GO_BACK: 'seller-goBackToSellerApplicationForm',
    API_SELLERS_INFO_UPDATE: 'seller-updateSellerInfo',
    API_SELLERS_CREATE_CUSTOM_FIELD: 'seller-createSellerCustomField',
    API_SELLERS_UPDATE_CUSTOM_FIELD: 'seller-updateSellerCustomField',
    API_SELLERS_DELETE_CUSTOM_FIELD: 'seller-deleteSellerCustomField',
    API_SELLERS_ITEMS_BY_STATUS: 'seller-sellerItemsByStatus',
    API_SELLERS_SELLER_RELEASE_FLOW_VALIDATE_GATE_CODE: 'seller-validateGateCode',
    API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_PAYMENT_INFO: 'seller-getClaimSessionPaymentInfo',
    API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION: 'seller-getClaimSession',
    API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_BUYER_INFO: 'seller-getClaimSessionBuyerInfo',
    API_SELLERS_SELLER_RELEASE_FLOW_UPDATE_CLAIM_SESSION: 'seller-updateClaimSession',
    API_SELLERS_SELLER_RELEASE_FLOW_CONFIRM_CLAIM_SESSION: 'seller-confirmClaimSession',

    API_SEARCH_VEHICLES_BY_TEXT: 'searchEngine-searchVehiclesByText',
    API_SEARCH_INVENTORY_VEHICLES: 'searchEngine-searchInventoryVehicles',
    API_SEARCH_GET_VEHICLES_SUGGESTIONS: 'searchEngine-getVehiclesSearchSuggestions',
    API_SEARCH_GET_PUBLISHED_VEHICLES_FOR_FILTERS: 'searchEngine-getPublishedVehiclesForFilters',

    API_AUCTIONS_GET_AUCTION_SERIES: 'auction-getAuctionSeries',
    API_AUCTIONS_UPDATE_AUCTION_PAUSE: 'auction-updateAuctionPause',
    API_AUCTIONS_END_AUCTION_VEHICLE: 'auction-endAuctionVehicle',
    API_AUCTIONS_SKIP_AUCTION_VEHICLE: 'auction-skipAuctionVehicle',
    API_AUCTIONS_CHECK_NEXT_ITEM_UPDATED: 'auction-checkNextItemUpdated',
    API_AUCTIONS_UPDATE_AUCTION_EXPIRATION: 'auction-updateAuctionExpiration',
    API_AUCTIONS_UPDATE_AUCTION_EVENT_START: 'auction-updateAuctionEventStart',
    API_AUCTIONS_UPDATE_AUCTION_START: 'auction-updateAuctionStart',
    API_AUCTIONS_UPDATE_AUCTION_SMS_NOTIFICATIONS: 'auction-updateAuctionSmsNotifications',
    API_AUCTIONS_UPDATE_AUCTION_SMS_LEADTIME: 'auction-updateAuctionSmsLeadTime',
    API_AUCTIONS_UPDATE_AUCTION_SERIES: 'auction-updateAuctionSeries',
    API_AUCTIONS_UPDATE_AUCTION_OCCURRENCE: 'auction-updateAuctionOccurrence',
    API_AUCTIONS_RESCHEDULE_AUCTION_OCCURRENCE: 'auction-rescheduleAuctionOccurrence',
    API_AUCTIONS_EXTEND_BIDDING_EXPIRATION: 'auction-extendBiddingExpiration',
    API_AUCTIONS_ADD_SELLER_TO_AUCTION_SERIES: 'auction-addSellerToAuctionSeries',
    API_AUCTIONS_REMOVE_SELLER_FROM_AUCTION_SERIES: 'auction-removeSellerFromAuctionSeries',
    API_AUCTIONS_CLEAN_AUCTIONS: 'auction-cleanAuctions',
    API_AUCTIONS_ENABLE_PLACING_BIDS: 'auction-enablePlacingBids',
    API_AUCTIONS_GET_AUCTION_SCHEDULE: 'auction-getAuctionSchedule',
    API_AUCTIONS_SAVE_AUCTION_SCHEDULE: 'auction-saveAuctionSchedule',
    API_AUCTIONS_DELETE_AUCTION_SCHEDULE: 'auction-deleteAuctionSchedule',
    API_AUCTIONS_DELETE_AUCTION: 'auction-deleteAuction',
    API_AUCTIONS_DELETE_ALL_AUCTIONS_DATA: 'auction-deleteAllAuctionsData',
    API_AUCTIONS_VALIDATE_CONFIGURATION: 'auction-validateActiveAuctionsConfiguration',
    API_AUCTIONS_SAVE_BID_INCREMENT: 'auction-saveBidIncrement',
    API_AUCTIONS_CREATE_OR_UPDATE_PLATFORM_FEE_SCHEDULE: 'auction-createOrUpdatePlatformFeeSchedule',

    API_ITEMS_FIX_ITEMS_EXPIRATION_IN_AUCTION: 'item-fixItemsExpirationInAuction',
    API_ITEMS_FETCH_VEHICLE_CSV_DATA: 'item-fetchVehicleCSVData',
    API_ITEMS_FETCH_AUCTION_CSV_DATA: 'item-fetchAuctionCSVData',
    API_ITEMS_FETCH_VEHICLE_EXCEL_DATA: 'item-fetchVehicleExcelData',
    API_ITEMS_FETCH_VEHICLES_STATUSES_STATISTIC: 'item-fetchVehiclesStatusesStatistic',
    API_ITEMS_FETCH_UPCOMING_AUCTIONS_DATA: 'item-fetchUpcomingAuctionsData',
    API_ITEMS_UPLOAD_ITEMS_CSV: 'item-uploadItemsCsv',
    API_ITEMS_UPDATE_VEHICLES_IN_FIREBASE: 'item-updateVehiclesInFirebase',
    API_ITEMS_UPDATE_VEHICLE_STATUS_IN_FIREBASE: 'item-updateVehicleStatusInFirebase',
    API_ITEMS_UPDATE_VEHICLE_STATUS: 'item-updateVehicleStatus',
    API_ITEMS_UPDATE_VEHICLES_STATUS: 'item-updateVehiclesStatus',
    API_ITEMS_CLONE_VEHICLE: 'item-cloneVehicle',
    API_ITEMS_GET_ITEMS_FOR_SELLER: 'item-getItemsForSeller',
    API_ITEMS_GET_ITEMS_FOR_BUYER: 'item-getItemsForBuyer',
    API_ITEMS_GET_INVENTORY_ITEMS_FOR_AUCTION: 'item-getInventoryItemsForAuction',
    API_ITEMS_GET_WON_ITEMS_FOR_BUYER: 'item-getWonItemsForBuyer',
    API_ITEMS_GET_ITEM_SALE_DATA: 'item-getItemSaleData',
    API_ITEMS_GET_ITEMS_BY_IDS: 'item-getItemsByIds',
    API_ITEMS_UPDATE_PERSISTED_ITEM: 'item-updatePersistedItem',
    API_ITEMS_GET_ITEM_MEDIA: 'item-getItemMedia',
    API_ITEMS_REMOVE_ITEM_MEDIA: 'item-removeItemMedia',
    API_ITEMS_UPDATE_ITEM_MEDIA_ORDER: 'item-updateItemMediaOrder',
    API_ITEMS_UPDATE_ITEM_IMAGES_COUNT: 'item-updateItemImagesCount',
    API_ITEMS_CREATE_LOADING_ITEM_MEDIA: 'item-createLoadingItemMedia',
    API_ITEMS_SCHEDULE_VEHICLES: 'item-scheduleVehicles',
    API_ITEMS_RESCHEDULE_VEHICLES: 'item-rescheduleVehicles',
    API_ITEMS_REORDER_VEHICLES: 'item-reorderVehicles',
    API_ITEMS_CURRENT_USER_PAID_ITEMS: 'item-currentUserPaidItems',
    API_ITEMS_FIND_SELLER_VEHICLES_BY_SELLER_VEHICLE_ID: 'item-findSellerVehiclesBySellerVehicleId',
    API_ITEMS_PERFORM_SELL_VEHICLE_OFFLINE: 'item-performSellVehicleOffline',
    API_ITEMS_CALCULATE_ITEMS_COUNT: 'item-calculateItemsCount',
    API_ITEMS_UNSCHEDULE_VEHICLE: 'item-unscheduleVehicle',

    API_PAYMENT_ADD_PAYMENT_METHOD: 'payment-addPaymentMethod',
    API_PAYMENT_REMOVE_PAYMENT_METHOD: 'payment-removePaymentMethod',
    API_PAYMENT_REPLACE_PAYMENT_METHOD: 'payment-replacePaymentMethod',
    API_PAYMENT_UPDATE_PAYMENT_METHOD: 'payment-updatePaymentMethod',
    API_PAYMENT_GET_FEES_BREAKDOWN_FOR_AMOUNT: 'bid-getFeesBreakdownForAmount',
    API_PAYMENT_GENERATE_RECEIPT: 'payment-generateReceipt',
    API_PAYMENT_GENERATE_PAY_IN_FULL_RECEIPT: 'payment-generatePayInFullReceipt',
    API_PAYMENT_GENERATE_RECENT_RECEIPT: 'payment-generateRecentReceipt',
    API_PAYMENT_STORE_PDF_RECEIPTS: 'payment-storePDFReceipts',
    API_PAYMENT_GENERATE_PDF_BULK_RECEIPT: 'payment-generatePDFBulkReceiptOnCall',
    API_PAYMENT_CREATE_PAYMENT_METHOD_SETUP_INTENT: 'payment-createPaymentMethodSetupIntent',
    API_PAYMENT_GET_CUSTOMER_INVOICES: 'payment-getCustomerInvoices',
    API_PAYMENT_GET_PAY_IN_FULL_DISCOUNT: 'payment-getPayInFullDiscount',
    API_PAYMENT_CHECK_IF_USER_IS_ABLE_TO_PAY_FOR_ITEMS: 'payment-checkIfUserIsAbleToPayForItems',
    API_PAYMENT_GENERATE_CHECKOUT: 'payment-generateCheckout',
    API_PAYMENT_CONFIRM_CC_HOLD: 'payment-confirmCcHold',
    API_PAYMENT_CONFIRM_CC_HOLD_AND_PLACE_CC_HOLD_FOR_BIDDER: 'payment-confirmCcHoldAndPlaceCcHoldForBidder',
    API_PAYMENT_PLACE_CC_HOLD_FOR_BIDDER: 'payment-placeCcHoldForBidder',
    API_PAYMENT_GET_USER_IDENTITY_VERIFICATION_URL: 'payment-getUserIdentityVerificationUrl',
    API_PAYMENT_GET_STRIPE_IDENTITY_VERIFICATION_SESSION: 'payment-getStripeIdentityVerificationSession',

    API_USER_CREATE_USER: 'user-createUser',
    API_USER_BAN_USERS: 'user-banUsers',
    API_USER_GRANT_RESTRICTED_ACCESS_TO_AUCTION_SERIES_FOR_USER: 'user-grantRestrictedAccessToAuctionSeriesForUser',
    API_USER_GET_USER_ACTIVE_RESTRICTED_ACCESS_AUCTION_SERIES: 'user-getUserActiveRestrictedAccessAuctionSeries',
    API_USER_BAN_USER_FOR_SELLERS: 'user-banUserForSellers',
    API_USER_IS_USER_BANNED: 'user-isUserBanned',
    API_USER_BAN_USER_AS_SELLER: 'user-banUserAsSeller',
    API_USER_BAN_USERS_FOR_AUCTION_SELLER: 'user-banUsersForAuctionSeller',
    API_USER_UNBAN_USERS_FOR_SELLERS: 'user-unbanUserForSellers',
    API_USER_FIND_SELLERS_FOR_BANNED_USER: 'user-findSellersForBannedUser',
    API_USER_SEND_BUYER_COMMUNICATION: 'user-sendBuyerCommunication',
    API_USER_FETCH_BUYER_COMMUNICATIONS: 'user-fetchBuyerCommunications',
    API_USER_SEND_NOTIFICATION_BIDDING_OPEN: 'user-sendNotificationBiddingOpen',
    API_USER_TOGGLE_FAVORITE_REGION: 'user-toggleFavoriteRegion',
    API_USER_TOGGLE_FAVORITE_AUCTION_SERIES: 'user-toggleFavoriteAuctionSeries',
    API_USER_TOGGLE_FAVORITE_AUCTION: 'user-toggleFavoriteAuction',
    API_USER_TOGGLE_FAVORITE_ITEM: 'user-toggleFavoriteItem',
    API_USER_FETCH_SELLERS_FOR_PREAPPROVED_USER: 'user-fetchSellersForPreapprovedUser',
    API_USER_SAVE_SELLERS_FOR_PREAPPROVED_USER: 'user-saveSellersForPreapprovedUser',
    API_USER_SEND_EMAIL_VERIFICATION_FOR_USER: 'user-sendEmailVerificationForUser',
    API_USER_UPDATE_EMAIL_FOR_USER: 'user-updateEmailForUser',
    API_USER_SAVE_USER_NOTE: 'user-saveUserNote',
    API_USER_SAVE_CONTACT_DETAILS: 'user-saveContactDetails',
    API_USER_UPDATE_VERIFICATION_STATUS_BY_ADMIN_FOR_USER: 'user-updateVerificationStatusByAdminForUser',
    API_USER_UPDATE_USER: 'user-updateUser',
    API_USER_FIND_USER_BY_EMAIL: 'user-findUserByEmail',
    API_USER_FIND_USER_BY_ID: 'user-findUserById',
    API_USER_GET_WINNING_BUYER_INFO: 'user-getWinningBuyerInfo',
    API_USER_GET_USER_ACTIVITY: 'user-getUserActivity',
    API_USER_SEARCH_USERS: 'user-searchUsers',
    API_USER_GET_VEHICLES_WITH_FAILED_DEPOSIT: 'user-getVehiclesWithFailedDeposit',
    API_USER_CHECK_IS_USER_BLACKLISTED: 'user-checkIsUserBlacklisted',
    API_USER_BAN_USER_GLOBALLY: 'user-banUserGlobally',
    API_USER_GET_GLOBALLY_BANNED_USER: 'user-getGloballyBannedUser',
    API_USER_BYPASS_EMAIL_VERIFICATION_FOR_USER: 'user-bypassEmailVerificationForUser',
    API_USER_BYPASS_PHONE_VERIFICATION_FOR_USER: 'user-bypassPhoneVerificationForUser',
    API_USER_GET_ZIP_CODE_BY_COORDINATES: 'user-getZipCodeByCoordinates',
    API_USER_GET_COORDINATES_BY_ZIP_CODE: 'user-getCoordinatesByZipCode',
    API_USER_USER_WON_ITEMS: 'user-userWonItems',
    API_USER_MARK_VEHICLE_AS_PICKED_UP: 'user-markVehicleAsPickedUp',
    API_USER_CURRENT_USER_WON_ITEMS: 'user-currentUserWonItems',
    API_USER_CURRENT_USER_CLAIMED_ITEMS: 'user-currentUserClaimedItems',
    API_USER_MARK_FOR_REMOVE_TRANSIENT_EVENT_STATE: 'user-markForRemoveTransientEventState',
    API_USER_UPDATE_STATE_FOR_ALL_USER_PERSISTED_NOTIFICATIONS: 'user-updateStateForAllUserPersistedNotifications',
    API_USER_SAVE_PERSISTED_NOTIFICATIONS: 'user-savePersistedNotifications',
    API_USER_GET_ZIP_CODE_BY_IP: 'user-getZipCodeByIP',

    API_BID_CHECK_AUCTION_BIDDING_ENDED: 'bid-checkAuctionBiddingEnded',
    API_BID_CANCEL_AUTO_BID: 'bid-cancelAutobid',
    API_BID_REVERT_BID: 'bid-revertBid',
    API_BID_GET_PERSISTED_BIDS: 'bid-getPersistedBids',
    API_BIDS_FETCH_ALL_ITEMS_WITH_OFFERS: 'bid-fetchAllItemsWithOffers',
    API_BIDS_HANDLE_HIGHEST_OFFER_BY_SELLER: 'bid-handleHighestOfferBySeller',
    API_BIDS_REJECTY_ALL_ACTIVE_OFFERS: 'bid-rejectAllActiveOffers',

    API_PHONE_SEND_VERIFICATION_CODE: 'phone-sendVerificationCode',
    API_PHONE_CHECK_VERIFICATION_CODE: 'phone-checkVerificationCode',
    API_PHONE_CANCEL_PHONE_VERIFICATION: 'phone-cancelPhoneVerification',
    API_PHONE_VALIDATE_PHONE_NUMBER: 'phone-validatePhoneNumber',

    API_GOOGLE_VALIDATE_GOOGLE_DRIVE_FOLDER: 'google-validateGoogleDriveFolder',
    API_GOOGLE_SET_GOOGLE_DRIVE_QUEUE: 'google-setGoogleDriveQueue',
    API_GOOGLE_GOOGLE_IMPORT_MANUAL: 'google-importManual',

    API_USER_ROLES_ADD_ROLE_TO_USER: 'userRoles-addRoleToUser',
    API_USER_ROLES_REMOVE_ROLE_FOR_USER: 'userRoles-removeRoleForUser',
    API_USER_ROLES_FIND_ASSOCIATED_USERS_FOR_SELLER: 'userRoles-findAssociatedUsersForSeller',

    API_WHITELIST_GET_WHITELIST_PHONES: 'whitelist-getWhitelistPhones',
    API_WHITELIST_CREATE_WHITELIST_PHONE: 'whitelist-createWhitelistPhone',
    API_WHITELIST_REMOVE_WHITE_LIST_PHONE: 'whitelist-removeWhitelistPhone',

    API_AUDIT_CREATE_EVENT: 'audit-createEvent',
} as const;
export type FEReqRoute = typeof feReqRoutes[keyof typeof feReqRoutes];

export const feRequestHandlers = {
    APP_HTTP: 'app-http',
    SELLERS_HTTP: 'sellers-http',
    MEDIA_HTTP: 'media-http',
    BIDDING_WS: 'bidding-ws',
    CLOUD_FUNCTIONS: 'cloud-functions',
    FIREBASE_HOSTING: 'firebase-hosting',
    SEARCH_HTTP: 'search-http',
    AUCTIONS_HTTP: 'auctions-http',
    ITEMS_HTTP: 'items-http',
    PAYMENTS_HTTP: 'payments-http',
    USERS_HTTP: 'users-http',
    PHONES_HTTP: 'phones-http',
    BIDS_HTTP: 'bids-http',
    MIGRATIONS_HTTP: 'migrations-http',
} as const;

export type FERequestHandler = typeof feRequestHandlers[keyof typeof feRequestHandlers];

export const FALLBACK_FE_REQUEST_HANDLER = feRequestHandlers.APP_HTTP;

export const DEFAULT_PROJECT_REGION = 'us-central1';

export interface MicroservicesConfig {
    defaultRequestHandler?: string;
    appHttpUrl?: string;
    sellersHttpUrl?: string;
    searchHttpUrl?: string;
    auctionsHttpUrl?: string;
    itemsHttpUrl?: string;
    mediaHttpUrl?: string;
    paymentsHttpUrl?: string;
    usersHttpUrl?: string;
    phonesHttpUrl?: string;
    bidsHttpUrl?: string;
    biddingWsUrl?: string;
    migrationsHttpUrl?: string;
    restApiHttpUrl?: string;
}

export interface FERoutingConfig {
    projectId: string;
    functionUrl?: string;
    microservices: MicroservicesConfig;
}

function getCloudFunctionsUrl(config: FERoutingConfig) {
    if (config.functionUrl) {
        return config.functionUrl;
    }
    const requestHandler =
        config.microservices.defaultRequestHandler ??
        process.env.REACT_APP_DEFAULT_REQUEST_HANDLER ??
        feRequestHandlers.CLOUD_FUNCTIONS;
    if (isLocalHost() && requestHandler === feRequestHandlers.CLOUD_FUNCTIONS) {
        throw new Error('config.functionUrl is empty');
    }
    return `https://${DEFAULT_PROJECT_REGION}-${config.projectId}.cloudfunctions.net`;
}

export const feHandlersURLs = (config: FERoutingConfig): {[key in FERequestHandler]: string | undefined} => ({
    [feRequestHandlers.CLOUD_FUNCTIONS]: getCloudFunctionsUrl(config),
    [feRequestHandlers.APP_HTTP]: config.microservices.appHttpUrl,
    [feRequestHandlers.SELLERS_HTTP]: config.microservices.sellersHttpUrl,
    [feRequestHandlers.SEARCH_HTTP]: config.microservices.searchHttpUrl,
    [feRequestHandlers.AUCTIONS_HTTP]: config.microservices.auctionsHttpUrl,
    [feRequestHandlers.ITEMS_HTTP]: config.microservices.itemsHttpUrl,
    [feRequestHandlers.PAYMENTS_HTTP]: config.microservices.paymentsHttpUrl,
    [feRequestHandlers.USERS_HTTP]: config.microservices.usersHttpUrl,
    [feRequestHandlers.PHONES_HTTP]: config.microservices.phonesHttpUrl,
    [feRequestHandlers.BIDS_HTTP]: config.microservices.bidsHttpUrl,
    // MIGRATIONS_HTTP is used by migrations module that uses FE Routing service too
    [feRequestHandlers.MIGRATIONS_HTTP]: config.microservices.migrationsHttpUrl,
    // Right now we are using this as a webhook endpoint for AWS.
    [feRequestHandlers.MEDIA_HTTP]: config.microservices.mediaHttpUrl || globalThis?.location?.origin,
    [feRequestHandlers.BIDDING_WS]: config.microservices.biddingWsUrl,
    // Note, FERoutingService is also used in test to simulate requests from WebSockets, but window won't be available
    //  in jest, so we use globalThis here
    [feRequestHandlers.FIREBASE_HOSTING]: globalThis?.location?.origin,
});

// handlersByRoute contains only overrides; other routes will be handled by the default request handler
export const feHandlersByRoute: Partial<Record<FEReqRoute, FERequestHandler>> = {
    [feReqRoutes.AWS_WEBHOOK_UPLOAD_MEDIA]: feRequestHandlers.MEDIA_HTTP,
    [feReqRoutes.AWS_GET_OPEN_ID_TOKEN_FOR_DEVELOPER_IDENTITY]: feRequestHandlers.APP_HTTP,
    [feReqRoutes.API_SELLERS_CREATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_REGIONS_UPDATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_GOOGLE_DRIVE_FOLDER_ID_UPDATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_ADDRESS_UPDATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_ACCEPTS_PAY_IN_FULL_UPDATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_PICKUP_MESSAGE_UPDATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_LOCATION_UPDATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_DOCUMENTS_CREATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_DOCUMENTS_DELETE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_FEE_UPDATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_APPLICATION_PDF_GET]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_APPLICATION_PDF_GENERATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_APPLICATION_APPROVE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_APPLICATION_DECLINE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_APPLICATION_UPDATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_APPLICATION_CONFIRM]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_APPLICATION_PROCESS_CANCEL]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_APPLICATION_STATUS_GET]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_APPLICATION_GO_BACK]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_INFO_UPDATE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_ITEMS_BY_STATUS]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_CREATE_CUSTOM_FIELD]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_UPDATE_CUSTOM_FIELD]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_DELETE_CUSTOM_FIELD]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_VALIDATE_GATE_CODE]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_PAYMENT_INFO]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_BUYER_INFO]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_UPDATE_CLAIM_SESSION]: feRequestHandlers.SELLERS_HTTP,
    [feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_CONFIRM_CLAIM_SESSION]: feRequestHandlers.SELLERS_HTTP,

    [feReqRoutes.API_SEARCH_VEHICLES_BY_TEXT]: feRequestHandlers.SEARCH_HTTP,
    [feReqRoutes.API_SEARCH_INVENTORY_VEHICLES]: feRequestHandlers.SEARCH_HTTP,
    [feReqRoutes.API_SEARCH_GET_VEHICLES_SUGGESTIONS]: feRequestHandlers.SEARCH_HTTP,
    [feReqRoutes.API_SEARCH_GET_PUBLISHED_VEHICLES_FOR_FILTERS]: feRequestHandlers.SEARCH_HTTP,

    [feReqRoutes.API_AUCTIONS_GET_AUCTION_SERIES]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_UPDATE_AUCTION_PAUSE]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_END_AUCTION_VEHICLE]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_SKIP_AUCTION_VEHICLE]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_CHECK_NEXT_ITEM_UPDATED]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_UPDATE_AUCTION_EXPIRATION]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_UPDATE_AUCTION_EVENT_START]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_UPDATE_AUCTION_START]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_UPDATE_AUCTION_SMS_NOTIFICATIONS]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_UPDATE_AUCTION_SMS_LEADTIME]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_UPDATE_AUCTION_SERIES]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_UPDATE_AUCTION_OCCURRENCE]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_RESCHEDULE_AUCTION_OCCURRENCE]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_EXTEND_BIDDING_EXPIRATION]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_ADD_SELLER_TO_AUCTION_SERIES]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_REMOVE_SELLER_FROM_AUCTION_SERIES]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_CLEAN_AUCTIONS]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_ENABLE_PLACING_BIDS]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_GET_AUCTION_SCHEDULE]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_SAVE_AUCTION_SCHEDULE]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_DELETE_AUCTION_SCHEDULE]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_DELETE_AUCTION]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_DELETE_ALL_AUCTIONS_DATA]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_VALIDATE_CONFIGURATION]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_SAVE_BID_INCREMENT]: feRequestHandlers.AUCTIONS_HTTP,
    [feReqRoutes.API_AUCTIONS_CREATE_OR_UPDATE_PLATFORM_FEE_SCHEDULE]: feRequestHandlers.AUCTIONS_HTTP,

    [feReqRoutes.API_ITEMS_FIX_ITEMS_EXPIRATION_IN_AUCTION]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_FETCH_VEHICLE_CSV_DATA]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_FETCH_AUCTION_CSV_DATA]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_FETCH_VEHICLE_EXCEL_DATA]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_FETCH_VEHICLES_STATUSES_STATISTIC]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_FETCH_UPCOMING_AUCTIONS_DATA]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_UPLOAD_ITEMS_CSV]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_UPDATE_VEHICLES_IN_FIREBASE]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_UPDATE_VEHICLE_STATUS_IN_FIREBASE]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_UPDATE_VEHICLE_STATUS]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_UPDATE_VEHICLES_STATUS]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_CLONE_VEHICLE]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_GET_ITEMS_FOR_SELLER]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_GET_ITEMS_FOR_BUYER]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_GET_WON_ITEMS_FOR_BUYER]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_GET_INVENTORY_ITEMS_FOR_AUCTION]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_GET_ITEM_SALE_DATA]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_GET_ITEMS_BY_IDS]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_UPDATE_PERSISTED_ITEM]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_GET_ITEM_MEDIA]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_REMOVE_ITEM_MEDIA]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_UPDATE_ITEM_MEDIA_ORDER]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_UPDATE_ITEM_IMAGES_COUNT]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_CREATE_LOADING_ITEM_MEDIA]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_SCHEDULE_VEHICLES]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_RESCHEDULE_VEHICLES]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_REORDER_VEHICLES]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_CURRENT_USER_PAID_ITEMS]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_FIND_SELLER_VEHICLES_BY_SELLER_VEHICLE_ID]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_PERFORM_SELL_VEHICLE_OFFLINE]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_CALCULATE_ITEMS_COUNT]: feRequestHandlers.ITEMS_HTTP,
    [feReqRoutes.API_ITEMS_UNSCHEDULE_VEHICLE]: feRequestHandlers.ITEMS_HTTP,

    [feReqRoutes.API_PAYMENT_ADD_PAYMENT_METHOD]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_REMOVE_PAYMENT_METHOD]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_REPLACE_PAYMENT_METHOD]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_UPDATE_PAYMENT_METHOD]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_GET_FEES_BREAKDOWN_FOR_AMOUNT]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_GENERATE_RECEIPT]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_GENERATE_PAY_IN_FULL_RECEIPT]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_GENERATE_RECENT_RECEIPT]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_STORE_PDF_RECEIPTS]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_GENERATE_PDF_BULK_RECEIPT]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_CREATE_PAYMENT_METHOD_SETUP_INTENT]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_GET_CUSTOMER_INVOICES]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_GET_PAY_IN_FULL_DISCOUNT]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_CHECK_IF_USER_IS_ABLE_TO_PAY_FOR_ITEMS]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_GENERATE_CHECKOUT]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_CONFIRM_CC_HOLD]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_CONFIRM_CC_HOLD_AND_PLACE_CC_HOLD_FOR_BIDDER]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_PLACE_CC_HOLD_FOR_BIDDER]: feRequestHandlers.PAYMENTS_HTTP,

    [feReqRoutes.API_PAYMENT_GET_USER_IDENTITY_VERIFICATION_URL]: feRequestHandlers.PAYMENTS_HTTP,
    [feReqRoutes.API_PAYMENT_GET_STRIPE_IDENTITY_VERIFICATION_SESSION]: feRequestHandlers.PAYMENTS_HTTP,

    [feReqRoutes.API_USER_CREATE_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_BAN_USERS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_GRANT_RESTRICTED_ACCESS_TO_AUCTION_SERIES_FOR_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_GET_USER_ACTIVE_RESTRICTED_ACCESS_AUCTION_SERIES]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_BAN_USER_FOR_SELLERS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_IS_USER_BANNED]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_BAN_USER_AS_SELLER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_BAN_USERS_FOR_AUCTION_SELLER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_UNBAN_USERS_FOR_SELLERS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_FIND_SELLERS_FOR_BANNED_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_SEND_BUYER_COMMUNICATION]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_FETCH_BUYER_COMMUNICATIONS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_SEND_NOTIFICATION_BIDDING_OPEN]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_TOGGLE_FAVORITE_REGION]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_TOGGLE_FAVORITE_AUCTION_SERIES]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_TOGGLE_FAVORITE_AUCTION]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_TOGGLE_FAVORITE_ITEM]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_FETCH_SELLERS_FOR_PREAPPROVED_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_SAVE_SELLERS_FOR_PREAPPROVED_USER]: feRequestHandlers.USERS_HTTP,

    [feReqRoutes.API_USER_SEND_EMAIL_VERIFICATION_FOR_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_UPDATE_EMAIL_FOR_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_SAVE_USER_NOTE]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_SAVE_CONTACT_DETAILS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_UPDATE_VERIFICATION_STATUS_BY_ADMIN_FOR_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_UPDATE_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_FIND_USER_BY_EMAIL]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_FIND_USER_BY_ID]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_GET_WINNING_BUYER_INFO]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_GET_USER_ACTIVITY]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_SEARCH_USERS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_CHECK_IS_USER_BLACKLISTED]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_BAN_USER_GLOBALLY]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_GET_GLOBALLY_BANNED_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_BYPASS_EMAIL_VERIFICATION_FOR_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_BYPASS_PHONE_VERIFICATION_FOR_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_GET_ZIP_CODE_BY_COORDINATES]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_GET_COORDINATES_BY_ZIP_CODE]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_USER_WON_ITEMS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_MARK_VEHICLE_AS_PICKED_UP]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_CURRENT_USER_WON_ITEMS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_CURRENT_USER_CLAIMED_ITEMS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_GET_VEHICLES_WITH_FAILED_DEPOSIT]: feRequestHandlers.USERS_HTTP,

    [feReqRoutes.API_USER_GET_ZIP_CODE_BY_IP]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_MARK_FOR_REMOVE_TRANSIENT_EVENT_STATE]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_UPDATE_STATE_FOR_ALL_USER_PERSISTED_NOTIFICATIONS]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_SAVE_PERSISTED_NOTIFICATIONS]: feRequestHandlers.USERS_HTTP,

    [feReqRoutes.API_BID_CHECK_AUCTION_BIDDING_ENDED]: feRequestHandlers.BIDS_HTTP,
    [feReqRoutes.API_BID_CANCEL_AUTO_BID]: feRequestHandlers.BIDS_HTTP,
    [feReqRoutes.API_BID_REVERT_BID]: feRequestHandlers.BIDS_HTTP,
    [feReqRoutes.API_BID_GET_PERSISTED_BIDS]: feRequestHandlers.BIDS_HTTP,
    [feReqRoutes.API_BIDS_FETCH_ALL_ITEMS_WITH_OFFERS]: feRequestHandlers.BIDS_HTTP,
    [feReqRoutes.API_BIDS_HANDLE_HIGHEST_OFFER_BY_SELLER]: feRequestHandlers.BIDS_HTTP,
    [feReqRoutes.API_BIDS_REJECTY_ALL_ACTIVE_OFFERS]: feRequestHandlers.BIDS_HTTP,

    [feReqRoutes.API_GOOGLE_VALIDATE_GOOGLE_DRIVE_FOLDER]: feRequestHandlers.APP_HTTP,
    [feReqRoutes.API_GOOGLE_SET_GOOGLE_DRIVE_QUEUE]: feRequestHandlers.APP_HTTP,
    [feReqRoutes.API_GOOGLE_GOOGLE_IMPORT_MANUAL]: feRequestHandlers.APP_HTTP,

    [feReqRoutes.API_USER_ROLES_ADD_ROLE_TO_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_ROLES_REMOVE_ROLE_FOR_USER]: feRequestHandlers.USERS_HTTP,
    [feReqRoutes.API_USER_ROLES_FIND_ASSOCIATED_USERS_FOR_SELLER]: feRequestHandlers.USERS_HTTP,

    [feReqRoutes.API_PHONE_SEND_VERIFICATION_CODE]: feRequestHandlers.PHONES_HTTP,
    [feReqRoutes.API_PHONE_CHECK_VERIFICATION_CODE]: feRequestHandlers.PHONES_HTTP,
    [feReqRoutes.API_PHONE_CANCEL_PHONE_VERIFICATION]: feRequestHandlers.PHONES_HTTP,
    [feReqRoutes.API_PHONE_VALIDATE_PHONE_NUMBER]: feRequestHandlers.PHONES_HTTP,

    [feReqRoutes.API_WHITELIST_GET_WHITELIST_PHONES]: feRequestHandlers.PHONES_HTTP,
    [feReqRoutes.API_WHITELIST_CREATE_WHITELIST_PHONE]: feRequestHandlers.PHONES_HTTP,
    [feReqRoutes.API_WHITELIST_REMOVE_WHITE_LIST_PHONE]: feRequestHandlers.PHONES_HTTP,

    [feReqRoutes.API_AUDIT_CREATE_EVENT]: feRequestHandlers.APP_HTTP,
};

/**
 * It knows how to build full URLs for sending requests (i.e. it will direct the request to a proper BE handler).
 */
export function createFERoutingService(config: FERoutingConfig) {
    return new RoutingService<FERequestHandler>(feHandlersURLs(config), FALLBACK_FE_REQUEST_HANDLER, feHandlersByRoute);
}
