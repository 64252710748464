import {WhitelistPhone} from '@joyrideautos/auction-core/src/types/WhiteList';
import {feReqRoutes} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {BaseService} from './BaseService';

export class WhiteListService extends BaseService {
    async fetchWhitelistPhones(): Promise<WhitelistPhone[]> {
        return await this.firebase.rpcService.call(feReqRoutes.API_WHITELIST_GET_WHITELIST_PHONES)();
    }

    async createWhitelistPhone(phone: string): Promise<boolean> {
        return await this.firebase.rpcService.call(feReqRoutes.API_WHITELIST_CREATE_WHITELIST_PHONE)(phone);
    }

    async removeWhitelistPhone(phone: string): Promise<boolean> {
        return await this.firebase.rpcService.call(feReqRoutes.API_WHITELIST_REMOVE_WHITE_LIST_PHONE)(phone);
    }
}
