import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {Item, PersistedItem} from '@joyrideautos/auction-core/src/types/Item';
import {flatten} from '@joyrideautos/auction-utils/src/arrayUtils';
import {filterUndef} from '@joyrideautos/auction-utils/src/objectUtils';
import {PersistedItemStatusEnum} from '@joyrideautos/auction-core/src/types/ItemTypes';
import {SortingEnum} from '@joyrideautos/auction-core/src/types/Sorting';
import {
    DraftSeller,
    Seller,
    SellerCustomField,
    SellerSettings,
    SellerPickupMessage,
} from '@joyrideautos/auction-core/src/types/Seller';
import {SellerApplication} from '@joyrideautos/auction-core/src/types/SellerApplication';
import {DocumentTypes, DocumentWithId} from '@joyrideautos/auction-core/src/types/Document';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';
import {BaseService} from './BaseService';
import {feReqRoutes} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {keyOnlySnapToArrayFactory} from '../firebase/Database';
import {Unsubscribe} from '../types';
import {
    CreateCustomFieldReqData,
    CreateCustomFieldResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/createSellerCustomFields';
import {
    UpdateCustomFieldReqData,
    UpdateCustomFieldResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/updateSellerCustomFields';
import {
    DeleteCustomFieldReqData,
    DeleteCustomFieldResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/deleteSellerCustomFields';
import {
    UpdateSellerLocationReqData,
    UpdateSellerLocationResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/updateSellerLocation';
import {
    GetClaimSessionPaymentInfoRPCReqData,
    GetClaimSessionPaymentInfoRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/getClaimSessionPaymentInfoReqTypes';
import {
    GetClaimSessionRPCReqData,
    GetClaimSessionRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/getClaimSessionReqTypes';
import {
    GetClaimSessionBuyerInfoRPCReqData,
    GetClaimSessionBuyerInfoRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/getClaimSessionBuyerInfoReqTypes';
import {
    UpdateClaimSessionRPCReqData,
    UpdateClaimSessionRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/updateClaimSessionReqTypes';
import {
    ConfirmClaimSessionRPCReqData,
    ConfirmClaimSessionRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/confirmClaimSessionReqTypes';

function toPersistedItems(snapshots: any[] | null): WithKey<PersistedItem>[] {
    if (!snapshots) {
        return [];
    }
    return snapshots.map((snapshot) => {
        const item = snapshot.data() as PersistedItem;
        return {...item, key: snapshot.id};
    });
}

export class SellersService extends BaseService {
    async fetchManagersSellers(authUserId: string): Promise<{id: string}[]> {
        const snapshot = await this.firebase.database.fetchOnceSnapshot(`/userRoles/managers/${authUserId}/sellers`);

        const sellers: {id: string}[] = [];
        snapshot.forEach((s) => {
            if (s.key) {
                sellers.push({id: s.key});
            }
        });
        return sellers;
    }

    fetchManagersSellersForCurrentUser = async (): Promise<{id: string}[]> => {
        const currentUser = this.firebase.auth.currentUser;
        if (currentUser) {
            return this.fetchManagersSellers(currentUser.uid);
        }
        return Promise.resolve([]);
    };

    async fetchSellers(): Promise<Seller[]> {
        const result = await this.firebase.database.fetchOnceSnapshot(`/sellers/`);
        if (!result.exists()) {
            return Promise.resolve([]);
        }
        const sellerEntries = result.val();

        const sellers: Seller[] = Object.keys(sellerEntries).map((seller) => ({
            ...sellerEntries[seller],
            sellerId: seller,
        }));
        return Promise.resolve(sellers);
    }

    subscribeToSellers(subscriber: (sellers: {[sellerId: string]: Seller}) => void): Unsubscribe {
        return this.firebase.database.subscribe<{
            [sellerId: string]: Omit<Seller, 'sellerId'>;
        }>('/sellers', (sellers) => {
            if (sellers) {
                const result: {[sellerId: string]: Seller} = {};
                Object.keys(sellers).forEach((sellerId) => {
                    const seller = sellers[sellerId];
                    result[sellerId] = {
                        sellerId,
                        ...seller,
                        internal_docs: seller.internal_docs ? Object.values(seller.internal_docs) : [],
                        payment_info_docs: seller.payment_info_docs ? Object.values(seller.payment_info_docs) : [],
                        policy_docs: seller.policy_docs ? Object.values(seller.policy_docs) : [],
                        customFields: Object.keys(seller.customFields ?? {}).reduce<
                            Record<string, SellerCustomField> | undefined
                        >((acc, key) => {
                            if (!seller.customFields) {
                                return acc;
                            }
                            return {
                                ...acc,
                                [key]: {
                                    ...seller.customFields[key],
                                    key,
                                },
                            };
                        }, undefined),
                    };
                });
                subscriber(result);
            }
        });
    }

    fetchSeller = async (sellerId: string): Promise<Seller> => {
        const sellers = await this.firebase.database.fetchOnceSnapshot(`/sellers/${sellerId}/`);
        return Promise.resolve(sellers.val());
    };

    fetchSellersForPreapprovedUser = async (uid: string): Promise<string[]> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_USER_FETCH_SELLERS_FOR_PREAPPROVED_USER)({uid});
    };

    saveSellersForPreapprovedUser = async (uid: string, sellerIds: string[] | null): Promise<void> => {
        await this.firebase.rpcService.call(feReqRoutes.API_USER_SAVE_SELLERS_FOR_PREAPPROVED_USER)({uid, sellerIds});
    };

    updateSellerLocation = async (data: UpdateSellerLocationReqData): Promise<UpdateSellerLocationResData> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_LOCATION_UPDATE)(data);
    };

    updateSellerPickupMessage = async (id: string, pickupMessage: SellerPickupMessage): Promise<any> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_PICKUP_MESSAGE_UPDATE)({id, pickupMessage});
    };

    saveSellerFee = async (sellerSettings: any, sellerId: string): Promise<SellerSettings> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_FEE_UPDATE)({sellerSettings, sellerId});
    };

    updateSellerRegions = async (id: string, regions: string[]): Promise<any> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_REGIONS_UPDATE)({id, regions});
    };

    updateSellerGoogleFolderId = async (id: string, folderId: string): Promise<any> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_GOOGLE_DRIVE_FOLDER_ID_UPDATE)({
            id,
            folderId,
        });
    };

    updateSellerAcceptsFullVehiclePayment = async (id: string, value: boolean): Promise<any> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_ACCEPTS_PAY_IN_FULL_UPDATE)({id, value});
    };

    updateSellerInfo = async (id: string, value: Partial<Seller>): Promise<any> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_INFO_UPDATE)({id, value});
    };

    createCustomField = async (data: CreateCustomFieldReqData): Promise<CreateCustomFieldResData> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_CREATE_CUSTOM_FIELD)(data);
    };
    updateCustomField = async (data: UpdateCustomFieldReqData): Promise<UpdateCustomFieldResData> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_UPDATE_CUSTOM_FIELD)(data);
    };
    deleteCustomField = async (data: DeleteCustomFieldReqData): Promise<DeleteCustomFieldResData> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_DELETE_CUSTOM_FIELD)(data);
    };

    updateSellerAddress = async (
        id: string,
        name: string,
        phoneNumber?: string,
        emailAddress?: string,
        internalEmailAddress?: string
    ): Promise<any> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_ADDRESS_UPDATE)({
            id,
            name,
            phoneNumber,
            emailAddress,
            internalEmailAddress,
        });
    };

    /**
     * @returns string - id of the created seller
     */
    createSeller = async (sellerInfo: DraftSeller): Promise<string> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_CREATE)(sellerInfo);
    };

    addDocumentToSeller = async (sellerId: string, document: DocumentWithId, type: DocumentTypes): Promise<any> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_DOCUMENTS_CREATE)({
            sellerId,
            ...document,
            type,
        });
    };

    deleteDocumentToSeller = async (sellerId: string, type: string, id: string): Promise<any> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_DOCUMENTS_DELETE)({
            sellerId,
            type,
            docId: id,
        });
    };

    fetchSellerItemsByStatus = async (
        sellerId: string,
        status: PersistedItemStatusEnum,
        offset?: number,
        limit?: number,
        order?: SortingEnum,
        fromRtdb = false
    ): Promise<WithKey<PersistedItem>[]> => {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_ITEMS_BY_STATUS)({
            sellerId,
            status,
            offset,
            limit,
            order,
            fromRtdb,
        });
    };

    async fetchSellerApplications(): Promise<{[uid: string]: SellerApplication & {uid: string}} | undefined> {
        const docs = await this.firebase.firestore.fetchOnceDocuments<SellerApplication>(
            this.firebase.firestore.collectionRef('/sellerApplications')
        );
        if (!docs) {
            return undefined;
        }
        const result: {[uid: string]: SellerApplication & {uid: string}} = {};
        docs.forEach((doc) => {
            // TODO: (future) remove cast
            result[doc.id!] = {...doc.data(), uid: doc.id} as any;
        });
        return result;
    }

    async approveSellerApplication(uid: string, regionIds: string[]) {
        await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_APPLICATION_APPROVE)({uid, regionIds});
    }

    async declineSellerApplication(uid: string) {
        await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_APPLICATION_DECLINE)({uid});
    }

    async generateSellerApplicationPDF() {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_APPLICATION_PDF_GENERATE);
    }

    async getSellerApplicationPDF(uid: string) {
        return await this.firebase.rpcService.call(feReqRoutes.API_SELLERS_APPLICATION_PDF_GET)({uid});
    }

    async findSellersAssociatedWithAuctionSeries(regionKey: string, seriesKey: string): Promise<(string | null)[]> {
        const query = `/${regionKey}/auctionSeries/${seriesKey}/sellers`;
        return await this.firebase.database.fetchOnceArray<string | null>(query, keyOnlySnapToArrayFactory);
    }

    async findAuctionSeriesAssociatedWithSeller(sellerId: string): Promise<
        {
            regionId: string;
            seriesIds: number[];
        }[]
    > {
        const query = `/sellers/${sellerId}/auctionSeries`;
        return await this.firebase.database.fetchOnceArray<{regionId: string; seriesIds: number[]}>(
            query,
            (snapshot) => {
                return [
                    {
                        key: snapshot.key!,
                        value: {
                            regionId: snapshot.key!,
                            seriesIds: Object.keys(snapshot.val()).map((id) => +id),
                        },
                    },
                ];
            }
        );
    }

    fetchSellerItemsByStatusLocal = ({
        sellerId,
        status,
        orderByValues,
        initialValues,
        fromRtdb = false,
        selectedFilterByAuctionValue,
    }: {
        sellerId: string;
        status: PersistedItemStatusEnum;
        orderByValues?: {
            field: string;
            direction?: SortingEnum;
        }[];
        selectedFilterByAuctionValue?: {regionId: string; auctionId: string; formattedAsMonthDayYear: string};
        initialValues?: any[];
        fromRtdb?: boolean;
    }) => {
        const getRtdbItems = async (items: WithKey<PersistedItem>[]): Promise<Item[]> => {
            const itemKeys = flatten(
                items.map((item) =>
                    item.regionId && item.auctionId && item.itemId
                        ? [
                              {
                                  key: item.key,
                                  path: `/${item.regionId}/items/${item.auctionId}/${item.itemId}`,
                                  publishedAt: item.publishedAt,
                              },
                          ]
                        : []
                )
            );
            const itemsWithBrokenPaths: string[] = [];
            const rtdbItems = filterUndef<Item>(
                await Promise.all(
                    itemKeys.map((path) =>
                        this.firebase.database.fetchOnce<Item>(path.path).then((item) => {
                            if (!item) {
                                itemsWithBrokenPaths.push(path.key);
                            }
                            return item
                                ? ({
                                      ...item,
                                      publishedAt: path.publishedAt,
                                  } as Item)
                                : undefined;
                        })
                    )
                )
            );
            if (itemsWithBrokenPaths.length > 0) {
                this.logger.error(
                    'fetchSellerItemsByStatusLocal. Found items with broken pathes to auction',
                    itemsWithBrokenPaths
                );
            }
            return rtdbItems;
        };

        const queryBuilder = (
            sellerId: string,
            status: PersistedItemStatusEnum,
            orderByValues?: {
                field: string;
                direction?: SortingEnum;
            }[],
            selectedFilterByAuctionValue?: {
                regionId: string;
                auctionId: string;
                formattedAsMonthDayYear: string;
            }
        ) => {
            let _query = this.firebase.firestore
                .collectionRef<PersistedItem>('items')
                .where('sellerId', '==', sellerId)
                .where('status', '==', status);

            if (selectedFilterByAuctionValue) {
                _query = _query
                    .where('regionId', '==', selectedFilterByAuctionValue?.regionId)
                    .where('auctionId', '==', selectedFilterByAuctionValue?.auctionId);
            }

            if (orderByValues) {
                // TODO: (future) update field type to `typeof PersistedItem`
                orderByValues.forEach(({field, direction}) => (_query = _query.orderBy(field as any, direction)));
            }
            return (lastSnapshot: any | null, limitTo?: number) => {
                let query = _query;

                if (limitTo) {
                    query = query.limit(limitTo);
                }

                if (initialValues && !lastSnapshot) {
                    query = query.startAfter(...initialValues);
                } else if (lastSnapshot) {
                    query = query.startAfter(lastSnapshot);
                }
                return query;
            };
        };

        let lastSnapshot: any | null;
        const makeQuery = queryBuilder(sellerId, status, orderByValues, selectedFilterByAuctionValue);

        return async <T = WithKey<PersistedItem>>({limit}: {limit?: number}): Promise<T[]> => {
            const snapshots = await this.firebase.firestore.fetchOnceDocuments(makeQuery(lastSnapshot, limit));
            lastSnapshot = snapshots && limit ? snapshots[limit - 1] : null;

            const items = toPersistedItems(snapshots);
            if (fromRtdb) {
                return cast(await getRtdbItems(items));
            } else {
                return cast(items);
            }
        };
    };

    async fetchClaimSessionPaymentInfo(claimSessionKey: string) {
        return await this.firebase.rpcService.call(
            feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_PAYMENT_INFO
        )<GetClaimSessionPaymentInfoRPCReqData, GetClaimSessionPaymentInfoRPCResData>({
            claimSessionKey,
        });
    }

    async fetchClaimSession(claimSessionKey: string) {
        return this.firebase.rpcService.call(feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION)<
            GetClaimSessionRPCReqData,
            GetClaimSessionRPCResData
        >({
            claimSessionKey,
        });
    }

    async fetchClaimSessionBuyerInfo(claimSessionKey: string) {
        return this.firebase.rpcService.call(feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_GET_CLAIM_SESSION_BUYER_INFO)<
            GetClaimSessionBuyerInfoRPCReqData,
            GetClaimSessionBuyerInfoRPCResData
        >({
            claimSessionKey,
        });
    }

    async updateClaimSession(data: UpdateClaimSessionRPCReqData) {
        return this.firebase.rpcService.call(feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_UPDATE_CLAIM_SESSION)<
            UpdateClaimSessionRPCReqData,
            UpdateClaimSessionRPCResData
        >(data);
    }

    async confirmClaimSession(claimSessionKey: string) {
        return this.firebase.rpcService.call(feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_CONFIRM_CLAIM_SESSION)<
            ConfirmClaimSessionRPCReqData,
            ConfirmClaimSessionRPCResData
        >({
            claimSessionKey,
        });
    }
}
