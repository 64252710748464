import {feReqRoutes} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {BaseService} from './BaseService';
import {
    ValidateGateCodeReqData,
    ValidateGateCodeResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/sellers/validateGateCodeReqTypes';

export class ClaimSessionService extends BaseService {
    async validateGateCode(gateCode: string) {
        return this.firebase.rpcService.call(feReqRoutes.API_SELLERS_SELLER_RELEASE_FLOW_VALIDATE_GATE_CODE)<
            ValidateGateCodeReqData,
            ValidateGateCodeResData
        >({
            gateCode,
        });
    }
}
