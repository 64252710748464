import {IMSTMap, Instance, types, getSnapshot} from 'mobx-state-tree';
import {ItemPlaceholderEnum} from '@joyrideautos/auction-core/src/types/MediaResource';
import {convertToObj} from '@joyrideautos/auction-utils/src/objectUtils';
import {ItemMediaStatuses} from '@joyrideautos/auction-core/src/types/ItemMedia';

export const ItemLoadingStatus = types.enumeration('ItemLoadingStatus', [
    ItemMediaStatuses.LOADING,
    ItemMediaStatuses.LOADED,
    ItemMediaStatuses.ERROR,
]);

export const ItemPlaceholderType = types.enumeration([
    ItemPlaceholderEnum.EXTRA,
    ItemPlaceholderEnum.VIDEO,
    ItemPlaceholderEnum.FRONT_PASSENGER_SIDE,
    ItemPlaceholderEnum.FRONT_DRIVER_SIDE,
    ItemPlaceholderEnum.REAR_DRIVER_SIDE,
    ItemPlaceholderEnum.REAR_PASSENGER_SIDE,
    ItemPlaceholderEnum.INTERIOR_DRIVER_SIDE,
    ItemPlaceholderEnum.INTERIOR_BACK_SEAT,
    ItemPlaceholderEnum.INTERIOR_ODOMETER,
    ItemPlaceholderEnum.MANUFACTURER_LABEL,
    ItemPlaceholderEnum.INTERIOR_ENGINE,
    ItemPlaceholderEnum.CATALYTIC_CONVENTER,
    ItemPlaceholderEnum.KEYS,
    ItemPlaceholderEnum.DAMAGE,
    ItemPlaceholderEnum.VIDEO,
    ItemPlaceholderEnum.OTHER,
]);

export const ItemMedia = types
    .model('ItemMedia', {
        bucket: types.string,
        contentType: types.string,
        name: types.string,
        url: types.string,
        metadata: types.map(types.string),
        idx: types.maybe(types.union(types.string, types.number)),
        uploadedAt: types.maybe(types.number),
        placeholderType: types.maybe(ItemPlaceholderType),
        a: types.maybe(types.string),
        status: types.optional(ItemLoadingStatus, ItemMediaStatuses.LOADED),
    })
    .views((self) => {
        return {
            get downloadUrl() {
                if (self.metadata.has('firebaseStorageDownloadTokens')) {
                    const token = self.metadata.get('firebaseStorageDownloadTokens');
                    return `${self.url}&token=${token}`;
                } else {
                    return self.url;
                }
            },
            get sourceUrl(): string | undefined {
                return self.metadata.get('sourceURL');
            },
            get idxNum() {
                if (!self.idx) {
                    return 0;
                }
                return typeof self.idx == 'string' ? parseInt(self.idx) : self.idx;
            },
        };
    });

export interface ItemMediaType extends Instance<typeof ItemMedia> {}

export const ItemVideo = ItemMedia.named('ItemVideo');

export interface ItemVideoType extends Instance<typeof ItemVideo> {}

export const ItemVideoWithId = ItemVideo.props({
    id: types.string,
}).named('ItemVideoWithId');

export type ItemVideoWithIdType = Instance<typeof ItemVideoWithId>;

export const ItemImage = ItemMedia.props({
    height: types.number,
    width: types.number,
}).named('ItemImage');

export interface ItemImageType extends Instance<typeof ItemImage> {}

export const ItemImageWithId = ItemImage.props({
    id: types.string,
}).named('ItemImageWithId');

export interface ItemImageWithIdType extends Instance<typeof ItemImageWithId> {}

type HasIdx = {
    idxNum: number;
};

function sortByIdx(x: HasIdx, y: HasIdx) {
    return x.idxNum - y.idxNum;
}

export const ItemImageMap = types
    .model('ItemImageMap', {
        original: types.maybe(types.map(ItemImage)),
        thumb_200: types.maybe(types.map(ItemImage)),
        full_4x3: types.maybe(types.map(ItemImage)),
        thumbnail_4x3: types.maybe(types.map(ItemImage)),
    })
    .preProcessSnapshot((snap) => {
        return (
            snap && {
                original: convertToObj(snap.original),
                thumb_200: convertToObj(snap.thumb_200),
                full_4x3: convertToObj(snap.full_4x3),
                thumbnail_4x3: convertToObj(snap.thumbnail_4x3),
            }
        );
    })
    .views((self) => {
        function mapToImageWithId(values?: IMSTMap<typeof ItemImage>) {
            return Array.from(values ? values.entries() : [])
                .map(([key, value]) =>
                    ItemImageWithId.create({
                        ...getSnapshot(value),
                        id: key,
                    })
                )
                .sort(sortByIdx);
        }

        return {
            get originalImages() {
                return mapToImageWithId(self.original);
            },
            get thumbs200Images() {
                return mapToImageWithId(self.thumb_200);
            },
            get fullImages_4x3() {
                return mapToImageWithId(self.full_4x3);
            },
            get thumbnailImages_4x3() {
                return mapToImageWithId(self.thumbnail_4x3);
            },
        };
    });

export interface ItemImageMapType extends Instance<typeof ItemImageMap> {}

export const ItemVideoMap = types
    .model('ItemVideoMap', {
        video: types.maybe(types.map(ItemVideo)),
        video_4x3: types.maybe(types.map(ItemVideo)),
    })
    .views((self) => {
        function mapToVideoWithId(values?: IMSTMap<typeof ItemVideo>) {
            return Array.from(values ? values.entries() : [])
                .map(([key, value]) =>
                    ItemVideoWithId.create({
                        ...getSnapshot(value),
                        id: key,
                    })
                )
                .sort(sortByIdx);
        }

        return {
            get originalVideos() {
                return mapToVideoWithId(self.video);
            },
            get videos_4x3() {
                return mapToVideoWithId(self.video_4x3);
            },
        };
    });

export interface ItemVideoMapType extends Instance<typeof ItemVideoMap> {}

export type ItemImageAndVideoMediaType = ItemImageMapType & ItemVideoMapType;
