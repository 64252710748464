import {types, Instance, SnapshotIn, getSnapshot} from 'mobx-state-tree';
import {WinningBidDto} from '../WinningBid';
import {BaseItem} from './BaseItem';
import type {ItemType} from './Item';
import {PersistedItemStatusEnum} from '@joyrideautos/auction-core/src/types/ItemTypes';
import {FirestoreTimestamp} from './FirestoreTimestamp';
import {ItemImages, ItemPath} from '@joyrideautos/auction-core/src/types/Item';
import {
    ArchiveReasonEnum,
    SoldStatusMetas,
    PersistedItem as PersistedItemDto,
} from '@joyrideautos/auction-core/src/types/Item';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {ItemImageMap} from './ItemMedia';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';
import {AuctionPath} from '@joyrideautos/auction-core/src/types/AuctionOccurrence';

export const PersistedItemStatus = types.enumeration([
    PersistedItemStatusEnum.STORED,
    PersistedItemStatusEnum.CANDIDATE,
    PersistedItemStatusEnum.PUBLISHED,
    PersistedItemStatusEnum.SOLD,
    PersistedItemStatusEnum.UNSOLD,
    PersistedItemStatusEnum.PAID,
    PersistedItemStatusEnum.CLAIMED,
    PersistedItemStatusEnum.ARCHIVED,
]);

const StatusMetaModel = types.model('StatusMetaModel ', {
    reason: types.maybe(
        types.enumeration([...Object.values(ArchiveReasonEnum), ...Object.values(SoldStatusMetas), 'deleted'])
    ),
});

const BuyerInfo = types.model('BuyerInfo', {
    firstName: types.string,
    lastName: types.string,
    companyName: types.maybe(types.string),
    email: types.string,
    streetAddress: types.string,
    apartmentOrSuite: types.maybe(types.string),
    city: types.maybe(types.string),
    state: types.maybe(types.string),
    zip: types.maybe(types.string),
    phone: types.string,
});

export const PersistedItem = BaseItem.named('PersistedItem')
    .props({
        key: types.identifier,
        idx: types.maybeNull(types.number),
        regionId: types.maybe(types.string),
        auctionId: types.maybe(types.string),
        publishedAt: types.maybe(FirestoreTimestamp),
        storedAt: types.maybe(FirestoreTimestamp),
        candidateAt: types.maybe(FirestoreTimestamp),
        soldAt: types.maybe(FirestoreTimestamp),
        unsoldAt: types.maybe(FirestoreTimestamp),
        paidAt: types.maybe(FirestoreTimestamp),
        claimedAt: types.maybe(FirestoreTimestamp),
        archivedAt: types.maybe(FirestoreTimestamp),
        status: types.maybe(PersistedItemStatus),
        statusMeta: types.maybe(StatusMetaModel),
        result: types.maybe(WinningBidDto),
        currentResult: types.maybe(WinningBidDto),
        totalBids: types.maybeNull(types.number),
        buyerInfo: types.maybe(BuyerInfo),
        markedAsUnsoldBy: types.maybe(types.string),
        forbiddenAuctionsIds: types.array(types.string),
    })
    .views((self) => ({
        get isFailedDeposit() {
            return self.statusMeta?.reason === SoldStatusMetas.DEPOSIT_FAILED;
        },
        get auctionPath(): AuctionPath | undefined {
            if (
                self.status === PersistedItemStatusEnum.STORED ||
                self.status === PersistedItemStatusEnum.CANDIDATE ||
                !self.regionId ||
                !self.auctionId
            ) {
                return undefined;
            }
            const {regionId, auctionId} = self;
            return {regionId, auctionId};
        },
        get itemPath(): ItemPath | undefined {
            if (
                self.status === PersistedItemStatusEnum.STORED ||
                self.status === PersistedItemStatusEnum.CANDIDATE ||
                !self.regionId ||
                !self.auctionId
            ) {
                return undefined;
            }
            const {regionId, auctionId, itemId} = self;
            return {regionId, auctionId, itemId: String(itemId)};
        },
        get snapshot() {
            return getSnapshot<WithKey<PersistedItemDto>>(self);
        },
    }));

export interface PersistedItemType extends Instance<typeof PersistedItem> {}

export interface PersistedItemSnapshotIn extends SnapshotIn<typeof PersistedItem> {}

export function isAuctionItem(item: ItemType | PersistedItemType | undefined): item is ItemType {
    return item != null && (item as ItemType).persistenceKey !== undefined;
}

function mapImage(image?: ItemImages) {
    try {
        const model = image && ItemImageMap.create(image as any);
        return model;
    } catch (e: any) {
        console.log('failed to map image', e.message, image);
        return undefined;
    }
}

export function mapInventoryItemDtoToModel(item: WithKey<PersistedItemDto>): PersistedItemType {
    const image = mapImage(item.image);
    // TODO: (future) fix all 'any'
    return PersistedItem.create({
        ...item,
        feePrice: (item.feePrice ?? null) as any,
        image: image && getSnapshot(image),
        archiveReason: item.archiveReason,
        status: item.status as any,
        statusMeta: item.statusMeta,
        result: item.result as any,
        currentResult: item.currentResult as any,
    });
}

export function toPersistedItemDto<T>(item: PersistedItemType & T): PersistedItemDto & T {
    return cast<PersistedItemDto & T>(item);
}

export function toPartialPersistedItemDto(item: Partial<PersistedItemType>): Partial<PersistedItemDto> {
    return item as Partial<PersistedItemDto>;
}
