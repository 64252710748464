import {Instance, types} from 'mobx-state-tree';
import {
    PaymentStatusEnum,
    PaymentTypeEnum,
    ItemPayment as ItemPaymentDto,
    ItemInvoice as ItemInvoiceDto,
    PaymentStatistics as PaymentStatisticsDto,
} from '@joyrideautos/auction-core/src/types/Payments';

export const PaymentInfo = types.model('PaymentInfo', {
    amount: types.number,
    currency: types.string,
    amount_received: types.maybe(types.number),
    status: types.string,
    created: types.number,
    canceled_at: types.maybeNull(types.number),
    cancellation_reason: types.maybeNull(types.string),
    client_secret: types.string,
    confirmation_method: types.string,
    customer: types.string,
    payment_method: types.maybe(types.string),
    description: types.maybeNull(types.string),
    last_payment_error: types.maybeNull(types.string),
    statement_descriptor: types.maybeNull(types.string),
});

export const ItemPaymentStatus = types.enumeration([
    PaymentStatusEnum.CANCELED,
    PaymentStatusEnum.PENDING,
    PaymentStatusEnum.PROCESSING,
    PaymentStatusEnum.REQUIRES_ACTION,
    PaymentStatusEnum.REQUIRES_CAPTURE,
    PaymentStatusEnum.REQUIRES_CONFIRMATION,
    PaymentStatusEnum.REQUIRES_PAYMENT_METHOD,
    PaymentStatusEnum.SUCCEEDED,
    PaymentStatusEnum.ERROR,
]);

export const ItemPaymentEnum = types.enumeration([...Object.values(PaymentTypeEnum)]);

export const ItemPayment = types.model('ItemPayment', {
    uid: types.string,
    regionId: types.string,
    auctionId: types.string,
    success: types.boolean,
    amount: types.number,
    paymentInfo: types.maybe(PaymentInfo),
    itemId: types.union(types.number, types.string),
    sellerId: types.maybe(types.string),
    timestamp: types.string,
    status: types.maybe(ItemPaymentStatus), // maybe undefined for old payments
    error: types.maybe(types.frozen()),
    invoiceId: types.maybe(types.string),
    type: types.maybe(ItemPaymentEnum),
});

export interface ItemPaymentType extends Instance<typeof ItemPayment> {}

export const ItemInvoice = types.model('ItemInvoice', {
    uid: types.string,
    regionId: types.string,
    auctionId: types.string,
    success: types.boolean,
    amount: types.number,
    paymentInfo: types.maybe(PaymentInfo),
    itemIds: types.array(types.union(types.string, types.number)),
    sellerId: types.maybe(types.string),
    timestamp: types.string,
    status: ItemPaymentStatus,
    error: types.maybe(types.frozen()),
    invoiceId: types.string,
});

export interface ItemInvoiceType extends Instance<typeof ItemInvoice> {}

export const PaymentStatistics = types.model('PaymentStatistics', {
    success: types.maybe(types.number),
    failed: types.maybe(types.number),
});

export interface PaymentStatisticsType extends Instance<typeof PaymentStatistics> {}

export function fromItemPaymentDto(itemPayment: ItemPaymentDto): ItemPaymentType {
    return itemPayment as ItemPaymentType;
}

export function fromItemInvoiceDto(itemInvoice: ItemInvoiceDto): ItemInvoiceType {
    return itemInvoice as ItemInvoiceType;
}

export function fromPaymentStatisticsDto(itemPayment: PaymentStatisticsDto): PaymentStatisticsType {
    return itemPayment as PaymentStatisticsType;
}
