import {getParent, IAnyType, IModelType, Instance, types} from 'mobx-state-tree';
import {Validator} from '../validators/Validators';
import {InputFieldModel} from './InputFieldModel';
import {DropdownModelFactory, withDropdownStatus} from './DropdownModel';
import {CheckboxModel, ICheckboxModel} from './CheckboxModel';
import {LoadingStatus} from '../../utils/LoadingStatus';
import RootStoreAwareViewModel, {RootStoreAwareViewModelType} from '../../stores/rootStore/RootStoreAwareViewModel';
import {InputOTPFieldModel} from './InputOTPFieldModel';
import {RadioButtonValue, RadioGroupModel} from './RadioGroupModel';

type Events<T> = {
    onChange?: (newValue: T, oldValue: T) => void;
};

export interface DropdownOptions<T extends IAnyType, TEXT extends string> {
    validator?: Validator<any>;
    errorLoadingMessage?: string;
    preloadOptions?: boolean;
    toOption: (option?: T['Type']) => {key: string; text: TEXT | undefined; value: string | number | undefined};
    getOptions: () => T['Type'][] | Promise<T['Type'][]>;
}

type GetDropdownOptions<T extends IAnyType, TEXT extends string> = (
    self: RootStoreAwareViewModelType
) => DropdownOptions<T, TEXT>;

export interface InputOptions {
    validator?: Validator<string>;
    updateOnChange?: boolean;
    selectOnFocus?: boolean;
    forceFormatOnChange?: boolean;
    updateOnBlur?: boolean;
    events?: Events<string>;
}

export interface InputOTPOptions {
    validator?: Validator<(string | undefined | null)[]>;
    updateOnChange?: boolean;
    selectOnFocus?: boolean;
    forceFormatOnChange?: boolean;
    updateOnBlur?: boolean;
    events?: {value: Events<string>; chars: Events<(string | undefined)[]>};
}

type InputFiledViewModelDelegate<T> = {
    beforeSetValue?: (oldValue: T, isValid?: boolean) => void;
    afterSetValue?: (newValue: T, isValid?: boolean) => void;
};

export interface CheckboxOptions {
    validator?: Validator<any>;
    events?: Events<boolean>;
}

type CheckboxFieldViewModelDelegate = {
    afterSetValue: (newValue: boolean) => void;
};

const fieldModels = {
    input(initialValue: string, options?: InputOptions | ((self: RootStoreAwareViewModelType) => InputOptions)) {
        const model = types
            .model('InputFiledViewModel', {
                value: initialValue,
            })
            .volatile(() => ({
                delegate: undefined as InputFiledViewModelDelegate<string> | undefined,
                loadingStatus: new LoadingStatus(),
            }))
            .views((self) => ({
                get parent(): Instance<typeof RootStoreAwareViewModel> {
                    return getParent(self);
                },
                get events() {
                    if (!this.options) {
                        return;
                    }
                    return this.options?.events;
                },
                get options() {
                    if (!options) {
                        return;
                    }
                    if (typeof options == 'function') {
                        return options(this.parent);
                    }
                    return options;
                },
                get validator() {
                    return this.options?.validator;
                },
                get isEmpty(): boolean {
                    return self.value.length === 0;
                },
            }))
            .actions((self) => {
                const {validator} = self.options ?? {};
                return {
                    setValue(value?: string) {
                        const oldValue = self.value;
                        const isValidBefore = validator && validator(self.value) == null;
                        self.delegate?.beforeSetValue && self.delegate?.beforeSetValue(oldValue, isValidBefore);

                        self.value = value || '';

                        const isValidAfter = validator && validator(self.value) == null;
                        self.delegate?.afterSetValue && self.delegate?.afterSetValue(self.value, isValidAfter);
                        self.events?.onChange && self.events?.onChange(self.value, oldValue);
                    },
                    clear() {
                        self.value = '';
                    },
                    reset() {
                        self.value = initialValue;
                    },
                };
            })
            .views((self) => {
                return {
                    get connector() {
                        return new InputFieldModel(
                            self.options?.validator,
                            self.options?.updateOnChange,
                            self.options?.selectOnFocus,
                            self.options?.forceFormatOnChange,
                            self.options?.updateOnBlur
                        );
                    },
                    get fieldModel() {
                        return this.connector.connect(self.value, self.setValue);
                    },
                    get isValid() {
                        return this.fieldModel.isValid;
                    },
                    get validationError() {
                        return this.fieldModel.message;
                    },
                    getValidationError(checkInitialValue = false) {
                        if (!self.options?.validator) {
                            return undefined;
                        }
                        if (!checkInitialValue && self.value === initialValue) {
                            return undefined;
                        }
                        return self.options?.validator(self.value);
                    },
                };
            })
            .actions((self) => {
                return {
                    afterCreate() {
                        // TODO: (Future) inject logger from rootStore
                        // logger.log('initial value is', self.value);
                    },
                };
            });
        return types.optional(model, {});
    },
    inputOTP(
        initialValue: string,
        charsLength: number,
        options?: InputOTPOptions | ((self: RootStoreAwareViewModelType) => InputOTPOptions)
    ) {
        const model = types
            .model('InputOTPFiledViewModel', {
                value: initialValue,
                chars: types.optional(
                    types.array(types.maybe(types.string)),
                    initialValue ? initialValue.split('') : Array.from<string>({length: charsLength}).fill('')
                ),
            })
            .volatile(() => ({
                delegate: undefined as InputFiledViewModelDelegate<string[]> | undefined,
                loadingStatus: new LoadingStatus(),
            }))
            .views((self) => ({
                get length() {
                    return charsLength;
                },
                get parent(): Instance<typeof RootStoreAwareViewModel> {
                    return getParent(self);
                },
                get events() {
                    if (!this.options) {
                        return;
                    }
                    return this.options?.events;
                },
                get options() {
                    if (!options) {
                        return;
                    }
                    if (typeof options == 'function') {
                        return options(this.parent);
                    }
                    return options;
                },
                get validator() {
                    return this.options?.validator;
                },
                get isEmpty(): boolean {
                    return self.chars.every((value) => !value);
                },
                get hasEmptyValues() {
                    return self.chars.some((value) => !value);
                },
            }))
            .actions((self) => {
                return {
                    setValue(value?: string) {
                        const oldValue = self.value;
                        self.value = value || '';
                        this.setChars(value);
                        self.events?.value.onChange && self.events?.value.onChange(self.value, oldValue);
                    },
                    setChars(value?: string) {
                        self.chars.replace(
                            value ? value.split('') : Array.from<string>({length: charsLength}).fill('')
                        );
                    },
                    setCharAt(value: string | undefined, position: number) {
                        self.chars[position] = value;
                    },
                    clear() {
                        self.value = '';
                        self.chars.replace(Array.from<string>({length: charsLength}).fill(''));
                    },
                    reset() {
                        self.value = initialValue;
                        self.chars.replace(
                            initialValue ? initialValue.split('') : Array.from<string>({length: charsLength}).fill('')
                        );
                    },
                };
            })
            .views((self) => {
                return {
                    get connector() {
                        return new InputOTPFieldModel(
                            self.options?.validator,
                            self.options?.updateOnChange,
                            self.options?.forceFormatOnChange
                        );
                    },
                    get fieldModel() {
                        return this.connector.connect(self.value, self.setValue, self.setCharAt);
                    },
                    get isValid() {
                        return this.fieldModel.isValid;
                    },
                    get validationError() {
                        return this.fieldModel.message;
                    },
                    getValidationError(checkInitialValue = false) {
                        if (!self.options?.validator) {
                            return undefined;
                        }
                        if (!checkInitialValue && self.value === initialValue) {
                            return undefined;
                        }
                        return self.options?.validator(self.chars);
                    },
                };
            })
            .actions((self) => {
                return {
                    afterCreate() {
                        // when the value is set from a snapshot we need to manually update chars
                        self.setChars(self.value);
                    },
                };
            });
        return types.optional(model, {});
    },
    dropdown<T extends IAnyType, TEXT extends string = string>(
        optionModel: T,
        getOptions: GetDropdownOptions<T, TEXT>,
        defaultOption?: T['Type'],
        events?: (parent: any) => {onChange: (newValue: T['Type'], oldValue: T['Type']) => void}
    ) {
        const model = types
            .model('DropdownFieldViewModel', {
                option: types.maybe(optionModel),
            })
            .views((self) => ({
                get parent(): RootStoreAwareViewModelType {
                    return getParent(self);
                },
                get dropdownOptions(): DropdownOptions<T, TEXT> {
                    return getOptions(this.parent);
                },
                get errorLoadingMessage() {
                    return this.dropdownOptions.errorLoadingMessage;
                },
                get validator() {
                    return this.dropdownOptions.validator;
                },
                get preloadOptions() {
                    return this.dropdownOptions.preloadOptions;
                },
                get getOptions() {
                    return this.dropdownOptions.getOptions;
                },
                get toOption() {
                    return this.dropdownOptions.toOption;
                },
                get events() {
                    return events && events(this.parent);
                },
            }))
            .actions((self) => ({
                setOption(option?: T['Type']) {
                    const oldValue = self.option;
                    self.option = option || defaultOption;
                    self.events?.onChange(self.option, oldValue);
                },
                clear() {
                    self.option = defaultOption;
                },
            }))
            .views((self) => {
                return {
                    get connector() {
                        return new DropdownModelFactory<T['Type']>(
                            withDropdownStatus(self.getOptions),
                            (option) => {
                                return self.toOption(option);
                            },
                            self.errorLoadingMessage,
                            self.validator,
                            self.preloadOptions
                        );
                    },
                    get fieldModel() {
                        return this.connector.connect(self.option, self.setOption);
                    },
                    get text() {
                        return self.toOption(self.option).text;
                    },
                    get value(): T['Type'] {
                        return self.toOption(self.option).value;
                    },
                    get isValid() {
                        if (!self.validator) {
                            return true;
                        }
                        return self.validator(self.option);
                    },
                };
            });
        return types.optional(model, {option: defaultOption});
    },
    checkbox(
        initialValue = false,
        options?: CheckboxOptions | ((self: RootStoreAwareViewModelType) => CheckboxOptions)
    ) {
        const model = types
            .model('CheckboxFieldViewModel', {
                value: initialValue,
            })
            .volatile(() => ({
                delegate: undefined as CheckboxFieldViewModelDelegate | undefined,
                loadingStatus: new LoadingStatus(),
            }))
            .views((self) => ({
                get parent(): RootStoreAwareViewModelType {
                    return getParent(self);
                },
                get options() {
                    if (!options) {
                        return;
                    }
                    if (typeof options == 'function') {
                        return options(this.parent);
                    }
                    return options;
                },
                get validator() {
                    return this.options?.validator;
                },
                get events() {
                    if (!this.options) {
                        return;
                    }
                    return this.options?.events;
                },
            }))
            .actions((self) => ({
                setValue(value = false, options?: {notify?: boolean}) {
                    const notify = options?.notify ?? true;
                    self.value = value;
                    if (notify) {
                        self.delegate?.afterSetValue(value);
                        self.events?.onChange && self.events?.onChange(self.value, !self.value);
                    }
                },
                toggle() {
                    self.value = !self.value;
                    self.delegate?.afterSetValue(self.value);
                    self.events?.onChange && self.events?.onChange(self.value, !self.value);
                },
                setDelegate(delegate?: CheckboxFieldViewModelDelegate) {
                    self.delegate = delegate;
                },
                clear() {
                    self.value = initialValue;
                },
            }))
            .views((self) => {
                const connector = new CheckboxModel(self.options?.validator);
                return {
                    get fieldModel() {
                        return connector.connect(self.value, (value) => self.setValue(value));
                    },
                };
            });
        return types.optional(model, {});
    },
    radio<VALUE extends string, PARENT extends RootStoreAwareViewModelType = RootStoreAwareViewModelType>(
        initialValue: VALUE,
        getOptions: (self: PARENT) => RadioButtonValue[]
    ) {
        return types.optional(
            types
                .model('RadioFieldViewModel', {
                    _value: types.optional(types.string, initialValue),
                })
                .views((self) => ({
                    get parent(): PARENT {
                        return getParent<any>(self);
                    },
                    get value(): VALUE {
                        return self._value as VALUE;
                    },
                }))
                .actions((self) => ({
                    setValue(value = '') {
                        self._value = value;
                    },
                }))
                .views((self) => {
                    return {
                        get connector() {
                            return new RadioGroupModel(getOptions(self.parent));
                        },
                        get fieldModel() {
                            return this.connector.connect(self.value, (value) => self.setValue(value));
                        },
                    };
                }),
            {}
        );
    },
    // TODO: (future) implement all other field models
};

export default fieldModels;

interface CheckboxFieldModel {
    value: boolean;
    setValue: (value: boolean) => void;
    fieldModel: ICheckboxModel;
}

export interface CheckboxFieldModelType extends Instance<IModelType<any, CheckboxFieldModel, any, any>> {}
