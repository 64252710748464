import {LongTimeoutResult, setLongTimeout} from '@joyrideautos/auction-utils/src/setLongTimeout';
import {
    ListingAuctionSMPresenterType,
    ListingAuctionStateMachineContext,
    SequenceAuctionSMPresenterType,
    SequenceAuctionStateMachineContext,
} from './types';
import {IAnyStateTreeNode, IJsonPatch, onPatch, onSnapshot} from 'mobx-state-tree';
import {AuctionPauseType} from '@joyrideautos/auction-core/src/types/AuctionTypes';
import {ClockModelType} from '../../utils/Clock';
import {when} from 'mobx';

export function executeAt(handler: (...args: any[]) => void, time: number): LongTimeoutResult | undefined {
    const currentTime = Date.now();

    if (currentTime > time) {
        return;
    }

    return setLongTimeout(handler, time - currentTime + 100) as any;
}

export function startCountDown(
    presenter: SequenceAuctionSMPresenterType | ListingAuctionSMPresenterType,
    endDate: number,
    callback?: () => void
) {
    if (endDate > Date.now()) {
        presenter.countDownTimer.start(endDate, callback);
        return () => {
            presenter.countDownTimer.clear();
        };
    } else {
        presenter.countDownTimer.reset();
        callback && callback();
    }
}

export const subscribeToClockOffsetChange = (clock: ClockModelType) => (callback: () => void) => {
    return onSnapshot(clock, () => {
        callback();
    });
};

export function subscribeToAuctionPauseChange(
    context: SequenceAuctionStateMachineContext | ListingAuctionStateMachineContext,
    callback: (pauseType: AuctionPauseType) => void
) {
    const auction = context.presenter.auction! as IAnyStateTreeNode;
    return onPatch(auction, (patch: IJsonPatch) => {
        if (patch.path.endsWith('paused')) {
            const value = patch.value as AuctionPauseType;
            callback(value);
        }
    });
}

export function isNotEndedWinningBid(context: SequenceAuctionStateMachineContext) {
    const winningBid = context.presenter.currentWinningBid;
    return !winningBid?.ended;
}

export async function subscribeToWinningBidList(
    context: SequenceAuctionStateMachineContext,
    onEndedChanged: (ended: boolean) => void,
    onExpirationChanged: () => void
) {
    await when(() => !!context.presenter.winningBidContainer);
    return onPatch(context.presenter.winningBidContainer!, (patch: IJsonPatch) => {
        const winningBid = context.presenter.currentWinningBid;
        context.logger?.log(JSON.stringify({winningBid, value: patch.value, path: patch.path}));
        if (winningBid) {
            if (patch.path.endsWith(`${winningBid.itemKey}/ended`)) {
                context.logger?.log('winning bid: ended changed', patch.value);
                onEndedChanged(patch.value === true);
            } else if (patch.path.endsWith(`${winningBid.itemKey}/expiration`)) {
                context.logger?.log('winning bid: expiration changed', patch.value);
                onExpirationChanged();
            }
        } else {
            context.logger?.log('winning bid: expiration changed', patch.value);
            onExpirationChanged();
        }
    });
}

export async function checkItemBiddingEnded(context: SequenceAuctionStateMachineContext): Promise<boolean> {
    const auction = context.presenter.auction;
    const item = context.presenter.currentItem;
    if (auction && item) {
        return context.services.bidService.checkItemBiddingEnded(auction.regionId, auction.auctionId, item!.key);
    } else {
        return false;
    }
}
