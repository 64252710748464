import * as s from 'superstruct';
import {FirestoreTimestamp} from './validations/itemValidation';
import {ObjectPath} from '@joyrideautos/auction-utils/src/types';

export type Nullable<T> = {[P in keyof T]: T[P] | null};

export type NullOrUndef<T> = T | null | undefined;

export type WithKey<T> = T & {key: string; persistenceKey?: string; parentKey?: string};
export type WithOptionalKey<T> = T & {key?: string};
export type KeyValuePair<T> = {key: string; value: T; parentKey?: string};

export const mapValuesObjectToArrayWithKey = <T>(values: Record<string, T>): WithKey<T>[] => {
    return Object.keys(values).reduce<WithKey<T>[]>((acc, key) => {
        acc.push({key, ...values[key]});
        return acc;
    }, []);
};

export const mapKeyValueToWithKey = <T>({key, parentKey, value}: KeyValuePair<T>): WithKey<T> => ({
    ...value,
    key,
    parentKey,
});

export const mapWithKeyToKeyValue = <T>({key, parentKey, ...value}: WithKey<T>): KeyValuePair<T> => ({
    key,
    parentKey,
    value: value as T,
});

export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type StringKeyed<T> = {[key: string]: T};

// Remove types from T that are assignable to U
export type Diff<T, U> = T extends U ? never : T;
// Remove types from T that are not assignable to U
export type Filter<T, U> = T extends U ? T : never;

export type DummyFunc = () => void;

export type Timestamp = s.Infer<typeof FirestoreTimestamp>;

export type ExtractParams<F extends (...args: any[]) => any> = (
    ...args: Extract<Parameters<F>, readonly any[]>
) => ReturnType<F>;

export interface ListOptions<T extends object> {
    pageSize?: number;
    pageOffset?: number;
    pageToken?: string | number;
    orderBy?: [ObjectPath<T>, 'asc' | 'desc'][];
}

export type CacheWithPromise<T> = Map<string, Promise<WithKey<T> | undefined>>;
export type CacheWithKeyValuePromise<T> = Map<string, Promise<KeyValuePair<T> | undefined>>;
