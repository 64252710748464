import * as s from 'superstruct';
import {WinningBidValidation} from './winningBidValidation';
import {persistedItemStatuses} from '../ItemTypes';
import {idValidation, PositiveNumber} from './commonValidation';
import {ArchiveReasonEnum} from '../Item';

// WARNING! in case you need to change max length, it requires altering column in pg
export const PERSISTED_ITEM_FIELDS_MAX_LENGTH = {
    BODY: 64,
    EXTERIOR_COLOR: 32,
    AUCTION_CONDITIONS: 128,
    DOCUMENTATION: 64,
    DOCUMENTATION_TYPE: 64,
    ENGINE_TYPE: 128,
    KEY_STATUS: 32,
    VIN: 24,
    YEAR: 8,
    MAKE: 32,
    MODEL: 64,
    START_CODE: 32,
    ODOMETER: 8,
    FUEL_TYPE: 32,
    SELLER_SEQUENCE: 32,
    LICENSE_EXPIRATION: 32,
    STATUS: 32,
    TRANS_ID: 64,
    ARCHIVE_REASON: 32,
    MARKED_AS_ARCHIVED_BY: 32,
    SELLER_NOTES: 4096, // in pg it has no limitation, just using reasonable max length
    CUSTOM_FIELD_VALUE: 50, // in pg, there is separate table for custom fields

    // Fields below don't exist in pg, but add restriction to avoid dummy data
    LICENSE_PLATE: 32,
    LICENSE_PLATE_STATE: 32,
    RUNS: 64,
    CATALYTIC_CONVERTER: 64,
    DOCUMENTATION_STATE: 64,
    DRIVE_TRAIN: 64,
};

export const CodeAndName = s.type({
    code: s.union([s.string(), s.number()]),
    name: s.optional(s.string()),
    displayName: s.optional(s.string()),
    active: s.optional(s.boolean()),
});

/*
 * WARNING:
 * BE definition
 * {
 *   _nanoseconds: PositiveNumber
 *   _seconds: PositiveNumber
 * }
 * FE definition:
 * {
 *   nanoseconds: PositiveNumber
 *   seconds: PositiveNumber
 * }
 *  */
export const FirestoreTimestamp = s.object({
    _nanoseconds: PositiveNumber,
    _seconds: PositiveNumber,
});

export const FeePriceValidation = s.type({
    amount: PositiveNumber,
    feeType: s.enums(['key', 'other']),
    description: s.optional(s.string()),
    updatedAt: s.optional(FirestoreTimestamp),
    feeUpdatedAt: s.optional(FirestoreTimestamp),
    feeUpdatedBy: s.optional(s.string()),
});

export const ItemInfoValidation = s.type({
    auctionConditions: s.optional(s.string()),
    documentation: s.optional(s.string()),
    documentationState: s.optional(s.string()),
    documentationType: s.optional(s.string()),
    keyStatus: s.optional(s.string()),
    engineType: s.optional(s.union([CodeAndName, s.string()])),
    numCylinders: s.optional(PositiveNumber),
    vehicleMakeModel: s.optional(s.string()),
    model: s.string(),
    make: s.string(),
    vin: s.string(),
    isNonStandardVin: s.boolean(),
    year: s.optional(PositiveNumber),
    odometer: s.optional(PositiveNumber),
    startCode: s.optional(s.string()),
    body: s.optional(s.string()),
    exteriorColor: s.optional(s.string()),
    sellerSequence: s.optional(s.string()),
    licensePlateState: s.optional(CodeAndName),
    licensePlate: s.optional(s.string()),
    licenseExpiration: s.optional(s.string()),
    transmission: s.optional(CodeAndName),
    drivetrain: s.optional(CodeAndName),
    updatedAt: s.optional(FirestoreTimestamp),
    updatedBy: s.optional(s.string()),
    catalyticConverter: s.optional(s.string()),
    fuelType: s.optional(s.string()),
});

export const ItemStatusTimestampValidation = s.type({
    storedAt: s.optional(FirestoreTimestamp),
    publishedAt: s.optional(FirestoreTimestamp),
    candidateAt: s.optional(FirestoreTimestamp),
    soldAt: s.optional(FirestoreTimestamp),
    unsoldAt: s.optional(FirestoreTimestamp),
    paidAt: s.optional(FirestoreTimestamp),
    claimedAt: s.optional(FirestoreTimestamp),
    archivedAt: s.optional(FirestoreTimestamp),
});

export const ItemValidation = s.assign(
    s.type({
        sellerId: s.union([s.string(), PositiveNumber]),
        sellerVehicleId: s.optional(idValidation),
        info: ItemInfoValidation,
        feePrice: s.optional(s.nullable(FeePriceValidation)),
        reservePrice: s.nullable(PositiveNumber),
        buyNowAmount: s.optional(s.nullable(PositiveNumber)),
        locationId: s.optional(s.string()),
        status: s.enums(persistedItemStatuses),
        idx: PositiveNumber,
        regionId: s.string(),
        auctionId: s.string(),
        result: s.optional(WinningBidValidation),
        image: s.optional(s.unknown()),
        imagesCount: s.optional(PositiveNumber),
        isAcceptingOffers: s.optional(s.boolean()),
        importedFrom: s.optional(s.string()),
        importGroupId: s.optional(s.string()),
    }),
    ItemStatusTimestampValidation
);

export const PersistedItemValidation = s.assign(
    s.type({
        sellerId: s.union([s.string(), PositiveNumber]),
        info: ItemInfoValidation,
        status: s.enums(persistedItemStatuses),
        statusMeta: s.optional(s.unknown()),
        sellerVehicleId: idValidation,
        itemId: idValidation,
        itemKey: s.optional(s.string()),
        feePrice: s.optional(s.nullable(FeePriceValidation)),
        reservePrice: s.optional(s.nullable(PositiveNumber)),
        buyNowAmount: s.optional(s.nullable(PositiveNumber)),
        locationId: s.optional(s.string()),
        regionId: s.optional(s.string()),
        auctionId: s.optional(s.string()),
        result: s.optional(WinningBidValidation),
        image: s.optional(s.unknown()),
        imagesCount: s.optional(PositiveNumber),
        strictAuctionDate: s.optional(s.boolean()),
        publishAtDate: s.optional(s.date()),
        requestedAuctionDate: s.optional(s.date()),
        transId: s.optional(s.string()),
        archiveReason: s.optional(s.enums([ArchiveReasonEnum.RELEASED, ArchiveReasonEnum.RELISTED])),
        relisted: s.optional(s.boolean()),
        isAcceptingOffers: s.optional(s.boolean()),
        importedFrom: s.optional(s.string()),
        importGroupId: s.optional(s.string()),
        sellerNotes: s.optional(s.string()),
        impoundFees: s.optional(s.any()),
        customFields: s.optional(s.any()),
    }),
    ItemStatusTimestampValidation
);

export const InventoryErrorCodes = {
    EXISTING_SELLER_VEHICLE_ID: 'EXISTING_SELLER_VEHICLE_ID',
} as const;

export interface ExistingSellerVehicleIdErrorMeta {
    code: typeof InventoryErrorCodes.EXISTING_SELLER_VEHICLE_ID;
    data: {itemKeys: string[]};
}
