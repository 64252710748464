import {PutObjectCommand, PutObjectCommandInput, S3Client} from '@aws-sdk/client-s3';
import {CognitoIdentityClient} from '@aws-sdk/client-cognito-identity';
import {fromCognitoIdentity} from '@aws-sdk/credential-provider-cognito-identity';
import {AWSCredentialsProvider, AwsErrorCodeEnum, Progress} from '@joyrideautos/ui-services/src/aws/types';
import {Upload} from './Upload';
import {DefaultItemPlaceholder} from '@joyrideautos/auction-core/src/types/MediaResource';
import {
    createFERoutingService,
    FERequestHandler,
    feReqRoutes,
} from '@joyrideautos/auction-core/src/services/FERoutingService';
import RoutingService from '@joyrideautos/auction-core/src/services/RoutingService';
import {DownloadBinaryParams} from '@joyrideautos/ui-services/src/services/mediaService/types';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';

const ONE_MB = 1024 * 1024;

const DEFAULT_CACHE_CONTROL = 'max-age=86400';
const UPLOAD_QUEUE_SIZE = 4; // 4 is the default value in the library (@aws-sdk/lib-storage)
const UPLOAD_PART_SIZE_IN_MB = 5 * ONE_MB; // 5MB is the default (and minimum) value in library (@aws-sdk/lib-storage)

export interface MetadataType {
    db: {
        [key: string]: string;
    };
    file: {
        [key: string]: string;
    };
    uploadedAt?: number;
    placeholderType?: string;
}

export const prepareUploadFileData =
    (appConfig: AppConfig, {includeRequest} = {includeRequest: true}) =>
    (
        param: DownloadBinaryParams
    ): {
        destination: string;
        Bucket: string;
        contentType: string;
        index: number;
        metadata: MetadataType;
        request?: {headers: {[key: string]: string}; data: any};
    } => {
        if (!appConfig.awsConfig.privateBucketName) {
            throw Error('Missing private bucket name');
        }
        const {binary, contentType, destination, index, placeholderType} = param;

        const metadata: MetadataType = {
            db: {},
            file: {'x-amz-meta-source-url': 'BINARY'},
            uploadedAt: Date.now(),
            placeholderType,
        };

        let request;
        if (includeRequest) {
            request = {
                headers: {
                    'content-type': contentType,
                },
                data: binary,
            };
        }

        return {destination, Bucket: appConfig.awsConfig.privateBucketName, contentType, index, metadata, request};
    };

function isValidContentType(mimetype: string) {
    if (!mimetype || (!mimetype.startsWith('image') && !mimetype.startsWith('video'))) {
        return false;
    } else {
        return true;
    }
}

export class AWSClient {
    private s3: S3Client | undefined;
    private routingService: RoutingService<FERequestHandler>;

    constructor(
        private credentialsProvider: AWSCredentialsProvider,
        private appConfig: AppConfig,
        private logger?: Logger
    ) {
        this.routingService = createFERoutingService({
            projectId: appConfig.firebaseConfig.projectId,
            functionUrl: appConfig.emulatorsConfig.functionsUrl,
            microservices: appConfig.microservicesConfig,
        });
    }

    private async initializeS3() {
        if (!this.appConfig.awsConfig.region) {
            throw new Error('Missing AWS REGION');
        }

        if (!this.s3 || !this.credentialsProvider.isCredentialsValid) {
            this.logger?.log('initialize s3');
            const {identityId, token} = await this.credentialsProvider.getCredentials();
            this.s3 = new S3Client({
                region: this.appConfig.awsConfig.region,
                credentials: fromCognitoIdentity({
                    client: new CognitoIdentityClient({region: this.appConfig.awsConfig.region}),
                    identityId,
                    logins: {
                        'cognito-identity.amazonaws.com': token,
                    },
                }),
            });
        }

        return this.s3;
    }

    private get privateBucketName() {
        if (!this.appConfig.awsConfig.privateBucketName) {
            throw Error('Missing private bucket name');
        }
        return this.appConfig.awsConfig.privateBucketName;
    }

    private async prepareUploadParams(params: DownloadBinaryParams) {
        const {destination, index, metadata, request, contentType} = prepareUploadFileData(this.appConfig)(params);

        const callbackUrl = this.routingService.getUrlForRoute(feReqRoutes.AWS_WEBHOOK_UPLOAD_MEDIA);

        if (!isValidContentType(contentType)) {
            throw Error(`Invalid file type: ${contentType}`);
        }
        this.logger?.log({message: callbackUrl});

        return {
            Bucket: this.privateBucketName,
            Key: destination,
            Body: request?.data,
            CacheControl: DEFAULT_CACHE_CONTROL,
            ContentType: contentType,
            Metadata: {
                ...metadata.file,
                'x-amz-meta-idx': String(index),
                'x-amz-meta-placeholder-type': metadata.placeholderType || DefaultItemPlaceholder,
                'x-amz-meta-media-convert-callback-url': callbackUrl,
            },
        };
    }

    async uploadToS3(params: DownloadBinaryParams) {
        this.logger?.log('uploadToS3', params);
        const s3 = await this.initializeS3();

        let uploadParams: PutObjectCommandInput;
        try {
            uploadParams = await this.prepareUploadParams(params);
        } catch (e: any) {
            this.logger?.log(e);
            throw {
                message: `Error preparing media item: ${e.message}`,
                index: params.index,
                code: AwsErrorCodeEnum.WRONG_TYPE,
            };
        }

        try {
            await s3.send(new PutObjectCommand(uploadParams));
            return {Bucket: this.privateBucketName, index: params.index};
        } catch (e: any) {
            this.logger?.log(e);
            throw {
                message: `Error uploading media item to s3: ${e.message}`,
                index: params.index,
                code: AwsErrorCodeEnum.NETWORK_ERROR,
            };
        }
    }

    async multipartUploadToS3(
        params: DownloadBinaryParams,
        onProgress: (progress: Progress) => void,
        cancelable: (onCancel: () => Promise<void>) => void
    ) {
        const s3 = await this.initializeS3();

        let uploadParams: PutObjectCommandInput;
        try {
            uploadParams = await this.prepareUploadParams(params);
        } catch (e: any) {
            this.logger?.log(e);
            throw {
                message: `Error of preparing media item: ${e.message}`,
                index: params.index,
                code: AwsErrorCodeEnum.WRONG_TYPE,
            };
        }

        this.logger?.log('multipart upload params', uploadParams);

        try {
            const upload = new Upload(s3, {
                queueSize: UPLOAD_QUEUE_SIZE, // at the moment concurrent upload is not implemented
                partSize: UPLOAD_PART_SIZE_IN_MB,
            });

            await upload.start(uploadParams, onProgress, cancelable);
            return {Bucket: this.privateBucketName, index: params.index};
        } catch (e: any) {
            this.logger?.log(e);
            throw {
                message: `Error of multipart uploading media item to s3: ${e.message}`,
                index: params.index,
                code: AwsErrorCodeEnum.NETWORK_ERROR,
            };
        }
    }
}
