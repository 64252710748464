import {feReqRoutes} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {
    CreateEventDataRPCReqData,
    CreateEventDataRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/audit/createEventDataReqTypes';
import {BaseService} from './BaseService';

export class AuditService extends BaseService {
    async createEvent(data: CreateEventDataRPCReqData) {
        return await this.firebase.rpcService.call(feReqRoutes.API_AUDIT_CREATE_EVENT)<
            CreateEventDataRPCReqData,
            CreateEventDataRPCResData
        >(data);
    }
}
