import {flow, getParent, getSnapshot, IAnyType, isAlive, types} from 'mobx-state-tree';
import {RootStoreAwareViewModelType} from './stores/rootStore/RootStoreAwareViewModel';
import {IDisposer} from 'mobx-utils';
import {LoadingStatus, withStatus} from './utils/LoadingStatus';
import {logError} from '@joyrideautos/ui-logger/src/utils';

export interface PrimitivesOptions<T extends string | number> {
    action: () => Promise<T>;
}

export interface ValueOptions<T extends IAnyType> {
    action: () => Promise<T['Type'] | undefined>;
}

export const fetchAsyncModels = {
    value: <T extends IAnyType, PARENT extends RootStoreAwareViewModelType = RootStoreAwareViewModelType>(
        model: T,
        options: ValueOptions<T> | ((self: PARENT) => ValueOptions<T>)
    ) => {
        return types.optional(
            types
                .model('FetchAsyncModels.Value', {
                    value: types.maybe(model),
                })
                .volatile(() => ({
                    disposers: [] as IDisposer[],
                    loadingStatus: new LoadingStatus(),
                }))
                .views((self) => ({
                    get parent(): PARENT {
                        return getParent<any>(self);
                    },
                    get snapshot() {
                        return self.value && getSnapshot<T['SnapshotType']>(self.value);
                    },
                    get options() {
                        if (typeof options == 'function') {
                            return options(this.parent);
                        }
                        return options;
                    },
                }))
                .actions((self) => ({
                    setValue(value?: T['Type']) {
                        if (!isAlive(self)) return;
                        self.value = value;
                    },
                    reset() {
                        if (!isAlive(self)) return;
                        self.value = undefined;
                    },
                }))
                .actions((self) => {
                    return {
                        reload: flow<void, []>(function* () {
                            try {
                                const value = yield withStatus(self.loadingStatus)(() => self.options.action());
                                self.setValue(value);
                            } catch (error: any) {
                                self.loadingStatus.setError(error.message);
                            }
                        }),
                        afterCreate() {
                            this.reload().catch(logError());
                        },
                        beforeDestroy() {
                            self.disposers.forEach((d) => d());
                        },
                    };
                }),
            {value: undefined}
        );
    },
    primitives: <T extends string | number, PARENT extends RootStoreAwareViewModelType = RootStoreAwareViewModelType>(
        initialValue: T,
        options: PrimitivesOptions<T> | ((self: PARENT) => PrimitivesOptions<T>)
    ) => {
        return types.optional(
            types
                .model('FetchAsyncModels.Primitives')
                .volatile(() => ({
                    value: initialValue as typeof initialValue | null,
                    disposers: [] as IDisposer[],
                    loadingStatus: new LoadingStatus(),
                }))
                .views((self) => ({
                    get parent(): PARENT {
                        return getParent<any>(self);
                    },
                    get options() {
                        if (typeof options == 'function') {
                            return options(this.parent);
                        }
                        return options;
                    },
                }))
                .actions((self) => ({
                    setValue(value: typeof initialValue | null) {
                        if (!isAlive(self)) return;
                        self.value = value;
                    },
                    reset() {
                        if (!isAlive(self)) return;
                        self.value = null;
                    },
                }))
                .actions((self) => {
                    return {
                        reload: flow<void, []>(function* () {
                            try {
                                const value = yield withStatus(self.loadingStatus)(() => self.options.action());
                                self.setValue(value);
                            } catch (error: any) {
                                self.loadingStatus.setError(error.message);
                            }
                        }),
                        afterCreate() {
                            this.reload().catch(logError());
                        },
                        beforeDestroy() {
                            self.disposers.forEach((d) => d());
                        },
                    };
                }),
            {}
        );
    },
};
