import ValidatorJS from 'validatorjs';
import moment from 'moment';
import * as phoneNumberUtil from '../utils/phoneNumberUtil';
import validationErrorMessages from './validationErrorMessages';
import {DateFormat, DateTimeFormat, TimeFormat} from '../utils/formatters';
import {ImpoundFees, UNKNOWN_YEAR_VALUE} from '@joyrideautos/auction-core/src/types/Item';
import {toNumber} from '@joyrideautos/auction-utils/src/numberUtil';
import {PersistedItemType} from '../../types/item/PersistedItem';
import {SellerType} from '../../types/Seller';
import {calculateFees} from '@joyrideautos/auction-core/src/bidding/feeCalculation';
import {AuctionOccurrence} from '@joyrideautos/auction-core/src/types/AuctionOccurrence';
import {getSnapshot} from 'mobx-state-tree';
import {getActiveFeeSchedule} from '@joyrideautos/auction-core/src/bidding/utils';
import {SellerSettings} from '@joyrideautos/auction-core/src/types/Seller';
import {PlatformFeeSchedule, PlatformFeeTypes} from '@joyrideautos/auction-core/src/types/PlatformFeeSchedule';
import {lodashCountBy} from '@joyrideautos/auction-utils/src/arrayUtils';

const makeValidationErrorMessages = (fieldName: string) => ({
    required: `${fieldName} is required`,
    size: `${fieldName} should contain of :size characters`,
    email: `${fieldName} should be a valid email`,
    phoneNumber: `${fieldName} should be a valid phone number XXX-XXX-XXXX or XXXXXXXXXX`,
    integer: `${fieldName} should be a number`,
    positiveInteger: `${fieldName} should be a positive integer`,
    min: `${fieldName} must be at least :min.`,
});

export type Validator<T> = (value: T | null | undefined) => string | undefined;

const moneyRegexp = /^[0-9]+(\.[0-9]{1,2})?$/g;
const positiveIntegerRegexp = /^\d+$/;

ValidatorJS.register(
    'phoneNumber',
    function (value, requirement, attribute) {
        // requirement parameter defaults to null
        return phoneNumberUtil.isValid(String(value));
    },
    validationErrorMessages.phoneNumber
);

ValidatorJS.register(
    'positiveInteger',
    function (value, requirement, attribute) {
        return Boolean(String(value).match(positiveIntegerRegexp));
    },
    'Should be a positive integer'
);

ValidatorJS.register(
    'money',
    function (value, requirement, attribute) {
        // requirement parameter defaults to null
        return Boolean(String(value).match(moneyRegexp));
    },
    'Should be a valid money amount 10.00'
);

ValidatorJS.register(
    'min_amount',
    function (value, requirement, attribute) {
        const amount = Number.parseFloat(String(value));
        const minAmount = Number.parseFloat(String(requirement));
        return amount >= minAmount;
    },
    validationErrorMessages.minAmount
);

ValidatorJS.register(
    'date',
    function (value, args, attribute) {
        // requirement parameter defaults to null
        const date = moment(String(value), args || DateFormat, true);
        return date.isValid();
    },
    `Should be a valid date`
);

ValidatorJS.register(
    'time',
    function (value, args, attribute) {
        // requirement parameter defaults to null
        const date = moment(String(value), args || TimeFormat, true);
        return date.isValid();
    },
    `Should be a valid time`
);

ValidatorJS.register(
    'dateTime',
    function (value, args, attribute) {
        // requirement parameter defaults to null
        const date = moment(String(value), args || DateTimeFormat, true);
        return date.isValid();
    },
    `Should be a valid timestamp`
);

ValidatorJS.register(
    'inFuture',
    function (value, args, attribute) {
        // requirement parameter defaults to null
        return moment().isBefore(moment(String(value), args || DateFormat));
    },
    'The date must be in the future'
);

ValidatorJS.register(
    'year',
    function (value, args, attribute) {
        const yearAsNumber = toNumber(String(value));
        if (yearAsNumber === null) {
            return false;
        }
        return yearAsNumber >= 1800 || yearAsNumber === UNKNOWN_YEAR_VALUE;
    },
    'Year must be at least 1800'
);

export const NO_VIN = 'NO VIN';
export const STANDARD_VIN_REGEX = /^(([\w-]{14,17})|(NO VIN))$/g;
export const NON_STANDARD_VIN_REGEX = /^(([\w-]{1,20})|(NO VIN))$/;
const SELLER_NOTES_REGEX = /^[a-z0-9.,/'!()%&+-\s]+$/i;

ValidatorJS.register('sellerNotes', (value) => !!String(value).match(SELLER_NOTES_REGEX));

ValidatorJS.register('VIN', (value) => !!String(value).match(STANDARD_VIN_REGEX), validationErrorMessages.vin);

ValidatorJS.register(
    'nonStandardVIN',
    (value) => !!String(value).match(NON_STANDARD_VIN_REGEX),
    validationErrorMessages.nonStandardVin
);

export const DIRTY_VIN_ARRAY_REGEX = new RegExp(`(${NO_VIN})|([\\w-]+)`, 'g');

export function validateVIN(vin: string): boolean {
    // Check for NO VIN
    if (vin === NO_VIN) {
        return true;
    }

    // Check VIN length
    if (vin.length !== 17) {
        return false;
    }

    // Check that all characters are valid
    const validChars = '0123456789ABCDEFGHJKLMNPRSTUVWXYZ';
    if (!vin.split('').every((char) => validChars.includes(char))) {
        return false;
    }

    // Weighting factors for each position in the VIN
    const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

    // Map of characters to their integer values
    const valueMap: {[key: string]: number} = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        F: 6,
        G: 7,
        H: 8,
        J: 1,
        K: 2,
        L: 3,
        M: 4,
        N: 5,
        P: 7,
        R: 9,
        S: 2,
        T: 3,
        U: 4,
        V: 5,
        W: 6,
        X: 7,
        Y: 8,
        Z: 9,
        '0': 0,
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9,
    };

    // Calculate the weighted sum of the VIN characters
    let sum = 0;
    for (let i = 0; i < vin.length; i++) {
        const char = vin[i];
        const value = valueMap[char];
        const weight = weights[i];
        sum += value * weight;
    }

    // Check that the check digit is valid
    const checkDigit = vin[8];
    let expectedCheckDigit: string;
    if (checkDigit === 'X') {
        expectedCheckDigit = '10';
    } else {
        expectedCheckDigit = valueMap[checkDigit].toString();
    }
    return expectedCheckDigit === (sum % 11).toString();
}

// add a new validator (Validators.ts)
export type VinError = {vin: string; error: string | undefined};

function parseVINsFromInput<T>(value: T | null | undefined) {
    return String(value)
        .split(/,|;|\n/)
        .map((s) => s.trim())
        .filter((s) => s.length);
}

export function requiredVinsValidator<T>(
    rule: string | string[],
    reducer?: (vinErrors: VinError[], isEmpty: boolean) => string | undefined
): Validator<T> {
    return (value) => {
        const vins = parseVINsFromInput(value);
        const errors: VinError[] = [];
        for (const vin of vins) {
            if (lodashCountBy(vins)[vin] > 1 && vin !== 'NO VIN') {
                errors.push({vin, error: validationErrorMessages.noDuplicateVinsInArray});
            } else {
                const validator = new ValidatorJS({value: vin}, {value: rule}, {...validationErrorMessages});
                validator.check();
                errors.push({vin, error: validator.errors.first('value') || undefined});
            }
        }
        const _reducer = reducer ? reducer : (errors: VinError[]) => errors.map(({error}) => error).join('');
        return _reducer(errors, vins.length === 0);
    };
}

ValidatorJS.register(
    'VINArray',
    (value) => {
        const vins = parseVINsFromInput(value);
        if (!vins.length) {
            return false;
        }
        return vins.every(validateVIN);
    },
    validationErrorMessages.vinArray
);

ValidatorJS.register(
    'nonStandardVINArray',
    (value) => {
        const vins = parseVINsFromInput(value);
        if (!vins.length) {
            return false;
        }
        return vins.every((vin) => vin.match(NON_STANDARD_VIN_REGEX));
    },
    validationErrorMessages.nonStandardVinArray
);

ValidatorJS.register(
    'less_than',
    (value, args) => {
        if (!value) {
            return false;
        }
        return `${value}`.length <= Number.parseInt(args);
    },
    validationErrorMessages.lessThan
);

ValidatorJS.register(
    'new_password',
    (value, args) => {
        if (!value) {
            return false;
        }
        return `${value}`.length >= Number.parseInt(args);
    },
    validationErrorMessages.newPassword
);

export function declarativeValidator<T>(rule: string | string[]): Validator<T> {
    return (value) => {
        const validator = new ValidatorJS({value: value}, {value: rule}, {...validationErrorMessages});
        validator.check();
        return validator.errors.first('value') || undefined;
    };
}

export function declarativeValidatorForField<T>(rule: string | string[], fieldName: string): Validator<T> {
    return (value) => {
        const validator = new ValidatorJS({value: value}, {value: rule}, {...makeValidationErrorMessages(fieldName)});
        validator.check();
        return validator.errors.first('value') || undefined;
    };
}

export function requiredFieldValidator<T>(fieldName: string, errorMessage?: string | undefined): Validator<T> {
    return (value) => {
        const notEmpty = Array.isArray(value) ? value.length > 0 : Boolean(value);
        if (notEmpty) {
            return undefined;
        }
        return errorMessage || `${fieldName} is required`;
    };
}

export function multiplicityValidator<T>(increment = 25): Validator<T> {
    return (value) => {
        const newAmount = Number(value);
        if (newAmount && newAmount % increment === 0) {
            return undefined;
        }
        return `Must be in multiplies of $${increment}`;
    };
}

export function regexValidator(
    fieldName: string,
    regex: string | undefined,
    valueRequired: boolean,
    errorMessage?: string | undefined
): Validator<string> {
    return (value) => {
        if (!regex) {
            return undefined;
        }
        if (!value || value.length === 0) {
            return valueRequired ? errorMessage || `${fieldName} format is invalid` : undefined;
        }
        return new RegExp(regex).test(value) ? undefined : errorMessage || `${fieldName} format is invalid`;
    };
}

export function regexOptionalValidator(
    fieldName: string,
    regex: string | undefined,
    errorMessage?: string | undefined
): Validator<string> {
    return regexValidator(fieldName, regex, false, errorMessage);
}

export function regexRequiredValidator(
    fieldName: string,
    regex: string | undefined,
    errorMessage?: string | undefined
): Validator<string> {
    return regexValidator(fieldName, regex, true, errorMessage);
}

export function chainValidator<T>(...validators: Validator<T>[]): Validator<T> {
    return (value) => {
        for (const validator of validators) {
            const message = validator(value);
            if (message) {
                return message;
            }
        }
        return undefined;
    };
}

export function createMinimumBidValidator<T>({
    item,
    auction,
    seller,
    platformFeeSchedule,
}: {
    item?: PersistedItemType;
    auction?: AuctionOccurrence;
    seller?: SellerType | null;
    platformFeeSchedule?: PlatformFeeSchedule;
}): Validator<T> {
    return (value) => {
        if (!auction || !seller) {
            return undefined;
        }
        if (auction.settings.platformFeeType === PlatformFeeTypes.SCHEDULE && !platformFeeSchedule) {
            return undefined;
        }
        try {
            const parsedVal = Number(value);
            const feeSchedule = seller.settings && getActiveFeeSchedule(seller.settings as SellerSettings);
            calculateFees({
                amount: parsedVal,
                feeSchedule,
                feePrice: item?.feePrice,
                auctionSettings: {
                    ...auction.settings,
                    minimumBid: parsedVal,
                },
                settings: {
                    isUserPreApproved: false,
                    isLicensedBuyer: false,
                    isManagerForSeller: false,
                },
                impoundFees: item?.impoundFees && (getSnapshot(item.impoundFees) as ImpoundFees),
                platformFeeSchedule,
            });
            return undefined;
        } catch (e) {
            return 'Cannot set minimum bid lower than total fees';
        }
    };
}

export function createStepBidValidator<T>(step: number): Validator<T> {
    return (value) => {
        const parsedVal = Number(value);
        if (parsedVal % step !== 0) {
            return `The number must be a multiply of $${step}`;
        }
    };
}

export function orValidator<T>(...validators: Validator<T>[]): Validator<T> {
    return (value) => {
        const messages = validators.map((validator) => validator(value)).filter((message) => Boolean(message));
        return messages.length === validators.length ? messages.join(' or ') : undefined;
    };
}

interface ViewModel {
    validate: () => void;
}

interface ValidationBuilderItem<T> {
    viewModel: ViewModel;
    validator: Validator<T> | undefined;
    value: T | null | undefined;
}

interface ValidationBuilderFieldItem<T> {
    fieldModel: ViewModel;
    validator: Validator<T> | undefined;
    value: T | null | undefined;
}

export default class ValidationBuilder {
    private items: ValidationBuilderItem<any>[] = [];

    add<T>(viewModel: ViewModel, validator: Validator<T> | undefined, value: T | null | undefined) {
        this.items.push({
            viewModel: viewModel,
            validator: validator,
            value: value,
        });

        return this;
    }

    addFieldModel<T>(model: ValidationBuilderFieldItem<T>) {
        this.items.push({
            viewModel: model.fieldModel,
            validator: model.validator,
            value: model.value,
        });

        return this;
    }

    validate(dryRun = false): boolean {
        const validationResult = this.items
            .map((item) => {
                if (!dryRun) {
                    item.viewModel.validate();
                }
                return item.validator && item.validator(item.value);
            })
            .find((validationResult) => Boolean(validationResult));

        return !validationResult;
    }

    validateWithErrors(dryRun = false): string {
        const validationResults = this.items.map((item) => {
            if (!dryRun) {
                item.viewModel.validate();
            }
            return item.validator && item.validator(item.value);
        });

        return validationResults.filter((result) => Boolean(result)).join(',\n');
    }
}
